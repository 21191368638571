import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Button,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { deleteOrganization } from "../../store/actions/organizationAction";
import { colorStyling } from "../../helpers/color";
import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";
import { showSanitizedInput } from "../../helpers/inputProcessing";

const DeleteOrganization = ({ open, onClose, organization }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("organization");

  const handleClickClose = () => {
    onClose(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteOrganization(id));

    handleClickClose();
  };

  return (
    <>
      <Dialog maxWidth="sm" onClose={handleClickClose} open={open}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(
              parseVariables(t("form.deleteConfirmationText"), {
                name: showSanitizedInput(organization.name),
              })
            )}
          </DialogContentText>

          <Box
            sx={{
              fontFamily: "sans-serif",
              color: colorStyling.gray.darkblue,
              textAlign: "center",
              fontSize: 14,
            }}
            id="alert-dialog-slide-description"
          >
            <Alert severity="warning" variant="outlined">
              <strong>{t("form.deleteConfirmationHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {t("form.cancelBtn")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(organization.id)}
            fullWidth
            startIcon={<DoneIcon />}
          >
            {t("form.confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteOrganization;
