import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TypographyNormal, TypographyLarge } from "../../../customComponent";
import { getAssetData } from "../../../../store/actions/cmsAction";
import Marquee from "react-fast-marquee";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-creative";

import T from "../../../../transitions";
import FONTS from "../../../../fonts";
import { colorStyling } from "../../../../helpers/color";
import { logger } from "../../../../helpers/logger";

const addedPadding = process.env.REACT_APP_CONTENT_VIDEO_PADDING || 0;
const useStyles = makeStyles(() => ({
  videoContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    "& video": {
      opacity: 1,
      transition: "opacity 0.5s ease",
    },
  },
  fadeIn: {
    "& video": {
      opacity: 1,
    },
  },
  fadeOut: {
    "& video": {
      opacity: 0,
    },
  },
}));

const LayerAssetItem = ({
  layer,
  contentRes,
  canvasRes,
  ratiodRes,
  open,
  refreshed,
  disableControl,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { assetData, adsPlaceholderFlag } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [layerRes, setLayerRes] = useState({ width: 0, height: 0 });
  const [ratiodPos, setRatiodPos] = useState({ x: 0, y: 0 });
  const [transitionBox, setTransitionBox] = useState([]);
  const [coveringBox, setCoveringBox] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);

  const videoRef = useRef(null);
  const [videoLoad, setVideoLoad] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const assetRefs = useRef([]);
  const swiperEl = useRef(null);

  const autoplayTimer = useRef();
  const handleAutoplay = (interval) => {
    clearTimeout(autoplayTimer.current);
    const timer = setTimeout(() => {
      if (swiperEl.current) {
        swiperEl.current.swiper?.slideNext();
      }
    }, interval + addedPadding);

    autoplayTimer.current = timer;
    return () => clearTimeout(timer);
  };

  const layerTransition = useMemo(() => {
    if (!layer.transition) return {};
    logger.log(`transition [${layer.transition}]:`, T[layer.transition]);
    return T[layer.transition];
  }, [layer.transition]);

  const switchONTransition = (index) => {
    const updated = [];
    for (let i = 0; i < layer.assets.length; i++) {
      updated.push(i === index ? true : false);
    }
    setTransitionBox(updated);
  };

  const switchOFFCovering = (index) => {
    const updated = [];
    for (let i = 0; i < layer.assets.length; i++) {
      updated.push(i === index ? false : true);
    }
    setCoveringBox(updated);
  };

  useEffect(() => {
    if (Object.keys(assetData).length === 0) {
      return;
    }

    const transBox = [];
    const coverBox = [];
    if (Object.keys(assetData).length > 0) {
      layer.assets.forEach((asset, i) => {
        transBox.push(false);
        coverBox.push(true);
      });

      const firstAsset = layer.assets[0];
      handleAutoplay(firstAsset.duration ?? firstAsset.interval * 1000);
      setTransitionBox(transBox);
      setCoveringBox(coverBox);

      if (layerTransition["transitionBox"]) {
        switchONTransition(0);
      }
    }

    const getSwiper = swiperEl.current?.swiper;
    if (!getSwiper) {
      return;
    }

    getSwiper.update();
    getSwiper.updateSlides();
    getSwiper.updateSlidesClasses();
    getSwiper.slideTo(0);
    getSwiper.off("slideChange");
    if (assetRefs.current.length > 0) {
      getSwiper.on("slideChange", (swiper) => {
        const prevIndex =
          swiper.previousIndex === swiper.realIndex
            ? assetRefs.current.length - 1
            : swiper.previousIndex;
        const nowIndex = swiper.realIndex;
        setCurrentIndex(nowIndex);
        switchONTransition(nowIndex);
        switchOFFCovering(nowIndex);

        const previous = layer.assets[prevIndex];
        if (!previous) {
          return;
        }

        const active = layer.assets[nowIndex];
        handleAutoplay(active.duration ?? active.interval * 1000);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData]);

  useEffect(() => {
    if (!(layer?.assets?.length > 0)) {
      return;
    }

    if (open === false) {
      return;
    }

    dispatch(
      getAssetData(layer.id, layer.assets, true, (results) => {
        logger.log(`getAssetData-callback:`, results);
      })
    );

    const ratiodPosX = Math.round(layer.x / ratiodRes.width);
    const ratiodPosY = Math.round(layer.y / ratiodRes.height);
    const updatedPosition = { x: ratiodPosX, y: ratiodPosY };
    setRatiodPos(updatedPosition);

    const ratiodResWidth = Math.round(layer.width / ratiodRes.width);
    const ratiodResHeight = Math.round(layer.height / ratiodRes.height);
    const updatedLayerRes = { width: ratiodResWidth, height: ratiodResHeight };
    setLayerRes(updatedLayerRes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, layer]);

  const textLayer = useMemo(() => {
    const text = assetData[layer.assets[0].id]?.text;
    return text || {};
  }, [layer, assetData]);

  const textSizeRatiod = useMemo(() => {
    if (!textLayer) {
      return 14;
    }

    const widthRatiod = canvasRes.width / contentRes.width;
    let ratiodSize = Math.ceil(textLayer.fontSize * widthRatiod);
    if (ratiodSize <= 0) ratiodSize = 1;
    return ratiodSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, contentRes, canvasRes]);

  const parseObjectFit = (assetFit) => {
    switch (assetFit) {
      default:
      case "FIT":
        return "contain";
      case "STRETCH":
        return "fill";
    }
  };

  const parseAlignTextHorizontal = useMemo(() => {
    switch (textLayer?.horizontalAlignment) {
      default:
      case "LEFT":
        return "left";
      case "CENTER":
        return "center";
      case "RIGHT":
        return "right";
      case "JUSTIFY":
        return "justify";
    }
  }, [textLayer]);

  const parseAlignTextVertical = useMemo(() => {
    switch (textLayer?.verticalAlignment) {
      case "TOP":
        return "baseline";
      default:
      case "CENTER":
        return "center";
      case "BOTTOM":
        return "end";
    }
  }, [textLayer]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !videoLoad || isFading) {
      return;
    }

    logger.log(`videoloop - START!`);
    const handleTimeUpdate = () => {
      const timeLeft = video.duration - video.currentTime;
      if (timeLeft <= 0.5 && !isFading) {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        setIsFading(true);

        video.pause();
        setTimeout(() => {
          video.currentTime = 0; // Reset video to start
          setTimeout(() => {
            setIsFading(false); // Reset fading state
            // below is intended, to ignore error for Sentry
            video.play().catch((error) => {
              return;
            });
          }, 111);
        }, 888); // Match this duration with your CSS transition duration
      }
    };

    video.removeEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("timeupdate", handleTimeUpdate);
  }, [videoLoad, isFading]);

  const previews = useMemo(() => {
    const assetPreview = [];
    layer.assets.forEach((asset, i) => {
      const getMedia = assetData[asset.assetId ?? asset.id];
      if (!getMedia) {
        assetPreview.push(
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ width: layerRes.width, height: layerRes.height }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 400 }}
            >
              {tCommon("loading")}
            </TypographyNormal>
          </Box>
        );
      } else {
        const media = { ...getMedia };
        switch (media.type) {
          case "VIDEO":
            assetPreview.push(
              <div
                className={`${classes.videoContainer} ${
                  isFading ? classes.fadeOut : classes.fadeIn
                }`}
              >
                <video
                  is="x-muted"
                  ref={(el) => {
                    setVideoLoad(false);
                    assetRefs.current[i] = el;
                    videoRef.current = el;
                    if (el) {
                      if (layer.assets.length > 1) {
                        el.currentTime = 0;
                        if (i === currentIndex || refreshed) {
                          // below is intended, to ignore error for Sentry
                          el.play()
                            .then(() => {
                              el.controls = !disableControl;
                            })
                            .catch((error) => {
                              return;
                            });
                        } else {
                          el.pause();
                        }
                      } else {
                        setVideoLoad(true);
                      }
                    }
                  }}
                  draggable={false}
                  src={media.url}
                  alt={media.name}
                  width={layerRes.width}
                  height={layerRes.height}
                  controls
                  onClick={(e) => e.preventDefault()}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: parseObjectFit(media.fit),
                  }}
                  onLoadedData={() => {
                    if (i === 0 && !firstLoad) {
                      setFirstLoad(true);
                      logger.log(`VIDEO-onLoadedData!`);
                      switchOFFCovering(i);
                    }
                  }}
                />
              </div>
            );
            break;

          case "IMAGE":
            assetPreview.push(
              <img
                ref={(el) => (assetRefs.current[i] = el)}
                draggable={false}
                src={media.url}
                alt={t("detail.contentImageAltText")}
                width={layerRes.width}
                height={layerRes.height}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: parseObjectFit(media.fit),
                }}
                onLoad={() => {
                  if (i === 0 && !firstLoad) {
                    setFirstLoad(true);
                    logger.log(`IMAGE-onLoad!`);
                    switchOFFCovering(i);
                  }
                }}
              />
            );
            break;

          default:
          case "VISTAR_ADVERTISEMENT":
          case "HIVESTACK_ADVERTISEMENT":
            if (adsPlaceholderFlag[layer.id]?.[i]) {
              let placeholder;
              switch (media.placeholder?.type) {
                default:
                  placeholder = (
                    <img
                      ref={(el) => (assetRefs.current[i] = el)}
                      draggable={false}
                      src={`https://placehold.co/${layerRes.width}x${layerRes.height}`}
                      alt={t("detail.contentImageAltText")}
                      width={layerRes.width}
                      height={layerRes.height}
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                      onLoad={() => {
                        if (i === 0 && !firstLoad) {
                          setFirstLoad(true);
                          logger.log(`ADS-None-onLoad!`);
                          switchOFFCovering(i);
                        }
                      }}
                    />
                  );
                  break;

                case "IMAGE":
                  placeholder = (
                    <img
                      ref={(el) => (assetRefs.current[i] = el)}
                      draggable={false}
                      src={media.placeholder.url}
                      alt={t("detail.contentImageAltText")}
                      width={layerRes.width}
                      height={layerRes.height}
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: parseObjectFit(media.fit),
                      }}
                      onLoad={() => {
                        if (i === 0 && !firstLoad) {
                          setFirstLoad(true);
                          logger.log(`ADS-IMAGE-onLoad!`);
                          switchOFFCovering(i);
                        }
                      }}
                    />
                  );
                  break;

                case "VIDEO":
                  placeholder = (
                    <div
                      className={`${classes.videoContainer} ${
                        isFading ? classes.fadeOut : classes.fadeIn
                      }`}
                    >
                      <video
                        is="x-muted"
                        ref={(el) => {
                          setVideoLoad(false);
                          assetRefs.current[i] = el;
                          videoRef.current = el;
                          if (el) {
                            if (layer.assets.length > 1) {
                              el.currentTime = 0;
                              if (i === currentIndex || refreshed) {
                                // below is intended, to ignore error for Sentry
                                el.play()
                                  .then(() => {
                                    el.controls = !disableControl;
                                  })
                                  .catch((error) => {
                                    return;
                                  });
                              } else {
                                el.pause();
                              }
                            } else {
                              setVideoLoad(true);
                            }
                          }
                        }}
                        draggable={false}
                        src={media.placeholder.url}
                        alt={media.name}
                        width={layerRes.width}
                        height={layerRes.height}
                        controls
                        onClick={(e) => e.preventDefault()}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: parseObjectFit(media.fit),
                        }}
                        onLoadedData={() => {
                          if (i === 0 && !firstLoad) {
                            setFirstLoad(true);
                            logger.log(`ADS-VIDEO-onLoadedData!`);
                            switchOFFCovering(i);
                          }
                        }}
                      />
                    </div>
                  );
                  break;
              }

              assetPreview.push(placeholder);
            } else {
              assetPreview.push(
                <img
                  ref={(el) => (assetRefs.current[i] = el)}
                  draggable={false}
                  src={`/ad_slot.png`}
                  alt={t("detail.contentImageAltText")}
                  width={layerRes.width}
                  height={layerRes.height}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                  onLoad={() => {
                    if (i === 0 && !firstLoad) {
                      setFirstLoad(true);
                      logger.log(`ADS-onLoad!`);
                      switchOFFCovering(i);
                    }
                  }}
                />
              );
            }
            break;

          case "TEXT":
            const textComponent = (
              <TypographyLarge
                sx={{
                  pl: 1,
                  pr: textLayer.mode !== "STATIC" ? 10 : 1,
                  height: layerRes.height,
                  textAlign: parseAlignTextHorizontal,
                  alignContent: parseAlignTextVertical,
                  overflow: "hidden",
                  textWrap: "nowrap",
                  textOverflow: "clip",
                  fontSize: `${textSizeRatiod}px`,
                  fontWeight: textLayer.fontWeight,
                  color: textLayer.color,
                  display: "grid",
                  fontFamily: FONTS[textLayer.fontType]?.value,
                }}
              >
                {textLayer.text}
              </TypographyLarge>
            );

            assetPreview.push(
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  alignContent: "center",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "darkgrey",
                  backgroundColor:
                    textLayer.backgroundColor?.length > 0
                      ? textLayer.backgroundColor
                      : "lightgrey",
                }}
              >
                {textLayer.mode === "STATIC" ? (
                  <Box>{textComponent}</Box>
                ) : (
                  <Marquee
                    speed={textLayer.scrollingSpeed}
                    direction={textLayer.scrollingDirection?.toLowerCase()}
                  >
                    {textComponent}
                  </Marquee>
                )}
              </Box>
            );
            break;
        }
      }
    });

    if (layer.assets?.length === 1) {
      return assetPreview[0];
    }

    return assetPreview.map((asset, i) => {
      return (
        <Box
          width={layerRes.width}
          height={layerRes.height}
          display={"flex"}
          position={"relative"}
          sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "darkgrey",
            backgroundColor: colorStyling.cms.black,
          }}
        >
          {/* Transition Box encapsulates the content. */}
          {layerTransition["transitionBox"] ? (
            <Slide
              direction={layerTransition["transitionBox"].direction}
              in={transitionBox[i]}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: layerTransition["transitionBox"].speed }}
            >
              {asset}
            </Slide>
          ) : (
            <>
              {asset}

              {/* Cover Box needs to be remove to reveal the content. */}
              {layerTransition["coverBox"] && (
                <Slide
                  direction={layerTransition["coverBox"].direction}
                  in={coveringBox[i]}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ exit: layerTransition["coverBox"].speed }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: layerRes.width,
                      height: layerRes.height,
                      backgroundColor: colorStyling.cms.black,
                    }}
                  ></Box>
                </Slide>
              )}
            </>
          )}
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layer,
    assetData,
    transitionBox,
    coveringBox,
    adsPlaceholderFlag,
    isFading,
  ]);

  return (
    <Box
      sx={{
        top: ratiodPos.y,
        left: ratiodPos.x,
        width: layerRes.width,
        height: layerRes.height,
        lineHeight: 0,
        position: "absolute",
        backgroundColor: !textLayer ? colorStyling.cms.black : null,
        border: "1px solid rgba(28, 78, 216, 0.5)",
        zIndex: layer.z,
      }}
    >
      {layer.assets?.length === 1 && previews}
      {layer.assets?.length > 1 && (
        <Swiper
          ref={swiperEl}
          modules={[Navigation, EffectCreative]}
          watchSlidesProgress
          slidesPerView={1}
          navigation={!disableControl}
          loop
          effect="creative"
          speed={layerTransition.speed}
          creativeEffect={layerTransition.effect}
        >
          {previews.map((preview, i) => {
            return <SwiperSlide key={i}>{preview}</SwiperSlide>;
          })}
        </Swiper>
      )}
    </Box>
  );
};

export default LayerAssetItem;
