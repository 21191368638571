import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssetsManagerZoom from "./AssetsManagerZoom";
import { TypographyNormal, TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const AssetsManagerBatchDelete = ({
  assets,
  open,
  closeDialog,
  unselectItem,
  handleConfirm,
  loading,
}) => {
  const { t } = useTranslation("assetmanager");
  const { t: tCommon } = useTranslation();

  const [viewAsset, setViewAsset] = useState(false);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hoverFlag, setHoverFlag] = useState([]);
  const [removedIndex, setRemovedIndex] = useState(null);

  const [itemIndex, setItemIndex] = useState(0);
  const [itemFocus, setItemFocus] = useState({});

  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    const items = [];
    const flags = [];
    Object.keys(assets).forEach((id) => {
      items.push(assets[id]);
      flags.push(false);
    });

    if (removedIndex !== null) {
      let updatedIndex = itemIndex;
      if (removedIndex < itemIndex || updatedIndex >= items.length) {
        updatedIndex = updatedIndex - 1;
      }

      if (updatedIndex < 0) {
        closeDialog();
        return;
      }

      setItemIndex(updatedIndex);
      setItemFocus(items[updatedIndex]);
      setRemovedIndex(null);
    }

    setHoverFlag(flags);
    setSelectedItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  useEffect(() => {
    if (open && selectedItems?.length > 0) {
      setItemIndex(0);
      setItemFocus(selectedItems[0]);
      setHoverFlag(new Array(selectedItems.length).fill(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    switch (itemFocus.type) {
      case "IMAGE":
        setIsImage(true);
        break;

      case "VIDEO":
        setIsVideo(true);
        break;

      default:
        break;
    }

    return () => {
      setIsImage(false);
      setIsVideo(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemFocus]);

  const handleChangeFocus = (index) => {
    setItemIndex(index);
    setItemFocus(selectedItems[index]);
  };

  const handleHovering = (index, value) => {
    const updated = [...hoverFlag];
    updated[index] = value;
    setHoverFlag(updated);
  };

  const handleCallback = (event) => {
    event.preventDefault();
    handleConfirm(() => {
      setConfirmCheckbox(false);
    });
  };

  const handleClose = (event) => {
    event.preventDefault();
    closeDialog();
    setConfirmCheckbox(false);
  };

  const handleRemoveItem = (event, id, index) => {
    event.stopPropagation();
    setRemovedIndex(index);
    unselectItem(id);
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {t("batchDelete.title")}
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="small" color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <AssetsManagerZoom
          open={viewAsset}
          closeDialog={() => setViewAsset(false)}
          asset={itemFocus}
          assetType={{ isImage, isVideo }}
        />

        <Box
          width={"700px"}
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ px: 1, py: 2 }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{ pr: 3 }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"333px"}
              maxHeight={"400px"}
            >
              <TableContainer
                component={Paper}
                sx={{ overflowY: "auto", maxHeight: 525, boxShadow: 3 }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width={"117px"}>
                        {parser(
                          parseVariables(t("batchDelete.headerName"), {
                            count: selectedItems.length,
                          })
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {selectedItems.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          ...((index + 1) % 2 !== 0
                            ? {
                                backgroundColor: colorStyling.white.hover,
                              }
                            : {
                                backgroundColor: colorStyling.white.full,
                              }),
                        }}
                      >
                        <TableCell align="left">
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            onClick={() => handleChangeFocus(index)}
                            onMouseEnter={() => handleHovering(index, true)}
                            onMouseLeave={() => handleHovering(index, false)}
                            sx={{ cursor: "pointer" }}
                          >
                            <Box
                              width={"100%"}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Tooltip
                                arrow
                                placement="top"
                                disableInteractive
                                title={
                                  loading ? (
                                    ""
                                  ) : (
                                    <TypographySmall
                                      sx={{
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {t("batchDelete.removeTip")}
                                    </TypographySmall>
                                  )
                                }
                              >
                                <ClearIcon
                                  color={!loading ? "error" : "disabled"}
                                  fontSize="small"
                                  onClick={(e) =>
                                    !loading
                                      ? handleRemoveItem(e, data.id, index)
                                      : null
                                  }
                                  sx={{
                                    mr: 1,
                                    "&:hover": { opacity: 0.7 },
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                arrow
                                placement="top"
                                disableInteractive
                                title={
                                  <TypographySmall
                                    sx={{ color: "white", textAlign: "center" }}
                                  >
                                    {data.name}
                                  </TypographySmall>
                                }
                              >
                                <Box sx={{ maxWidth: "230px" }}>
                                  <TypographyNormal
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.name}
                                  </TypographyNormal>
                                </Box>
                              </Tooltip>
                            </Box>
                            {(hoverFlag[index] || index === itemIndex) && (
                              <PlayArrowIcon
                                fontSize="small"
                                sx={{ color: index === itemIndex && "blue" }}
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Box display={"flex"} justifyContent={"center"} sx={{ mb: 1 }}>
              <TypographySmall
                width={"fit-content"}
                textAlign={"center"}
                sx={{ pt: "4px" }}
              >
                {t("batchDelete.previewLabel")}
              </TypographySmall>
            </Box>
            <Link
              href="#"
              underline="none"
              component={"div"}
              onClick={() => setViewAsset(true)}
              sx={{
                width: "333px",
                position: "relative",
                cursor: "pointer",
                ":hover": {
                  opacity: 0.9,
                },
              }}
            >
              {itemFocus && (
                <img
                  alt={itemFocus.name}
                  style={{
                    width: "100%",
                    height: "222px",
                  }}
                  src={itemFocus?.thumbnailUrl ?? itemFocus?.url}
                />
              )}
              {isVideo && (
                <PlayCircleIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: colorStyling.white.full,
                    fontSize: "77px",
                  }}
                />
              )}
            </Link>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                onClick={() => setViewAsset(true)}
                sx={{
                  ":hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
              >
                <ZoomInIcon fontSize="15px" sx={{ mt: "4px" }} />
                <TypographySmall
                  width={"fit-content"}
                  textAlign={"center"}
                  sx={{ pt: "4px" }}
                >
                  {t("asset.zoomHint")}
                </TypographySmall>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <Box
        sx={{ width: "100%", height: 0 }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmCheckbox}
              onClick={(e) => {
                setConfirmCheckbox(e.target.checked);
              }}
            />
          }
          label={
            <TypographySmall sx={{ color: "red" }}>
              {parser(t("batchDelete.confirmCheckbox"))}
            </TypographySmall>
          }
          disabled={loading}
          sx={{ m: 0, width: "410px", marginTop: "-22px" }}
        />
      </Box>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          disabled={loading}
          onClick={handleClose}
          sx={{ height: "38px" }}
        >
          {tCommon("cancelBtn")}
        </Button>

        <Button
          startIcon={!loading && <DoneIcon />}
          fullWidth
          variant="contained"
          color="error"
          disabled={!confirmCheckbox || loading}
          onClick={handleCallback}
          sx={{ height: "38px" }}
        >
          {!loading ? (
            t("batchDelete.confirmBtn")
          ) : (
            <CircularProgress size={"22px"} color="inherit" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetsManagerBatchDelete;
