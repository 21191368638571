import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { deleteUser } from "../../store/actions/userAction";
import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";
import { showSanitizedInput } from "../../helpers/inputProcessing";

const DeleteUser = ({ open, onClose, user }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleClickClose = () => {
    onClose(false);
  };

  const handleDelete = (id) => {
    setLoading(true);
    dispatch(deleteUser(id), () => {
      setLoading(false);
      onClose(false);
    });
  };

  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") event.stopPropagation();

    if (event.key === "e") event.stopPropagation();
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onKeyDown={stopPropagationForTab}
        onClose={handleClickClose}
      >
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: showSanitizedInput(user.name),
              })
            )}
          </DialogContentText>

          <Box sx={{ textAlign: "center" }}>
            <Alert severity="warning" variant="outlined">
              <strong>{t("item.deleteConfirmationHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
            disabled={loading}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={loading ? null : <DoneIcon />}
            onClick={() => handleDelete(user.id)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUser;
