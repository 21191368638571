import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  InputLabel,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { CustomTextField, TypographySmall } from "../customComponent";
import { createSchedule } from "../../store/actions/scheduleAction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createScheduleSchema } from "../../schemas/createScheduleSchema";
import { initiateAXIOS } from "../../config/axios";
import { contentCMSAPILimit } from "../../api";
import dayjs from "dayjs";
import { range } from "lodash";
import RecurrenceOption from "./form/RecurrenceOption";
import { calculateCron } from "../../helpers/cronHelper";
import {
  convertDurationtoMs,
  durationValidation,
  generateDurationOptions,
} from "../../helpers/scheduleHelper";
import { logger } from "../../helpers/logger";
import {
  SET_ERROR_MESSAGE_SCHEDULE_DIALOG,
  SET_ERROR_PROMPT_SCHEDULE_DIALOG,
} from "../../store/actionTypes/scheduleActionType";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import { colorStyling } from "../../helpers/color";
import { sanitizeInput } from "../../helpers/inputProcessing";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const initMonthCheckbox = range("1", "13").toString().split(",");

const CreateSchedule = () => {
  const dispatch = useDispatch();

  const { features } = useSelector((state) => state.auth);
  const {
    isErrorPromptScheduleDialog: isError,
    errorMessageScheduleDialog: errorMessage,
  } = useSelector((state) => state.schedule);

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameValidation, setNameValidation] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const [loadingContentList, setLoadingContentList] = useState(false);
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");
  const [selectPriority, setSelectPriority] = useState(2);

  const [checkboxEndDate, setCheckboxEndDate] = useState(false);

  const [radioValue, setRadioValue] = useState(0);
  const [dayCheckbox, setDayCheckbox] = useState([]);
  const [monthCheckbox, setMonthCheckbox] = useState(initMonthCheckbox);
  const [selectMonthlyDate, setSelectMonthlyDate] = useState({
    start: 1,
    end: 1,
  });

  const [useMinuteInterval, setUseMinuteInterval] = useState(false);
  const [intervalMinutes, setIntervalMinutes] = useState(5);
  const [intervalMinutesHelper, setIntervalMinutesHelper] = useState("");

  /**
   * Yup form validation and function handling
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(createScheduleSchema),
    defaultValues: {
      frequency: "",
      TYPE: "",
      COMMAND: "",
    },
  });

  /**
   * to get Yup form value
   */
  const watchAllFields = watch();

  const handleMinuteIntervalToggle = (e) => {
    setUseMinuteInterval(e.target.checked);
    if (intervalMinutesHelper && !e.target.checked)
      setIntervalMinutesHelper("");
  };

  const handleMinutesChange = (e) => {
    const value = parseInt(e.target.value, 10);

    // Validation logic
    if (isNaN(value) || value < 1 || value > 59) {
      setIntervalMinutesHelper(t("validation.repeatIntervalHint"));
    } else {
      setIntervalMinutesHelper(""); // Clear error if valid
      setIntervalMinutes(value);
    }
  };

  const handleSetRecurrenceState = (type, value) => {
    switch (type) {
      case "radio":
        setRadioValue(value);
        break;

      case "checkDay":
        setDayCheckbox(value);
        break;

      case "checkMonth":
        setMonthCheckbox(value);
        break;

      case "selectMonthDate":
        setSelectMonthlyDate(value);
        break;

      default:
        break;
    }
  };

  const handleGetRecurrenceState = (type) => {
    switch (type) {
      case "radio":
        return radioValue;

      case "checkDay":
        return dayCheckbox;

      case "checkMonth":
        return monthCheckbox;

      case "selectMonthDate":
        return selectMonthlyDate;

      default:
        break;
    }
  };
  /**
   * Handle opening dialog
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Handle closing dialog
   */
  const handleClickClose = () => {
    handleResetForm();
    setOpen(false);
  };

  /**
   * Handle opening error alert
   */
  const handleOpenErr = (message) => {
    dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_DIALOG, payload: message });
  };

  const handleResetRecurrenceOption = () => {
    setRadioValue(0);
    setDayCheckbox([]);
    setMonthCheckbox(initMonthCheckbox);
    setSelectMonthlyDate({
      start: 1,
      end: 1,
    });
  };

  /**
   * Reset form data
   */
  const handleResetForm = () => {
    reset({
      name: "",
      frequency: "",
      TYPE: "",
      COMMAND: "",
    });

    setStartDate("");
    setEndDate("");
    setCustomContentList([]);
    setSelectCustomContent("");
    setSelectPriority(2);
    setCheckboxEndDate(false);
    setDuration({
      hours: "00",
      minutes: "00",
      seconds: "00",
    });

    handleResetRecurrenceOption();
    setNameValidation("");
  };

  /**
   *  Handling start date value
   */
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setEndDate(dayjs(e.target.value).add(1, "d").format("YYYY-MM-DDTHH:mm"));
  };

  /**
   * Handling end date value
   * @param {*} e Input Event
   */
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  /**
   * Handling duration field change
   * @param {*} e Input Value
   */
  const handleChangeDuration = (e, part) => {
    let input = e.target.value;
    setDuration({ ...duration, [part]: input });
  };

  const handleCheckboxEndDate = (checked) => {
    if (checked) {
      setEndDate(dayjs(startDate).add(1, "d").format("YYYY-MM-DDTHH:mm"));
    } else {
      setEndDate("");
    }
    setCheckboxEndDate(checked);
  };

  const getScheduleType = (frequency, action) => {
    let scheduleType = "";
    if (frequency !== "ONCE" && frequency !== "LOOP")
      scheduleType += "REPEATING_";
    if (action === "PLAY_CONTENT") scheduleType += "CONTENT_";
    else scheduleType += "COMMAND_";
    scheduleType += "SCHEDULE";

    return scheduleType;
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  /**
   * Form submit function, handling payload to sent to api
   * @param {*} data Form data from Yup
   */
  const submitForm = (data) => {
    let { name, frequency, COMMAND } = data;
    if (name.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }

    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    const startDateISO = sDate.toISOString();
    const scheduleType = getScheduleType(frequency, COMMAND);
    var payload = {
      name: name.trim(),
      startAt: startDateISO,
      cron: calculateCron(
        frequency,
        sDate,
        radioValue,
        dayCheckbox,
        monthCheckbox,
        selectMonthlyDate,
        useMinuteInterval ? intervalMinutes : ""
      ),
      command: COMMAND,
      type: scheduleType,
    };

    if (checkboxEndDate) {
      const endDateISO = eDate.toISOString();
      payload["endAt"] = endDateISO;
    }

    if (COMMAND === "PLAY_CONTENT") {
      payload["contentId"] = +selectCustomContent;
      payload["priority"] = +selectPriority;

      if (frequency !== "LOOP") {
        if (!durationValidation(duration)) {
          handleOpenErr(t("validation.durationEmpty"));
          handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
          return;
        }
        payload["duration"] = convertDurationtoMs(duration);
      }
    } else if (frequency === "ONCE") payload["endAt"] = startDateISO;

    setLoading(true);
    payload["name"] = sanitizeInput(payload.name);
    dispatch(createSchedule(payload, handleFinishSubmit));
  };

  /**
   * API call to get cms content list
   */
  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      logger.error(error);

      handleOpenErr(t("validation.listContentsError"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
    } finally {
      setLoadingContentList(false);
    }
  };

  /**
   * Running cms content fetch when user selecting Play Content action
   */
  useEffect(() => {
    if (watchAllFields.COMMAND === "PLAY_CONTENT") {
      getCMSContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields.COMMAND]);

  /**
   * Reset Recurrence option
   */
  useEffect(() => {
    handleResetRecurrenceOption();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields.frequency]);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ boxShadow: 3 }}
      >
        {t("createBtn")}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit(submitForm)}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("form.newDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_SCHEDULE_DIALOG}
            />

            <StyledBox sx={{ mt: 1 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>

              <TextField
                size="small"
                margin="dense"
                {...register("name")}
                inputProps={{
                  maxLength: 40,
                }}
                fullWidth
                variant="outlined"
                placeholder={t("form.namePlaceholder")}
                onChange={(e) => setNameValidation("")}
                disabled={loading}
              />
              {errors.name?.message && (
                <TypographySmall sx={{ color: colorStyling.red }}>
                  {errors.name?.message}
                </TypographySmall>
              )}
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </StyledBox>

            <Box sx={{ display: "flex" }}>
              <StyledBox sx={{ width: "100%" }}>
                <InputLabel>{t("form.actionTypeLabel")}</InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  {...register("TYPE")}
                  select
                  margin="dense"
                  SelectProps={{
                    native: true,
                  }}
                  required
                  helperText={errors.TYPE?.message}
                  disabled={loading}
                >
                  <option value="">{t("form.actionTypeSelect")}</option>
                  {features["SCHEDULE.CONTENT"] && (
                    <option value="CONTENT">
                      {t("form.actionTypeContent")}
                    </option>
                  )}
                  {features["SCHEDULE.COMMAND"] && (
                    <option value="DISPLAY">
                      {t("form.actionTypeDisplay")}
                    </option>
                  )}
                  {features["SCHEDULE.COMMAND"] && (
                    <option value="PC">{t("form.actionTypePC")}</option>
                  )}
                </TextField>
              </StyledBox>

              <StyledBox sx={{ width: "100%", ml: 2 }}>
                <InputLabel>{t("form.actionLabel")}</InputLabel>

                <TextField
                  size="small"
                  {...register("COMMAND")}
                  select
                  margin="dense"
                  SelectProps={{
                    native: true,
                  }}
                  required
                  fullWidth
                  helperText={errors.COMMAND && t("validation.actionEmpty")}
                  disabled={loading}
                >
                  <option value="">{t("action.select")}</option>
                  {watchAllFields.TYPE === "CONTENT" &&
                  features["SCHEDULE.CONTENT"] ? (
                    <option value="PLAY_CONTENT">
                      {t("action.playContent")}
                    </option>
                  ) : null}

                  {watchAllFields.TYPE === "PC" &&
                  features["SCHEDULE.COMMAND"] ? (
                    <option value="REBOOT">{t("action.reboot")}</option>
                  ) : null}
                  {watchAllFields.TYPE === "PC" &&
                  features["SCHEDULE.COMMAND"] ? (
                    <option value="SHUTDOWN">{t("action.shutDown")}</option>
                  ) : null}

                  {watchAllFields.TYPE === "DISPLAY" &&
                  features["SCHEDULE.COMMAND"] ? (
                    <option value="DISPLAY_ON">{t("action.turnOn")}</option>
                  ) : null}
                  {watchAllFields.TYPE === "DISPLAY" &&
                  features["SCHEDULE.COMMAND"] ? (
                    <option value="DISPLAY_OFF">{t("action.turnOff")}</option>
                  ) : null}
                  {watchAllFields.TYPE === "DISPLAY" &&
                  features["SCHEDULE.COMMAND"] ? (
                    <option value="DISPLAY_HDMI1">{t("action.HDMI1")}</option>
                  ) : null}
                  {watchAllFields.TYPE === "DISPLAY" &&
                  features["SCHEDULE.COMMAND"] ? (
                    <option value="DISPLAY_HDMI2">{t("action.HDMI2")}</option>
                  ) : null}
                </TextField>

                <TypographySmall>
                  {t("form.actionTypeFillHint")}
                </TypographySmall>
              </StyledBox>
            </Box>

            {watchAllFields.TYPE !== "" && watchAllFields.COMMAND !== "" && (
              <StyledBox>
                <InputLabel>{t("form.frequencyLabel")}</InputLabel>

                <TextField
                  size="small"
                  margin="dense"
                  {...register("frequency")}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  required
                  fullWidth
                  helperText={errors.frequency?.message}
                  disabled={loading}
                >
                  <option value="">{t("form.frequencySelect")}</option>
                  {watchAllFields.TYPE !== "CONTENT" && (
                    <option value="ONCE">{t("form.frequencyOnce")}</option>
                  )}

                  {watchAllFields.TYPE !== "DISPLAY" &&
                    watchAllFields.TYPE !== "PC" && (
                      <option value="LOOP">{t("form.frequencyLoop")}</option>
                    )}
                  <option value="DAILY">{t("form.frequencyDaily")}</option>
                  <option value="WEEKLY">{t("form.frequencyWeekly")}</option>
                  <option value="MONTHLY">{t("form.frequencyMonthly")}</option>
                </TextField>

                {watchAllFields.frequency !== "ONCE" &&
                  watchAllFields.frequency !== "LOOP" &&
                  watchAllFields.frequency !== undefined &&
                  watchAllFields.frequency !== "" && (
                    <RecurrenceOption
                      frequency={watchAllFields.frequency}
                      setRecurrenceState={handleSetRecurrenceState}
                      getRecurrenceState={handleGetRecurrenceState}
                      loading={loading}
                    />
                  )}

                {/* Additional Option for "Every n Minutes" */}
                {watchAllFields.frequency !== "ONCE" &&
                  watchAllFields.frequency !== "LOOP" &&
                  watchAllFields.frequency !== undefined &&
                  watchAllFields.frequency !== "" && (
                    <>
                      <Box sx={{ mt: 1 }}>
                        <FormControlLabel
                          control={<Checkbox />}
                          value={useMinuteInterval}
                          checked={useMinuteInterval}
                          onChange={handleMinuteIntervalToggle}
                          label={
                            !useMinuteInterval
                              ? t("form.repeatIntervalCBLabel")
                              : t("form.repeatIntervalLabel")
                          }
                          disabled={loading}
                        />
                        {useMinuteInterval && (
                          <>
                            <TextField
                              type="number"
                              value={intervalMinutes}
                              min="1"
                              max="59"
                              onChange={handleMinutesChange}
                              size="small"
                              sx={{ width: 135 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {t("form.repeatIntervalSuffix")}
                                  </InputAdornment>
                                ),
                              }}
                              disabled={loading}
                            />
                          </>
                        )}
                      </Box>
                      {intervalMinutesHelper && (
                        <TypographySmall sx={{ color: "red" }}>
                          {intervalMinutesHelper}
                        </TypographySmall>
                      )}
                    </>
                  )}
              </StyledBox>
            )}

            {!(
              watchAllFields.frequency === "" ||
              watchAllFields.TYPE === "" ||
              watchAllFields.COMMAND === ""
            ) && (
              <Box sx={{ display: "flex", alignItems: "end", mb: 1 }}>
                <StyledBox sx={{ width: "100%", mb: 0 }}>
                  <InputLabel sx={{ mr: 0.5 }}>
                    {t("form.startDateLabel")}
                  </InputLabel>

                  <TextField
                    size="small"
                    fullWidth
                    value={startDate}
                    onChange={handleStartDate}
                    required
                    margin="dense"
                    type="datetime-local"
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: dayjs().format("YYYY-MM-DDT00:00"),
                      },
                    }}
                    disabled={loading}
                  />
                </StyledBox>

                {(watchAllFields.TYPE === "DISPLAY" ||
                  watchAllFields.TYPE === "PC") &&
                watchAllFields.frequency === "ONCE" ? null : (
                  <Box
                    sx={{
                      width: "100%",
                      ml: 2,
                    }}
                  >
                    <Box sx={{ pb: checkboxEndDate ? 0 : 0.5 }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        value={checkboxEndDate}
                        checked={checkboxEndDate}
                        name={"showEndDate"}
                        onChange={(e) => {
                          handleCheckboxEndDate(e.target.checked);
                        }}
                        label={t("form.endDateCBLabel")}
                        disabled={loading}
                      />
                    </Box>

                    {checkboxEndDate && (
                      <StyledBox sx={{ width: "100%" }}>
                        <InputLabel>{t("form.endDateLabel")}</InputLabel>

                        <TextField
                          size="small"
                          fullWidth
                          value={endDate}
                          onChange={handleEndDate}
                          margin="dense"
                          type="datetime-local"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: startDate,
                            },
                          }}
                          disabled={loading}
                        />
                      </StyledBox>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {loadingContentList && customContentList.length === 0 && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ width: "100%", height: 200 }}
              >
                <CircularProgress />
              </Box>
            )}

            {watchAllFields.TYPE === "CONTENT" &&
            watchAllFields.COMMAND === "PLAY_CONTENT" &&
            (!loadingContentList || customContentList.length !== 0) ? (
              <>
                <StyledBox>
                  <InputLabel>{t("form.contentLabel")}</InputLabel>
                  <CustomTextField
                    size="small"
                    select
                    required
                    SelectProps={{ native: true }}
                    fullWidth
                    margin="dense"
                    name="contentSelect"
                    value={selectCustomContent}
                    onChange={(e) => {
                      setSelectCustomContent(e.target.value);
                    }}
                    disabled={loading}
                  >
                    <option value="" disabled>
                      {t("form.contentSelect")}
                    </option>
                    {customContentList.map((content) => (
                      <option value={content.id} key={content.id}>
                        {content.name}
                      </option>
                    ))}
                  </CustomTextField>
                </StyledBox>

                <Box sx={{ display: "flex" }}>
                  {watchAllFields.frequency !== "ONCE" &&
                    watchAllFields.frequency !== "LOOP" && (
                      <StyledBox sx={{ width: "100%" }}>
                        <InputLabel>{t("form.durationLabel")}</InputLabel>

                        <Box display="flex" alignItems="center">
                          <FormControl variant="outlined" margin="dense">
                            <InputLabel id="hours-label">
                              {t("form.durationHourLabel")}
                            </InputLabel>
                            <Select
                              size="small"
                              labelId="hours-label"
                              sx={{ width: 80 }}
                              value={duration.hours}
                              onChange={(e) => handleChangeDuration(e, "hours")}
                              label={t("form.durationHourLabel")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 100,
                                    overflowY: "scroll",
                                  },
                                },
                              }}
                              disabled={loading}
                            >
                              {generateDurationOptions(0, 23).map((hour) => (
                                <MenuItem key={hour} value={hour}>
                                  {hour}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <Box mx={1}>:</Box>

                          <FormControl variant="outlined" margin="dense">
                            <InputLabel id="minutes-label">
                              {t("form.durationMinuteLabel")}
                            </InputLabel>
                            <Select
                              size="small"
                              labelId="minutes-label"
                              sx={{ width: 80 }}
                              value={duration.minutes}
                              onChange={(e) =>
                                handleChangeDuration(e, "minutes")
                              }
                              label={t("form.durationMinuteLabel")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 100,
                                    overflowY: "scroll",
                                  },
                                },
                              }}
                              disabled={loading}
                            >
                              {generateDurationOptions(0, 59).map((minute) => (
                                <MenuItem key={minute} value={minute}>
                                  {minute}
                                </MenuItem>
                              ))}
                            </Select>
                            <TypographySmall>{}</TypographySmall>
                          </FormControl>

                          <Box mx={1}>:</Box>

                          <FormControl variant="outlined" margin="dense">
                            <InputLabel id="seconds-label">
                              {t("form.durationSecondLabel")}
                            </InputLabel>
                            <Select
                              size="small"
                              labelId="seconds-label"
                              sx={{ width: 80 }}
                              value={duration.seconds}
                              onChange={(e) =>
                                handleChangeDuration(e, "seconds")
                              }
                              label={t("form.durationSecondLabel")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 100,
                                    overflowY: "scroll",
                                  },
                                },
                              }}
                              disabled={loading}
                            >
                              {generateDurationOptions(0, 59).map((second) => (
                                <MenuItem key={second} value={second}>
                                  {second}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <TypographySmall>
                          {durationValidation(duration)
                            ? ""
                            : t("validation.durationHint")}
                        </TypographySmall>
                      </StyledBox>
                    )}

                  <StyledBox
                    sx={{
                      width: "100%",
                      ml:
                        watchAllFields.frequency !== "ONCE" &&
                        watchAllFields.frequency !== "LOOP"
                          ? 2
                          : 0,
                    }}
                  >
                    <InputLabel>{t("form.priorityLabel")}</InputLabel>
                    <CustomTextField
                      size="small"
                      select
                      required
                      SelectProps={{ native: true }}
                      fullWidth
                      margin="dense"
                      name="contentSelect"
                      value={selectPriority}
                      onChange={(e) => {
                        setSelectPriority(+e.target.value);
                      }}
                      disabled={loading}
                    >
                      <option value="0">{t("priority.Highest")}</option>
                      <option value="1">{t("priority.High")}</option>
                      <option value="2">{t("priority.Normal")}</option>
                      <option value="3">{t("priority.Low")}</option>
                      <option value="4">{t("priority.Lowest")}</option>
                    </CustomTextField>
                  </StyledBox>
                </Box>
              </>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClickClose} fullWidth>
              {tCommon("cancelBtn")}
            </Button>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("createBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateSchedule;
