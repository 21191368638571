import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Skeleton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GroupBlueIcon,
  ScheduleOrangeIcon,
  SmallScheduleIcon,
  ViewerGreyIcon,
} from "../../icons";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import { SET_DETAIL_GROUP } from "../../store/actionTypes/groupActionType";
import { parseVariables } from "../../helpers/localize";

const useStyles = makeStyles(() => ({
  hover: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: colorStyling.primary,
    },
  },
  hoverPaper: {
    "&:hover": { backgroundColor: colorStyling.white.hover, cursor: "pointer" },
  },
}));

const GroupItem = ({ group, dispatch }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation("group");

  const redirectToGroupDetailPage = (id) => {
    dispatch({ type: SET_DETAIL_GROUP, payload: group });
    navigate("/group/detail/" + id);
  };

  return (
    <>
      <Box
        className={classes.hoverPaper}
        onClick={() => redirectToGroupDetailPage(group.id)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "6px",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", width: 400 }}
            ml={2}
          >
            <GroupBlueIcon />
            <Box ml={2}>
              <Tooltip
                title={group?.name?.length > 30 ? group.name : ""}
                placement="top-start"
              >
                <Box>
                  <TypographyNormal
                    sx={{
                      fontWeight: 600,
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                        color: colorStyling.primary,
                      },
                    }}
                  >
                    {group?.name?.length > 30
                      ? group.name.substring(0, 27).trim() + "..."
                      : group.name}
                  </TypographyNormal>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: "350px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            mx={2}
            display="flex"
            alignItems="center"
            className={classes.hover}
          >
            <ViewerGreyIcon />
            {group.screensCount || group.screensCount === 0 ? (
              <TypographySmall sx={{ marginLeft: "5px", fontWeight: 300 }}>
                {parseVariables(t("item.numViewers"), {
                  num: group.screensCount ?? 0,
                })}
              </TypographySmall>
            ) : (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "12px",
                  lineHeight: "24px",
                  width: "80px",
                }}
              />
            )}
          </Box>

          <SmallScheduleIcon />

          <Box
            ml={2}
            display="flex"
            alignItems="center"
            className={classes.hover}
          >
            <ScheduleOrangeIcon />

            {group.schedulesCount || group.schedulesCount === 0 ? (
              <TypographySmall sx={{ marginLeft: "5px", fontWeight: 300 }}>
                {parseVariables(t("item.numSchedules"), {
                  num: group.schedulesCount ?? 0,
                })}
              </TypographySmall>
            ) : (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "12px",
                  lineHeight: "24px",
                  width: "80px",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GroupItem;
