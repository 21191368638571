import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Menu,
  Radio,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_LOADING_SCHEDULE,
  SET_SCHEDULE_FILTER,
} from "../../store/actionTypes/scheduleActionType";
import dayjs from "dayjs";
import { colorStyling } from "../../helpers/color";

const typeData = [
  { labelID: "Content", value: "CONTENT_SCHEDULE", flag: "SCHEDULE.CONTENT" },
  { labelID: "Command", value: "COMMAND_SCHEDULE", flag: "SCHEDULE.COMMAND" },
];

const ScheduleFilter = () => {
  const dispatch = useDispatch();
  const { scheduleFilters } = useSelector((state) => state.schedule);
  const { features } = useSelector((state) => state.auth);

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [scheduleName, setScheduleName] = useState(scheduleFilters.nameLike);

  const [status, setStatus] = useState(scheduleFilters.isActive);

  const [type, setType] = useState("");

  const [command, setCommand] = useState(scheduleFilters.command);

  const [nextDate, setNextDate] = useState(
    scheduleFilters.startAtLte
      ? dayjs(scheduleFilters.startAtLte).format("YYYY-MM-DD")
      : ""
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const getType = (typeString) => {
    if (
      typeString?.includes("COMMAND_SCHEDULE") &&
      typeString?.includes("CONTENT_SCHEDULE")
    ) {
      setType("");
      return;
    } else {
      if (typeString?.includes("CONTENT_SCHEDULE")) {
        setType("CONTENT_SCHEDULE");
        return;
      }

      if (typeString?.includes("COMMAND_SCHEDULE")) {
        setType("COMMAND_SCHEDULE");
        return;
      }
      setType("");
      return;
    }
  };

  useEffect(() => {
    if (scheduleFilters.type) getType(scheduleFilters.type);
  }, [scheduleFilters.type]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetState = () => {
    setScheduleName("");
    setStatus("");
    setType("");
    setCommand("");
    setNextDate("");
  };

  const handleChangeType = (e) => {
    setCommand("");
    setType(e.target.value);
  };

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_SCHEDULE_FILTER,
      payload,
    });
  };

  const handleLoading = (value) => {
    dispatch({
      type: SET_LOADING_SCHEDULE,
      payload: value,
    });
  };

  const handleChangeSortBy = (value) => {
    handleLoading(true);
    handleDispatchFilter({ ...scheduleFilters, sortBy: value });
  };

  const handleRefresh = () => {
    handleDispatchFilter({ sortBy: "idDesc" });
    resetState();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let query = { ...scheduleFilters };

    if (scheduleName) query["nameLike"] = scheduleName;
    else delete query["nameLike"];

    if (command) query["command"] = command;
    else delete query["command"];

    if (status) query["isActive"] = status;
    else delete query["isActive"];

    if (nextDate) {
      const startDate = new Date(
        dayjs(nextDate).format("YYYY-MM-DDT23:59:59")
      ).toISOString();
      const endDate = new Date(
        dayjs(nextDate).format("YYYY-MM-DDT00:00:00")
      ).toISOString();
      query["startAtLte"] = startDate;
      query["endAtGte"] = endDate;
    } else {
      delete query["startAtLte"];
      delete query["endAtGte"];
    }

    if (type) {
      if (type === "CONTENT_SCHEDULE" || type === "COMMAND_SCHEDULE")
        query["type"] = [type, "REPEATING_" + type];
      else {
        let arrType = [];
        typeData.forEach((data) => {
          arrType.push(data.value);
          arrType.push("REPEATING_" + data.value);
        });
        query["type"] = arrType;
      }
    } else {
      delete query["type"];
    }

    handleLoading(true);
    handleDispatchFilter(query);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          value={scheduleName}
          onChange={(e) => setScheduleName(e.target.value)}
          fullWidth
          type="Search"
          placeholder={t("filter.searchPlaceholder")}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          size="small"
          fullWidth
          select
          SelectProps={{ native: true }}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          sx={{ mx: 2 }}
        >
          <option defaultValue value="">
            {t("filter.statusAny")}
          </option>
          <option value="true">{t("filter.statusActive")}</option>
          <option value="false">{t("filter.statusInactive")}</option>
        </TextField>

        <TextField
          size="small"
          fullWidth
          select
          SelectProps={{ native: true }}
          value={type}
          onChange={handleChangeType}
        >
          <option defaultValue value="">
            {t("filter.typeAny")}
          </option>
          {typeData.map(
            (data) =>
              features[data.flag] && (
                <option key={data.value} value={data.value}>
                  {t(`filter.type${data.labelID}`)}
                </option>
              )
          )}
        </TextField>

        {type === "COMMAND_SCHEDULE" && (
          <TextField
            size="small"
            fullWidth
            select
            SelectProps={{ native: true }}
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            sx={{ ml: 2 }}
          >
            <option defaultValue value="">
              {t("filter.actionAny")}
            </option>
            <option value="REBOOT">{t("filter.actionRebootPC")}</option>
            <option value="SHUTDOWN">{t("filter.actionShutDownPC")}</option>
            <option value="DISPLAY_ON">{t("filter.actionDisplayOn")}</option>
            <option value="DISPLAY_OFF">{t("filter.actionDisplayOff")}</option>
            <option value="DISPLAY_HDMI1">{t("filter.actionHDMI1")}</option>
            <option value="DISPLAY_HDMI2">{t("filter.actionHDMI2")}</option>
          </TextField>
        )}

        <TextField
          type="date"
          size="small"
          fullWidth
          value={nextDate}
          onChange={(e) => setNextDate(e.target.value)}
          sx={{ mx: 2 }}
        />

        <Button variant="contained" type="submit">
          {tCommon("searchBtn")}
        </Button>

        <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: colorStyling.black }} onClick={handleClick}>
            <Tooltip title={tCommon("sortTip")}>
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
              <FormControlLabel
                disableTypography
                value="idDesc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "idDesc"}
                  />
                }
                label={t("filter.sortByNewest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
              <FormControlLabel
                disableTypography
                value="idAsc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "idAsc"}
                  />
                }
                label={t("filter.sortByOldest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameAsc")}>
              <FormControlLabel
                disableTypography
                value="nameAsc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "nameAsc"}
                  />
                }
                label={t("filter.sortByAtoZ")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameDesc")}>
              <FormControlLabel
                disableTypography
                value="nameDesc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "nameDesc"}
                  />
                }
                label={t("filter.sortByZtoA")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </Menu>

          <IconButton
            sx={{ ml: 2, color: colorStyling.black }}
            onClick={handleRefresh}
          >
            <Tooltip title={tCommon("resetFilterTip")}>
              <RestartAltIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ScheduleFilter;
