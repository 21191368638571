import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, ButtonBase, Tooltip, Typography } from "@mui/material";
import { colorStyling } from "../../../helpers/color";
import { TypographyLarge } from "../../customComponent";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import LaunchIcon from "@mui/icons-material/Launch";
import LiveStream from "./LiveStream";
import ContentDetails from "../../cms/contentTemplate/detail/ContentDetails";
import { ScheduleIcon } from "../../../icons";

const PlayingStatus = ({ viewer }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("viewer");

  const [openContentDetail, setOpenContentDetail] = useState(false);
  const [hoveredContent, setHoveredContent] = useState(false);
  const [hoveredSchedule, setHoveredSchedule] = useState(false);

  const handleOpenContentDetail = () => {
    setOpenContentDetail(true);
  };

  const handleCloseContentDetail = () => {
    setOpenContentDetail(false);
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          backgroundColor: colorStyling.white.active,
          borderRadius: 4,
          px: 2,
          py: 1,
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box
            sx={{
              mr: 2.5,
              ml: 2,
              backgroundColor: colorStyling.green,
              borderRadius: 16,
              width: 48,
              height: 48,
            }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <PlayCircleIcon
              sx={{ color: colorStyling.white.full }}
              fontSize="medium"
            />
          </Box>

          <Box display={"flex"} flexDirection={"column"}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: colorStyling.black,
                  fontSize: "18px",
                }}
              >
                {t("item.playingStatus")}
              </Typography>
            </Box>
            <Box>
              <Tooltip
                title={t("item.viewContentDetailsTip")}
                placement="top-start"
              >
                <ButtonBase
                  onClick={handleOpenContentDetail}
                  onMouseEnter={() => setHoveredContent(true)}
                  onMouseLeave={() => setHoveredContent(false)}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <BackupTableIcon
                      fontSize="small"
                      sx={{
                        color: hoveredContent
                          ? colorStyling.primary
                          : colorStyling.orange,
                      }}
                    />
                    <TypographyLarge
                      sx={{
                        ml: "4px",
                        ...(hoveredContent && {
                          color: colorStyling.primary,
                          textDecoration: "underline",
                          fontWeight: 600,
                        }),
                      }}
                    >
                      <b style={{ fontWeight: 600 }}>
                        {t("item.contentLabel")}
                      </b>
                      {viewer?.status?.contentName}
                    </TypographyLarge>
                    <LaunchIcon
                      fontSize="xs"
                      sx={{
                        ml: "4px",
                        color: hoveredContent
                          ? colorStyling.primary
                          : colorStyling.gray.label,
                      }}
                    />
                  </Box>
                </ButtonBase>
              </Tooltip>
              <ContentDetails
                open={openContentDetail}
                handleClose={handleCloseContentDetail}
                contentID={viewer?.status?.contentId}
              />
            </Box>

            <Box>
              <Tooltip
                title={t("item.viewScheduleDetailsTip")}
                placement="top-start"
              >
                <ButtonBase
                  onClick={() =>
                    navigate("/schedule/detail/" + viewer?.status?.scheduleId)
                  }
                  onMouseEnter={() => setHoveredSchedule(true)}
                  onMouseLeave={() => setHoveredSchedule(false)}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <ScheduleIcon
                      width={"20"}
                      height={"20"}
                      colorI={
                        hoveredSchedule
                          ? colorStyling.primary
                          : colorStyling.orange
                      }
                      colorII={
                        hoveredSchedule
                          ? colorStyling.primary
                          : colorStyling.orange
                      }
                    />
                    <TypographyLarge
                      sx={{
                        ml: "4px",
                        ...(hoveredSchedule && {
                          color: colorStyling.primary,
                          textDecoration: "underline",
                          fontWeight: 600,
                        }),
                      }}
                    >
                      <b style={{ fontWeight: 600 }}>
                        {t("item.scheduleLabel")}
                      </b>
                      {viewer?.status?.scheduleName}
                    </TypographyLarge>
                    <LaunchIcon
                      fontSize="xs"
                      sx={{
                        ml: "4px",
                        color: hoveredSchedule
                          ? colorStyling.primary
                          : colorStyling.gray.label,
                      }}
                    />
                  </Box>
                </ButtonBase>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <LiveStream viewer={viewer} />
      </Box>
    </>
  );
};

export default PlayingStatus;
