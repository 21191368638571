import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import { SET_INFO_PROMPT_CMS_PAGE } from "../../../store/actionTypes/cmsActionType";

const ContentTemplateInfoPrompt = () => {
  const dispatch = useDispatch();
  const { t: tCommon } = useTranslation();

  const { isInfoPromptCMSPage: isInfo, infoMessageCMSPage: infoMessage } =
    useSelector((state) => state.cms);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_INFO_PROMPT_CMS_PAGE, payload: false });
  };

  return (
    isInfo && (
      <Alert
        sx={{ my: 2 }}
        severity="info"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{tCommon("infoPromptTitle")}</AlertTitle>
        {infoMessage}
      </Alert>
    )
  );
};

export default ContentTemplateInfoPrompt;
