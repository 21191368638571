import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledTableRow } from "../../customComponent";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  removeContent,
  getAllContents,
} from "../../../store/actions/cmsAction";
import { dateFormat } from "../../../helpers/dateFormat";
import { colorStyling } from "../../../helpers/color";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ContentTemplateItem = ({
  content,
  openContentDetails,
  currentPayload,
  permission,
  selectItem,
  unselectItem,
  selectedFlag,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cmsData } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const contentPermission = permission?.content;
  const assetPermission = permission?.asset;

  const removeContentThenRefresh = async () => {
    setLoadingDelete(true);
    const { isConfirmed } = await withReactContent(Swal).fire({
      title: tCommon("confirmationLabel"),
      html: t("item.deleteConfirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colorStyling.cms.blue,
      cancelButtonColor: colorStyling.cms.red,
      confirmButtonText: tCommon("yesBtn"),
      cancelButtonText: tCommon("cancelBtn"),
      reverseButtons: true,
    });

    if (!isConfirmed) {
      setLoadingDelete(false);
      return;
    }

    dispatch(
      removeContent(content, () => {
        dispatch(getAllContents({ payload: currentPayload, cache: cmsData }));
      })
    );
  };

  const handleCheckbox = () => {
    const updated = !selectedFlag;
    if (updated === true) {
      selectItem(content);
    } else {
      unselectItem(content.id);
    }
  };

  const parseTooltipTitle = (which) => {
    switch (which) {
      case "delete":
        if (contentPermission?.delete) {
          return content.canDelete
            ? t("item.deleteTip")
            : t("item.cantDeleteTip");
        }
        break;

      case "update":
        if (contentPermission?.update && assetPermission?.read) {
          return content.canUpdate ? t("item.editTip") : t("item.cantEditTip");
        }
        break;

      default:
        break;
    }

    return t("item.notAuthorized");
  };

  return (
    <>
      <StyledTableRow>
        <TableCell>
          <Box display="flex" alignItems={"center"}>
            {content.name}
          </Box>
        </TableCell>
        <TableCell align="center">{dateFormat(content.updatedAt)}</TableCell>
        <TableCell align="center">{content.updatedBy?.name ?? "—"}</TableCell>

        <TableCell align="right">
          <Box display="flex" justifyContent={"end"}>
            <Box display={"flex"} alignItems={"center"}>
              <Checkbox
                checked={selectedFlag}
                size="small"
                disabled={!content.canDelete}
                onClick={handleCheckbox}
                sx={{ padding: "4px" }}
              />
            </Box>

            <Tooltip
              arrow
              disableInteractive
              title={parseTooltipTitle("delete")}
            >
              <Box sx={{ width: "fit-content" }}>
                <IconButton
                  color="warning"
                  variant="contained"
                  disabled={
                    loadingDelete ||
                    !content.canDelete ||
                    !contentPermission?.delete
                  }
                  onClick={() => removeContentThenRefresh()}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Tooltip>

            <Tooltip
              arrow
              disableInteractive
              title={parseTooltipTitle("update")}
            >
              <Box sx={{ width: "fit-content" }}>
                <IconButton
                  variant="contained"
                  disabled={
                    loadingDelete ||
                    !content.canUpdate ||
                    !contentPermission?.update ||
                    !assetPermission?.read
                  }
                  onClick={() =>
                    navigate(`/cms/content-template/edit/${content.id}`)
                  }
                  sx={{
                    color: colorStyling.lightgreen,
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Tooltip>

            <Tooltip arrow disableInteractive title={t("item.viewDetailsTip")}>
              <Box sx={{ width: "fit-content" }}>
                <IconButton
                  variant="contained"
                  disabled={loadingDelete}
                  onClick={() => openContentDetails(content.id)}
                  sx={{
                    color: colorStyling.primary,
                  }}
                >
                  <PreviewIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell sx={{ width: 0, padding: 0, position: "relative" }}>
          <Box
            display={loadingDelete ? "flex" : "none"}
            justifyContent={"right"}
            alignItems={"center"}
            width={"5555px"}
            height={"100%"}
            sx={{
              pr: "140px",
              top: 0,
              right: 0,
              opacity: 0.4,
              position: "absolute",
              backgroundColor: "lightgrey",
            }}
          >
            <CircularProgress
              size={22}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
          </Box>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default ContentTemplateItem;
