import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid, Tooltip } from "@mui/material";
import { ViewerBlueIcon, DisplayIcon, ScheduleGreyIcon } from "../../../icons";
import {
  TypographyLarge,
  TypographyNormal,
  TypographyXLarge,
} from "../../customComponent";
import Role from "../../../helpers/roles";
import { formattedDate } from "../../../helpers/dateFormat";
import { colorStyling } from "../../../helpers/color";
import {
  getViewerStatus,
  getViewerTrafficStatus,
} from "../../../helpers/viewerHelper";
import InfoIcon from "@mui/icons-material/Info";
import PlayingStatus from "./PlayingStatus";
import ViewerStatusIndicator from "../ViewerStatusIndicator";

const ViewerInfo = ({ viewer, loading, userRole }) => {
  const viewerIsActive = getViewerStatus(viewer?.activeAt);
  const viewerIsUpdated = getViewerTrafficStatus(viewer?.status?.updatedAt);
  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ViewerBlueIcon />

          <Tooltip
            title={viewer?.name?.length > 30 ? viewer?.name : ""}
            placement="top-start"
          >
            <TypographyXLarge sx={{ ml: 2, fontWeight: 700 }}>
              {viewer?.name?.length > 30
                ? viewer?.name?.substring(0, 27).trim() + "..."
                : viewer?.name}
            </TypographyXLarge>
          </Tooltip>
        </Box>

        <ViewerStatusIndicator viewer={viewer} />
      </Box>

      <Box sx={{ pl: 2, pr: 1 }}>
        <Grid container spacing={2} sx={{ my: 2 }}>
          {userRole === Role.Admin && (
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DisplayIcon />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.idLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {viewer.id}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>
          )}

          {userRole === Role.Admin && (
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DisplayIcon />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.ipAddressLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {viewer.status?.ipAddress ?? "-"}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ScheduleGreyIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("item.lastActiveTimeLabel")}
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {viewer.activeAt ? formattedDate(viewer.activeAt) : "-"}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          {viewer?.status?.version && (
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoIcon sx={{ color: colorStyling.gray.normal }} />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.versionLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {viewer?.status?.version}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>
          )}

          {viewer?.status?.contentId && viewerIsActive && viewerIsUpdated && (
            <Grid item xs={12}>
              <PlayingStatus viewer={viewer} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewerInfo;
