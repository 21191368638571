import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import { colorStyling } from "../../helpers/color";
import { dateFormat, dateOnlyFormatDDMMYYYY } from "../../helpers/dateFormat";
import {
  AdsScheduleIcon,
  AnnouncementPurpleIcon,
  ClockIcon,
  CommandScheduleIcon,
  ContentScheduleIcon,
  DotIcon,
  GroupBlueIcon,
  ViewerBlueIcon,
} from "../../icons";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { calculateDuration } from "../../helpers/scheduleHelper";

const AssignItem = ({ getName, data }) => {
  return (
    <>
      {getName === "schedule" && <ScheduleItem schedule={data} />}
      {getName === "viewer" && <ViewerItem viewer={data} />}
      {getName === "group" && <GroupItem group={data} />}
    </>
  );
};

export default AssignItem;

const ScheduleItem = ({ schedule }) => {
  const { t } = useTranslation("schedule");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {schedule.type === "ANNOUNCEMENT" ? (
        <AnnouncementPurpleIcon />
      ) : schedule.type === "ADVERTISEMENT" ? (
        <AdsScheduleIcon />
      ) : schedule.type.includes("CONTENT") ? (
        <ContentScheduleIcon />
      ) : (
        <CommandScheduleIcon />
      )}

      <Box
        sx={{
          ml: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TypographyNormal sx={{ fontWeight: 600, mb: 1 }}>
          {schedule.name}
        </TypographyNormal>
        {schedule.type === "ADVERTISEMENT" && (
          <Box display="inline-flex" flexWrap="wrap" alignItems="center">
            <TypographySmall
              display="flex"
              alignItems="center"
              sx={{
                mx: 0.5,
                fontWeight: 400,
              }}
            >
              {dateOnlyFormatDDMMYYYY(schedule.startAt) +
                " - " +
                (schedule.endAt
                  ? dateOnlyFormatDDMMYYYY(schedule.endAt)
                  : t("item.endTimeIndefinitely"))}
            </TypographySmall>

            <svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3" cy="3" r="3" fill={colorStyling.gray.normal} />
            </svg>

            <TypographySmall
              display="flex"
              alignItems="center"
              sx={{
                mx: 0.5,
                fontWeight: 400,
              }}
            >
              {"Slot " + calculateDuration(+schedule.advertDuration)}
            </TypographySmall>
          </Box>
        )}
        {schedule.type !== "ANNOUNCEMENT" &&
          schedule.type !== "ADVERTISEMENT" && (
            <Box display="flex" alignItems="center">
              <ClockIcon />
              <TypographySmall
                sx={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  textTransform: "uppercase",
                  color: colorStyling.primary,
                  fontWeight: 400,
                }}
              >
                {schedule.frequency === "OFF" ? (
                  <>
                    {schedule.type.includes("CONTENT") ? (
                      <>
                        <Tooltip
                          arrow
                          disableInteractive
                          placement="top"
                          title={t("item.explainLOOP")}
                        >
                          {t("frequency.LOOP")}
                        </Tooltip>
                      </>
                    ) : (
                      t("frequency.ONCE")
                    )}
                  </>
                ) : (
                  t(`frequency.${schedule.frequency}`, {
                    defaultValue: null,
                  }) ?? schedule.frequency
                )}
              </TypographySmall>
              <DotIcon />
              <TypographySmall sx={{ marginLeft: "5px", fontWeight: 300 }}>
                {dateFormat(schedule.startAt)}
                {schedule.endAt ? " - " + dateFormat(schedule.endAt) : ""}
              </TypographySmall>
            </Box>
          )}
      </Box>
    </Box>
  );
};

const ViewerItem = ({ viewer }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ViewerBlueIcon />

      <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {viewer.name}
        </TypographyNormal>
      </Box>
    </Box>
  );
};

const GroupItem = ({ group }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <GroupBlueIcon />

      <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {group.name}
        </TypographyNormal>
      </Box>
    </Box>
  );
};
