import {
  SET_LOADING_CMS,
  SET_CMS_DATA,
  SET_ASSET_DATA,
  SET_REMOVE_MEDIA_DATA,
  SET_ERROR_PROMPT_CMS_PAGE,
  SET_ERROR_MESSAGE_CMS_PAGE,
  SET_MEDIA_DATA,
  SET_MEDIA_DATA_LENGTH,
  SET_LOADING_MEDIA,
  SET_SUCCESS_PROMPT_CMS_PAGE,
  SET_SUCCESS_MESSAGE_CMS_PAGE,
  SET_UPLOADING_MEDIA,
  SET_UPLOAD_MEDIA_DATA,
  SET_UPDATE_UPLOAD_STATE,
  SET_UPDATE_UPLOAD_RESPONSE,
  SET_CONTENT_NAME,
  SET_CONTENT_LAYERS,
  SET_TEXT_LAYERS,
  SET_CONTENT_RESOLUTION,
  SET_CONTENT_RESOLUTION_RATIO,
  SET_CANVAS_RES,
  SET_LAYER_MEDIAS,
  SET_CMS_ERROR_HANDLER,
  SET_LOADING_SUBMIT_CONTENT,
  SET_LOADING_CONTENT_DETAIL,
  SET_CONTENT_IS_EDITING,
  SET_CONTENT_DETAIL,
  SET_ADS_PLACEHOLDER_FLAG,
  SET_FORCE_LOAD,
  SET_CONTENT_LIST_AFTER_ADD,
  SET_CONTENT_LIST_AFTER_REMOVE,
  SET_CMS_DETAILS,
  SET_CONTENT_LAYER_ARRAY,
  SET_MEDIA_DATA_FILTER,
  SET_MEDIA_ERRORS,
  SET_INFO_PROMPT_CMS_PAGE,
  SET_INFO_MESSAGE_CMS_PAGE,
} from "../actionTypes/cmsActionType";
import { handleError } from "../handleError";

import {
  assetCMSAPILimit,
  instanceCMSAssetAPI,
  instanceCMSContentAPI,
  contentCMSAPILimit,
  initiateUserAPI,
  instanceCMSAssetTextAPI,
  instanceCMSAssetAdsAPI,
} from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { dataURLtoFile, resizeImage } from "../../helpers/fileProcessing";
import { logger } from "../../helpers/logger";
import { parseVariables } from "../../helpers/localize";

import i18n from 'i18next';
const t = i18n.getFixedT(null, "cms");

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: false });
  }, 5000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: false });
  }, 5000);
};

const handleTimeoutInfoPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_INFO_PROMPT_CMS_PAGE, payload: false });
  }, 5000);
};

export const setLoadingMedia = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_MEDIA, payload: payload });
  };
};

export const setContentName = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_NAME, payload: payload });
  };
};

export const setContentLayers = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_LAYERS, payload: payload });
  };
};

export const setLayerArray = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_LAYER_ARRAY, payload: payload });
  };
};

export const setTextLayers = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEXT_LAYERS, payload: payload });
  };
};

export const updateContentLayerById = (payload) => {
  return async (dispatch) => {
    const { contentLayers, layerID, update } = payload;
    const updated = { ...contentLayers };
    updated[layerID] = update;
    dispatch({ type: SET_CONTENT_LAYERS, payload: updated });
  };
};

export const setContentResolution = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_RESOLUTION, payload: payload });
  };
};

export const setContentResolutionRatio = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTENT_RESOLUTION_RATIO, payload: payload });
  };
};

export const setCanvasRes = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CANVAS_RES, payload: payload });
  };
};

export const setLayerMedias = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_LAYER_MEDIAS, payload: payload });
  };
};

export const setUploadingMedia = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_UPLOADING_MEDIA, payload: payload });
  };
};

export const getUserData = (userID) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const getUrl = `${initiateUserAPI}${userID}`;

    try {
      const { data } = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      return data;
    } catch (e) {
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: t("api.getUserDataError"),
      });
  
      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data));
      }
    }
  };
};

export const getAllContents = ({ payload, cache, append, filter, cacheDetails }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    logger.log(`getAllContents:`, {payload, cache, append, filter, cacheDetails});
    if (filter || cache?.items?.length === 0) {
      dispatch({ type: SET_LOADING_CMS, payload: true });
    }

    const { limit, offset, searchIDs, searchNames, nameLike, sortBy, searchByUserIDs } = payload;
    let getURL = `${contentCMSAPILimit}`;
    if (limit >= 0) {
      getURL = `${instanceCMSContentAPI}?limit=${limit || 10}`;
    }

    // parsing payload to query
    getURL += `&offset=${offset || 0}`;
    if (searchIDs?.length > 0) {
      searchIDs.forEach((id) => {
        getURL += `&id=${id}`;
      });
    }
    if (searchNames?.length > 0) {
      searchNames.forEach((name) => {
        getURL += `&name=${name}`;
      });
    }
    if (nameLike?.length > 0) {
      getURL += `&nameLike=${nameLike}`;
    }
    if (sortBy?.length > 0) {
      getURL += `&sortBy=${sortBy}`;
    }
    if (searchByUserIDs?.length > 0) {
      searchByUserIDs.forEach((id) => {
        getURL += `&updatedById=${id}`;
      });
    }

    try {
      const { data } = await initiateAXIOS.get(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let fetchedData = [];
      const items = data.items;
      for (let i=0; i<items.length; i++) {
        fetchedData.push(items[i]);
      }
      dispatch(updateDetailsArray({ cache: cacheDetails, data: fetchedData }));

      let updated = cache?.items;
      if (append && cache?.items?.length > 0) {
        let appendedLength = cache.items.length + limit;
        if (appendedLength <= offset) {
          const paddingLength = offset - appendedLength + limit;
          const paddingArray = new Array(paddingLength).fill({}).map((_, index) => {
            return {
              id: index + 1,
              name: "..........",
              campaignName: ".....",
            };
          });
          updated = updated.concat(paddingArray);
        }
        
        updated = updated.concat(fetchedData);
        dispatch({ type: SET_CMS_DATA, payload: {
          items: updated,
          totalItem: data.totalItem,
        }});
      } else {
        if (cache?.items?.length > 0 && !filter) {
          updated.splice(offset, limit, ...fetchedData);
          dispatch({ type: SET_CMS_DATA, payload: {
            items: updated,
            totalItem: data.totalItem,
          }});
        } else {
          dispatch({ type: SET_CMS_DATA, payload: {
            items: fetchedData,
            totalItem: data.totalItem,
          }});
        }
      }
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_CMS, payload: false });
      dispatch({ type: SET_FORCE_LOAD, payload: false });
      if (callback) callback();
    }
  };
};

export const getAllAssets = ({ payload, cache, append, filter }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const { limit, offset, type, searchIDs, searchNames, nameLike, campaignNameLike } = payload;
    if (filter || cache?.length === 0) {
      dispatch({ type: SET_LOADING_MEDIA, payload: true });
    }

    let getURL = `${assetCMSAPILimit}`;
    if (limit >= 0) {
      getURL = `${instanceCMSAssetAPI}?limit=${limit || 10}`;
    }

    // parsing payload to query
    let updateFilter = {};
    getURL += `&offset=${offset || 0}`;
    if (searchIDs?.length > 0) {
      searchIDs.forEach((id) => {
        getURL += `&id=${id}`;
      });
    }
    if (searchNames?.length > 0) {
      searchNames.forEach((name) => {
        getURL += `&name=${name}`;
      });
    }
    if (nameLike?.length > 0) {
      getURL += `&nameLike=${nameLike}`;
      updateFilter["searchInput"] = nameLike;
      updateFilter["searchBy"] = "name";
    }
    if (campaignNameLike?.length > 0) {
      getURL += `&campaignNameLike=${campaignNameLike}`;
      updateFilter["searchInput"] = campaignNameLike;
      updateFilter["searchBy"] = "campaignName";
    }
    if (type?.length > 0) {
      getURL += `&type=${type}`;
      updateFilter["mediaType"] = type;
    } else {
      getURL += `&type=IMAGE&type=VIDEO`;
      updateFilter["mediaType"] = "";
    }

    if (filter) {
      dispatch({ type: SET_MEDIA_DATA_FILTER, payload: updateFilter });
    }

    try {
      const { data } = await initiateAXIOS.get(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let fetchedMedia = [];
      for (let i=0; i<data.items.length; i++) {
        const dt = { ...data.items[i] };
        fetchedMedia.push(dt);
      }

      if (cache && cache.length > 0) {
        const updated = cache.concat(fetchedMedia);
        dispatch({ type: SET_MEDIA_DATA, payload: updated });
        dispatch({ type: SET_MEDIA_DATA_LENGTH, payload: updated.length });
      } else {
        dispatch({ type: SET_MEDIA_DATA, payload: fetchedMedia });
        dispatch({ type: SET_MEDIA_DATA_LENGTH, payload: data.totalItem });
      }

      let updated = cache ?? [];
      if (append && cache?.length > 0) {
        updated = updated.concat(fetchedMedia);
        dispatch({ type: SET_MEDIA_DATA, payload: updated });
        dispatch({ type: SET_MEDIA_DATA_LENGTH, payload: data.totalItem });
      } else {
        if (cache?.length > 0 && !filter) {
          updated.splice(offset, limit, ...fetchedMedia);;
          dispatch({ type: SET_MEDIA_DATA, payload: updated });
          dispatch({ type: SET_MEDIA_DATA_LENGTH, payload: data.totalItem });
        } else {
          dispatch({ type: SET_MEDIA_DATA, payload: fetchedMedia });
          dispatch({ type: SET_MEDIA_DATA_LENGTH, payload: data.totalItem });
        }
      }
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_MEDIA, payload: false });
      if (callback) callback();
    }
  };
};

export const uploadMedia = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    const updateUploadState = (payload) => {
      const { status, progress } = payload;
      dispatch({
        type: SET_UPDATE_UPLOAD_STATE,
        payload: { name: payload.name, id: payload.id, status, progress },
      });
    };

    // Insert uploaded file
    const mediaData = [];
    const initUploadingData = {};
    for (let index = 0; index < payload.length; index++) {
      const media = payload[index];
      const initMedia = {
        id: media.id,
        index: media.index,
        file: media.file,
        name: media.name,
        campaignName: media.campaignName,
        type: media.type?.toUpperCase(),
        src: media.fileSrc,
        vidSrc: media.videoSrc,
        dimension: media.dimension,
        duration: media.duration,
        status: "queued",
        progress: 0,
      };

      let thumbnail = null;
      if (media.videoSrc) {
        thumbnail = dataURLtoFile(media.fileSrc, media.name);
        thumbnail = await resizeImage(thumbnail, `image/webp`);
      } else {
        thumbnail = await resizeImage(media.file, `${media.type}/webp`);
      }

      initMedia["thumbnailUrl"] = thumbnail;
      initMedia["thumbnailFile"] = dataURLtoFile(thumbnail, `${media.name}.webp`);
      mediaData.push(initMedia);
      initUploadingData[media.id] = initMedia;
    }

    dispatch({ type: SET_UPLOAD_MEDIA_DATA, payload: initUploadingData });

    for (let i=0; i<mediaData.length; i++) {
      const media = mediaData[i];
      let formdata = new FormData();
      formdata.append("name", media.name);
      formdata.append("file", media.file);
      formdata.append("width", media.dimension.width);
      formdata.append("height", media.dimension.height);
      if (media.campaignName?.length > 0) {
        formdata.append("campaignName", media.campaignName);
      }
      if (media.type === "VIDEO") {
        formdata.append("thumbnail", media.thumbnailFile);
      }

      let uploadedAsset = null;
      try {
        const { data } = await initiateAXIOS.post(
          instanceCMSAssetAPI,
          formdata,
          {
            headers: {
              authorization: `Bearer ${access_token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progress) => {
              const { total, loaded } = progress;
              const totalSizeInMB = total / 1000000;
              const loadedSizeInMB = loaded / 1000000;
              const uploadPercentage = Math.round(
                (loadedSizeInMB / totalSizeInMB) * 100
              );
              updateUploadState({ ...media, status: "uploading", progress: uploadPercentage });
            },
            encType: "multipart/form-data",
          }
        );
        uploadedAsset = { ...media, ...data};
        updateUploadState({ ...media, status: "completed" });
        dispatch({
          type: SET_UPDATE_UPLOAD_RESPONSE,
          payload: { id: mediaData[i].id, response: uploadedAsset },
        });
      } catch (e) {
        // FUTURE: If specific error messages are available
        const message = handleError(e);

        updateUploadState({ ...media, status: "error" });
        mediaData[i].error = true;

        dispatch({ type: SET_MEDIA_ERRORS, payload: { method: "add" } });
        if (e.response?.status >= 500) {
          throw new Error(JSON.stringify(e.response?.data) || message);
        }
      }
    }

    callback(mediaData);
  };
};

export const downloadAsset = async ({ id }) => {
  const access_token = sessionStorage.getItem("access_token");
  let getURL = `${instanceCMSAssetAPI}/${id}/download`;

  try {
    const { data } = await initiateAXIOS.get(getURL, {
      headers: { authorization: `Bearer ${access_token}` },
      responseType: "arraybuffer",
    });

    const blobParse = new Blob([data], { type: "image/png" });
    const srcFile = URL.createObjectURL(blobParse);
    return srcFile;
  } catch (e) {
    const message = handleError(e);
    if (message) {
      return "";
    }
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data) || message);
    }
  }
};

export const removeAsset = ({ id }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const payload = { id };
    let getURL = `${instanceCMSAssetAPI}/${id}`;

    try {
      await initiateAXIOS.delete(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_REMOVE_MEDIA_DATA, payload: payload });
    }
  };
};

export const submitContent = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const { editID, contentLayers, layerMedias, cacheDetails } = payload;
    const layersID = Object.keys(contentLayers);
    dispatch({
      type: SET_CMS_ERROR_HANDLER,
      payload: { reset: true },
    });

    const errorObj = { response: {
      status: 901,
      data: { message: "" },
    }};

    let missingInputs = "";
    if (!payload.name || payload.name.length === 0) {
      missingInputs += t("error.contentName");
      dispatch({
        type: SET_CMS_ERROR_HANDLER,
        payload: { contentName: true },
      });
    }
    if (payload.width === 0 || payload.height === 0) {
      if (missingInputs.length > 0) {
        missingInputs += ", ";
      }
      missingInputs += t("error.resolution");
      dispatch({
        type: SET_CMS_ERROR_HANDLER,
        payload: { resolution: true },
      });
    }

    let checkEmptyMedias = false;
    layersID.forEach((layerId) => {
      if (contentLayers[layerId].text) {
        return;
      }
      if (!layerMedias[layerId] || layerMedias[layerId].length === 0) {
        checkEmptyMedias = true;
      }
    });
    if (checkEmptyMedias) {
      if (missingInputs.length > 0) {
        missingInputs += ", ";
      }
      missingInputs += t("error.layerContent");
      dispatch({
        type: SET_CMS_ERROR_HANDLER,
        payload: { contentLayers: true },
      });
    } else {
      if (!contentLayers || layersID.length === 0) {
        if (missingInputs.length > 0) {
          missingInputs += ", ";
        }
        missingInputs += t("error.noLayer");
        dispatch({
          type: SET_CMS_ERROR_HANDLER,
          payload: { contentLayers: true },
        });
      }
    }

    if (missingInputs.length > 0) {
      errorObj.response.data.message = `${t("error.prefix")} ${missingInputs}.`;
      const message = handleError(errorObj);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });
      handleTimeoutErrorPrompt(dispatch);
      dispatch(setLoadingSubmit(false));
      return;
    }

    let parsedLayers = [];
    for (let i=0; i<layersID.length; i++) {
      const layer = contentLayers[layersID[i]];
      const parsed = {
        name: layer.name.length > 0 ? layer.name : null,
        x: Math.round(layer.dimensions.left * payload.widthRatio),
        y: Math.round(layer.dimensions.top * payload.heightRatio),
        z: layer.dimensions.zIndex - 1, // index start from 1
        width: layer.dimensions.width,
        height: layer.dimensions.height,
        transition: layer.transition ?? "NONE",
      };

      let layerAssets = [];
      let textCount = 1;
      if (!layer.text) {
        const medias = layerMedias[layersID[i]];
        for (let j=0; j<medias.length; j++) {
          const assetObj = {
            duration: medias[j].interval * 1000, // duration in ms
            fit: medias[j].assetFit ?? "FIT",
            order: j,
          };

          let newAsset = null;
          switch (medias[j].appType) {
            case "ads":
              try {
                newAsset = await submitAdsAsset(medias[j]);
                assetObj["id"] = newAsset.id;
              } catch (e) {
                const message = handleError(e);
                dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
                dispatch({
                  type: SET_ERROR_MESSAGE_CMS_PAGE,
                  payload: message,
                });
                handleTimeoutErrorPrompt(dispatch);
                dispatch(setLoadingSubmit(false));
                return;
              }
              break;
              
            default:
              assetObj["id"] = medias[j].assetID;
              if (medias[j].type === "VIDEO") {
                assetObj["isMuted"] = medias[j].isMuted ?? false;
              }
              break;
          }

          layerAssets.push(assetObj);
        }
      } else {
        const textPayload = {
          text: layer.text,
          textName: `${payload.name} [Text #${textCount++}]`,
          backgroundColor: layer.bgColor,
        };

        let newAsset = await submitTextAsset(textPayload);
        if (newAsset) {
          layerAssets.push({
            id: newAsset.id,
            duration: 1000, // not needed but required
            order: 0, // always 0 because only one text for text layer
          });
        }
      }

      parsed["assets"] = layerAssets;
      parsedLayers.push(parsed);
    }

    let body = {
      name: payload.name,
      width: payload.width,
      height: payload.height,
      organizationId: payload.organizationId,
      layers: parsedLayers,
    };
    if (payload.sameName) {
      delete body.name;
    }

    let resData = {};
    try {
      if (!editID || editID.length === 0) {
        resData = await initiateAXIOS.post(
          instanceCMSContentAPI,
          JSON.stringify(body),
          {
            headers: {
              authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        dispatch({ type: SET_CONTENT_LIST_AFTER_ADD, payload: resData.data });
      } else {
        resData = await initiateAXIOS.patch(
          `${instanceCMSContentAPI}${editID}`,
          JSON.stringify(body),
          {
            headers: {
              authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }

      dispatch(updateDetailsArray({ cache: cacheDetails, data: resData.data }));
      dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CMS_PAGE,
        payload: !editID ? t("api.submitSuccessMsg") : t("api.editSuccessMsg"),
      });
      handleTimeoutSuccessPrompt(dispatch);
      
      dispatch({ type: SET_CONTENT_DETAIL, payload: {} });
      dispatch({ type: SET_CONTENT_IS_EDITING, payload: false });
      callback();
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });
      handleTimeoutErrorPrompt(dispatch);
      dispatch(setLoadingSubmit(false));
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SUBMIT_CONTENT, payload: false });
    }
  };
};

export const setErrorHandler = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_CMS_ERROR_HANDLER, payload: payload });
  };
};

export const getAsset = async (assetID) => {
  if (!assetID) {
    logger.error(`error: Asset not found [ID: ${assetID}].`);
  }

  const access_token = sessionStorage.getItem("access_token");
  const getUrl = `${instanceCMSAssetAPI}/${assetID}`;

  try {
    const { data } = await initiateAXIOS.get(getUrl, {
      headers: { authorization: `Bearer ${access_token}` },
    });

    return data;
  } catch (error) {
    logger.error(`error: Asset not found [ID: ${assetID}].`);
  }
};

export const getAssetData = (layerID, payload, noCallApi, callback) => {
  return async (dispatch) => {
    const results = {};
    const placeholderFlag = new Array(payload.length).fill(false);
    for (let i=0; i<payload.length; i++) {
      const assetID = payload[i].id;
      const asset = noCallApi ? payload[i] : await getAsset(assetID);
      results[assetID] = asset;
    };
    dispatch({ type: SET_ASSET_DATA, payload: results });
    dispatch({ type: SET_ADS_PLACEHOLDER_FLAG, payload: {
      id: layerID,
      edit: placeholderFlag,
    }});
    callback(results);
  };
};

export const getContent = ({ id, cache, cacheDetails }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    if (cache?.id > 0 && cache.id.toString() === id) {
      callback(cache);
    } else if (cacheDetails[id]) {
      callback(cacheDetails[id]);
      if (!cacheDetails[id].layers) {
        dispatch({ type: SET_LOADING_CONTENT_DETAIL, payload: true });
      }
    }

    let response;
    try {
      const getUrl = `${instanceCMSContentAPI}${id}`;
      response = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_DETAIL, payload: false });
      callback(response.data);
      dispatch(updateDetailsArray({ cache: cacheDetails, data: response.data }));
    }
  };
};

export const removeContent = ({ id, name }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    let getURL = `${instanceCMSContentAPI}${id}`;

    try {
      await initiateAXIOS.delete(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_CONTENT_LIST_AFTER_REMOVE, payload: { id } });
      dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CMS_PAGE,
        payload: parseVariables(t("api.deleteSuccessMsg"), { name }),
      });
      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_CMS_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      callback();
    }
  };
};

export const removeContentsInBatch = (payload, closeDialog, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const { contents, batchIDs } = payload;
    dispatch({ type: SET_CONTENT_LIST_AFTER_REMOVE, payload: {
      batchIDs,
      callback: closeDialog,
    }});

    dispatch({ type: SET_INFO_PROMPT_CMS_PAGE, payload: true });
    dispatch({
      type: SET_INFO_MESSAGE_CMS_PAGE,
      payload: parseVariables(t("api.batchDeleteMsg"), { count: batchIDs.length }),
    });

    let getURL;
    let errorFlag;
    for (let i=0; i < contents.length; i++) {
      const content = contents[i];
      getURL = `${instanceCMSContentAPI}${content.id}`;

      let error = null;
      try {
        await initiateAXIOS.delete(getURL, {
          headers: { authorization: `Bearer ${access_token}` },
        });

      } catch (e) {
        error = e;
        const message = handleError(e);

        dispatch({ type: SET_ERROR_PROMPT_CMS_PAGE, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE_CMS_PAGE,
          payload: parseVariables(t("api.batchDeleteErrorMsg"), { name: content.name }),
        });

        if (e.response?.status >= 500) {
          throw new Error(JSON.stringify(e.response?.data) || message);
        }
      } finally {
        if (error) {
          errorFlag = true;
        }
      }
    }

    if (errorFlag) {
      dispatch({ type: SET_LOADING_CMS, payload: true });
    } else {
      dispatch({ type: SET_SUCCESS_PROMPT_CMS_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_CMS_PAGE,
        payload: t("api.batchDeleteSuccessMsg"),
      });
      handleTimeoutSuccessPrompt(dispatch);
    }

    handleTimeoutInfoPrompt(dispatch);
    callback();
  };
};

export const setLoadingSubmit = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SUBMIT_CONTENT, payload: payload });
  };
};

export const submitTextAsset = async (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  const text = payload.text;
  let body = {
    name: payload.textName,
    text: {
      text: text.text,
      mode: text.scrolling ? "SCROLLING" : "STATIC",
      scrollingDirection: text.direction,
      scrollingSpeed: text.speed * 40,
      horizontalAlignment: text.hAlign,
      verticalAlignment: text.vAlign,
      color: text.color,
      backgroundColor: payload.backgroundColor,
      fontType: text.family,
      fontSize: parseInt(text.size),
      fontWeight: parseInt(text.weight),
    },
  };

  let uploadedAsset = null;
  try {
    const { data } = await initiateAXIOS.post(
      instanceCMSAssetTextAPI,
      JSON.stringify(body),
      {
        headers: {
          authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    uploadedAsset = data;
  } catch (e) {
    const message = handleError(e);
    logger.error(message);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data) || message);
    }
  } finally {
    return uploadedAsset;
  }
};

export const submitAdsAsset = async (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  let body = { name: payload.name, type: payload.type };
  if (payload.placeholder) {
    body["placeholderId"] = payload.placeholder.id;
  }

  let uploadedAsset = null;
  try {
    const { data } = await initiateAXIOS.post(
      instanceCMSAssetAdsAPI,
      JSON.stringify(body),
      {
        headers: {
          authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    uploadedAsset = data;
  } catch (e) {
    const message = handleError(e);
    logger.error(message);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data) || message);
    }
  } finally {
    return uploadedAsset;
  }
};

export const updateDetailsArray = (payload) => {
  return async (dispatch) => {
    const { cache, data, reset } = payload;
    if (reset) {
      dispatch({ type: SET_CMS_DETAILS, payload: {} });
      return;
    }

    const updated = { ...cache };
    if (Array.isArray(data)) {
      data.forEach((dt) => {
        // update layers is not needed here
        if (!(updated[dt.id]?.layers)) {
          updated[dt.id] = dt;
        }
      });
    } else {
      updated[data.id] = data;
    }

    dispatch({ type: SET_CMS_DETAILS, payload: updated });
  };
};
