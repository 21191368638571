import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Box,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";
import { CustomTextField, TypographySmall } from "../customComponent";
import { Email } from "@mui/icons-material";
import { initiateAXIOS } from "../../config/axios";
import { initiateAuthAPI } from "../../api";
import { handleError } from "../../store/handleError";
import { colorStyling } from "../../helpers/color";

const ErrorPrompt = ({ errorMessage }) => {
  return (
    <Box mb={2}>
      <Alert severity="error">{errorMessage}</Alert>
    </Box>
  );
};

const ForgotPassword = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("user");

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("");

  const handleClickClose = () => {
    setEmail("");
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim().length === 0) {
      setEmptyMessage(t("login.forgotPassEmailEmpty"));
      setEmail(email.trim());
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await initiateAXIOS.post(
        initiateAuthAPI + "reset-password",
        {
          usernameOrEmail: email,
        }
      );

      const resetPasswordToken = data.otpToken;
      sessionStorage.setItem(
        "resetPasswordToken",
        resetPasswordToken || "TOTP"
      );
      sessionStorage.setItem("emailUser", email);
      navigate("/reset-password");
    } catch (error) {
      const errorMsg = handleError(error, "forgotPass", true);
      setIsError(true);
      setErrorMessage(errorMsg);

      const FOUR_SECONDS = 4000; // in milliseconds

      // Your account is locked due to too many password reset attempts. Please contact your supervisor to unlock it.
      const err = error.response.data;
      if (err.code === "2005") {
        sessionStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, FOUR_SECONDS);
      }

      setTimeout(() => {
        setIsError(false);
      }, FOUR_SECONDS);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form">
          <DialogTitle sx={{ textAlign: "center" }}>
            {t("login.forgotPassTitle")}
          </DialogTitle>

          <DialogContent>
            {isError && <ErrorPrompt errorMessage={errorMessage} />}

            <DialogContentText>
              {t("login.forgotPassMessage")}
            </DialogContentText>

            <CustomTextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmptyMessage("");
              }}
              label={t("login.emailLabel")}
              fullWidth
              margin="normal"
              placeholder={t("login.emailPlaceholder")}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />

            {emptyMessage.length > 0 && (
              <Box sx={{ pl: 1, display: "flex", justifyContent: "start" }}>
                <TypographySmall sx={{ color: colorStyling.red }}>
                  {emptyMessage}
                </TypographySmall>
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            {isLoading ? (
              <Button disabled fullWidth variant="contained">
                <CircularProgress size={20} thickness={2} color="inherit" />
              </Button>
            ) : (
              <Button fullWidth onClick={handleSubmit} variant="contained">
                {t("login.sendBtn")}
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
