import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { AccountCircle, Logout } from "@mui/icons-material";
import {
  Toolbar,
  Box,
  Menu,
  Backdrop,
  CircularProgress,
  Button,
  Typography,
  Badge,
} from "@mui/material";
import {
  CustomAppBar,
  CustomMenuItem,
  TypographyLarge,
  TypographyNormal,
} from "./customComponent";
import axios from "../config/axios";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../store/actionTypes/globalActionType";
import { decryptUser } from "../helpers/crypto";
import { colorStyling } from "../helpers/color";

const drawerWidth = 240;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: tCommon } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    const access_token = sessionStorage.getItem("access_token");

    setIsLoading(true);

    try {
      navigate("/login");
      await axios({
        method: "POST",
        url: "/api/auth/logout",
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      dispatch({
        type: SET_GLOBAL_SUCCESS_MESSAGE,
        payload: tCommon("navbar.successMsg"),
      });
    } catch (e) {
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({
        type: SET_GLOBAL_ERROR_MESSAGE,
        payload: e?.response?.data?.message,
      });
      setIsLoading(false);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      dispatch({ type: "LOGOUT" });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    navigate("/myAccount");
    setAnchorEl(null);
  };

  return (
    <>
      <CustomAppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          paddingLeft: "0.5rem",
        }}
      >
        <Toolbar>
          <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}></Box>

          <Button
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Badge
              color="error"
              variant="dot"
              sx={{
                "& .MuiBadge-dot": {
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                },
              }}
              invisible={decryptedLoggedUser.isEmailValid || open}
            >
              <Box>
                <TypographyLarge
                  sx={{
                    fontWeight: 600,
                    color: colorStyling.black,
                  }}
                >
                  {decryptedLoggedUser.name}
                </TypographyLarge>
                <TypographyNormal
                  sx={{
                    fontWeight: 400,
                    color: colorStyling.gray.darkblue,
                  }}
                >
                  {/* TODO: Check new API */}
                  {decryptedLoggedUser?.organization?.name} |{" "}
                  {decryptedLoggedUser?.type}
                </TypographyNormal>
              </Box>
            </Badge>
          </Button>

          <Menu
            id="simple-menu"
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            aria-labelledby="demo-positioned-button"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CustomMenuItem dense onClick={handleAccount}>
              <Badge
                color="error"
                variant="dot"
                invisible={decryptedLoggedUser.isEmailValid}
              >
                <Box display={"flex"} sx={{ pr: 1 }}>
                  <AccountCircle
                    sx={{ width: 20, height: 20, marginRight: "10px" }}
                    color="inherit"
                  />
                  {tCommon("navbar.myAccountBtn")}
                </Box>
              </Badge>
            </CustomMenuItem>
            <CustomMenuItem dense onClick={handleLogout}>
              <Logout
                sx={{ width: 20, height: 20, marginRight: "10px" }}
                color="inherit"
              />
              {tCommon("navbar.logoutBtn")}
            </CustomMenuItem>
            <Backdrop
              sx={{
                color: colorStyling.white.full,
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isLoading}
            >
              <CircularProgress
                size={22}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
            </Backdrop>

            <CustomMenuItem dense disabled>
              <Typography
                component="p"
                sx={{
                  fontSize: ".75rem",
                }}
              >
                {decryptedLoggedUser?.organization?.name} -{" "}
                {decryptedLoggedUser.type}
              </Typography>
            </CustomMenuItem>
          </Menu>
        </Toolbar>
      </CustomAppBar>
    </>
  );
};

export default Navbar;
