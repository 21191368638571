import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../helpers/switchColor";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { CommandScheduleIcon, ContentScheduleIcon } from "../../icons";
import { blue, purple } from "@mui/material/colors";
import { parseExpression } from "cron-parser";
import { formattedDate } from "../../helpers/dateFormat";
import { colorStyling } from "../../helpers/color";
import { SET_SCHEDULE_DETAIL } from "../../store/actionTypes/scheduleActionType";

const useStyles = makeStyles(() => ({
  hover: {
    "&:hover": { backgroundColor: colorStyling.white.hover, cursor: "pointer" },
  },
}));

const ScheduleItem = ({ schedule, dispatch }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { schedule: detail } = useSelector((state) => state.schedule);

  const { t } = useTranslation("schedule");

  const navigateToScheduleDetail = () => {
    const newDetail = {
      ...schedule,
      ...(detail.id === schedule.id && {
        content: detail.content,
        screenGroups: detail.screenGroups,
        screens: detail.screens,
      }),
    };
    dispatch({ type: SET_SCHEDULE_DETAIL, payload: newDetail });
    navigate("/schedule/detail/" + schedule.id);
  };

  return (
    <>
      <Box
        onClick={() => navigateToScheduleDetail()}
        className={classes.hover}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid lightgrey",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}>
          {schedule.type.includes("CONTENT") ? (
            <Tooltip
              arrow
              disableInteractive
              placement="top"
              title={
                <TypographySmall sx={{ color: "white" }}>
                  {t("item.contentScheduleTip")}
                </TypographySmall>
              }
            >
              <Box width={48} height={48}>
                <ContentScheduleIcon />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip
              arrow
              disableInteractive
              placement="top"
              title={
                <TypographySmall sx={{ color: "white" }}>
                  {t("item.commandScheduleTip")}
                </TypographySmall>
              }
            >
              <Box width={48} height={48}>
                <CommandScheduleIcon />
              </Box>
            </Tooltip>
          )}

          <Box display={"flex"} alignItems={"center"} sx={{ ml: 2 }}>
            {schedule.isApproved ? (
              <Tooltip title={t("item.approvedTip")} placement="top">
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t("item.notApprovedTip")} placement="top">
                <ErrorOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("NOT_APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            )}

            <Tooltip
              title={schedule?.name?.length > 30 ? schedule?.name : ""}
              placement="top-start"
            >
              <TypographyNormal sx={{ fontWeight: 600 }}>
                {schedule?.name?.length > 30
                  ? schedule?.name?.substring(0, 27).trim() + "..."
                  : schedule?.name}
              </TypographyNormal>
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: 700,
            minWidth: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {schedule.frequency === "OFF" || schedule.frequency === null ? (
            <TypographyNormal
              display="flex"
              alignItems="center"
              sx={{
                width: 100,
                fontWeight: 600,
                color: blue[600],
                textTransform: "uppercase",
              }}
            >
              {schedule.type.includes("CONTENT") ? (
                <>
                  {t("frequency.LOOP")}
                  <Tooltip
                    arrow
                    disableInteractive
                    placement="top"
                    title={t("item.explainLOOP")}
                  >
                    <InfoOutlinedIcon
                      fontSize="1rem"
                      sx={{ marginLeft: "3px" }}
                    />
                  </Tooltip>
                </>
              ) : (
                t("frequency.ONCE")
              )}
            </TypographyNormal>
          ) : (
            <Tooltip
              title={
                <Box sx={{ fontSize: 14, m: 0.2 }}>
                  {new Date(schedule.startAt) > new Date()
                    ? t("item.startAtTip") + formattedDate(schedule.startAt)
                    : !schedule.endAt ||
                      new Date(schedule.endAt) >
                        new Date(
                          parseExpression(schedule.cron, { tz: "GMT" }).next()
                        )
                    ? t("item.nextAtTip") +
                      formattedDate(
                        parseExpression(schedule.cron, { tz: "GMT" }).next()
                      )
                    : t("item.scheduleCompleteTip")}
                </Box>
              }
              placement="top"
              arrow
              leaveDelay={200}
            >
              <Box display={"flex"} sx={{ width: 100 }}>
                <TodayTwoToneIcon
                  fontSize="small"
                  sx={{
                    color:
                      !schedule.endAt ||
                      new Date(schedule.endAt) >
                        new Date(
                          parseExpression(schedule.cron, { tz: "GMT" }).next()
                        )
                        ? blue[700]
                        : "gray",
                    mr: 0.2,
                  }}
                />
                <TypographyNormal
                  sx={{
                    fontWeight: 600,
                    color: blue[600],
                    textTransform: "uppercase",
                  }}
                >
                  {t(`frequency.${schedule.frequency}`, {
                    defaultValue: null,
                  }) ?? schedule.frequency}
                </TypographyNormal>
              </Box>
            </Tooltip>
          )}

          <TypographySmall
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              p: 1,
              fontWeight: 600,
              minWidth: "80px",
              borderRadius: "15px",
              color: switchColorOnScheduleStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                mr: "5px",
              }}
            />
            {schedule.isActive
              ? t("item.activeLabel")
              : t("item.inactiveLabel")}
          </TypographySmall>

          <TypographyNormal
            sx={{
              width: 100,
              textAlign: "center",
              fontWeight: 600,
              color: purple[600],
              textTransform: "capitalize",
            }}
          >
            {schedule.command.replace(/_/g, " ").toLowerCase()}
          </TypographyNormal>

          <IconButton sx={{ color: colorStyling.black }}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ScheduleItem;
