import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createUserGroup } from "../../../store/actions/userAction";
import {
  SET_ERROR_MESSAGE_USER_DIALOG,
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../../store/actionTypes/userActionType";
import {
  API_LIMIT,
  initiateOrganizationAPI,
  initiatePermissionAPI,
} from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import Role from "../../../helpers/roles";
import { decryptUser } from "../../../helpers/crypto";
import { TypographyLarge, TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { logger } from "../../../helpers/logger";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import { checkUserGroupTooltipPermission } from "../../../helpers/localize";
import { sanitizeInput } from "../../../helpers/inputProcessing";

const CreateUserGroup = () => {
  const dispatch = useDispatch();
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("usergroup");
  const { t: tCommon } = useTranslation();

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userOrg = decryptedLoggedUser.organization.id;
  const userRole = decryptedLoggedUser.type;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameValidation, setNameValidation] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [selectOrg, setSelectOrg] = useState("");
  const [groupName, setGroupName] = useState("");
  const [permission, setPermission] = useState({});

  const getOrganizations = async (query) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setOrganizations(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  const getPermission = async (query) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(initiatePermissionAPI, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setPermission(data);
    } catch (error) {
      logger.error(error);
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_DIALOG,
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (open) {
      if (userRole === Role.Eins) {
        getOrganizations();
      }
      getPermission();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setGroupName("");
    setSelectOrg("");
    setNameValidation("");
    handleChangeAll(false);
    dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: false });
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
    setNameValidation("");
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (groupName.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      setGroupName("");
      return;
    }

    const payload = {
      name: groupName.trim(),
      permission,
      organizationId: selectOrg === "" ? +userOrg : +selectOrg,
    };

    setLoading(true);
    payload["name"] = sanitizeInput(payload.name);
    dispatch(createUserGroup(payload, handleFinishSubmit));
  };

  const handleChangePermission = (e, menu, perm) => {
    setPermission({
      ...permission,
      [menu]: { ...permission[menu], [perm]: e.target.checked },
    });
  };

  const handleChangeAll = (val) => {
    let newObj = {};
    Object.keys(permission).forEach((m) => {
      Object.keys(permission[m]).forEach((p) => {
        newObj = { ...newObj, [m]: { ...newObj[m], [p]: val } };
      });
    });
    setPermission(newObj);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        {t("form.createBtn")}
      </Button>

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("form.title")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
            />
            <Box sx={{ mb: 2, mt: 1 }}>
              <InputLabel>{t("form.groupNameLabel")}</InputLabel>
              <TextField
                fullWidth
                size="small"
                margin="dense"
                name="groupName"
                value={groupName}
                onChange={handleGroupName}
                placeholder={t("form.groupNamePlaceholder")}
                required
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </Box>

            {userRole === Role.Eins && (
              <Box>
                <InputLabel>{t("form.organizationLabel")}</InputLabel>
                <TextField
                  select
                  size="small"
                  margin="dense"
                  fullWidth
                  required
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => setSelectOrg(e.target.value)}
                  value={selectOrg}
                  disabled={loading}
                >
                  <option value="">
                    {t("form.organizationSelectDefault")}
                  </option>
                  {organizations?.map((organization) => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
                </TextField>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Box
                sx={{ mb: 1 }}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel>{t("form.permissionLabel")}</InputLabel>
                <Box>
                  <Button
                    sx={{ mr: "4px" }}
                    onClick={() => handleChangeAll(true)}
                    variant="contained"
                    size="small"
                    disabled={loading}
                  >
                    {t("form.checkAllBtn")}
                  </Button>
                  <Button
                    onClick={() => handleChangeAll(false)}
                    variant="contained"
                    size="small"
                    disabled={loading}
                  >
                    {t("form.uncheckAllBtn")}
                  </Button>
                </Box>
              </Box>
              <Grid container maxHeight={500} sx={{ overflowY: "auto" }}>
                {Object.keys(permission).map((key) => (
                  <Grid item md={3} sx={{ mb: 1 }} key={key}>
                    <TypographyLarge
                      sx={{
                        fontWeight: 600,
                        color: colorStyling.gray.label,
                        textTransform: "capitalize",
                      }}
                    >
                      {key === "assetLog"
                        ? t("form.contentReportLabel")
                        : key.replace(/([A-Z])/g, " $1").trim()}
                    </TypographyLarge>

                    <Box display={"flex"} flexDirection={"column"}>
                      {Object.keys(permission[key]).map((perm) => (
                        <Tooltip
                          title={
                            checkUserGroupTooltipPermission[perm]
                              ? t(`tooltip.${perm}`)
                              : ""
                          }
                          placement="top-start"
                        >
                          <Box display={"flex"} sx={{ mr: 1 }}>
                            <FormControlLabel
                              key={key + perm}
                              sx={{
                                mr: 0.4,
                              }}
                              control={<Checkbox size="small" />}
                              checked={permission[key][perm]}
                              onChange={(e) => {
                                handleChangePermission(e, key, perm);
                              }}
                              label={tCommon(`permission.${perm}`)}
                              disabled={loading}
                            />

                            {perm === "alert" && (
                              <InfoOutlinedIcon fontSize="10" />
                            )}
                          </Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleClickClose}
              variant="outlined"
              fullWidth
              disabled={loading}
            >
              {tCommon("cancelBtn")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("submitBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateUserGroup;
