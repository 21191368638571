import { Box, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { colorStyling } from "../../helpers/color";
import {
  getViewerStatus,
  getViewerTrafficStatus,
} from "../../helpers/viewerHelper";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const ViewerStatusIndicator = ({ size, viewer }) => {
  const { t } = useTranslation("viewer");
  const viewerIsActive = getViewerStatus(viewer.activeAt);
  const viewerIsUpdated = getViewerTrafficStatus(viewer?.status?.updatedAt);

  const [statusText, setStatusText] = useState(t("status.gray"));
  const [colorState, setColorState] = useState({
    left: colorStyling.traffic.gray,
    middle: colorStyling.traffic.gray,
    right: colorStyling.traffic.gray,
  });

  useEffect(() => {
    handleStatusUpdate();

    //eslint-disable-next-line
  }, [viewer]);

  const handleStatusUpdate = () => {
    const statusLogic = {
      gray: !viewerIsActive,

      red:
        viewerIsActive &&
        ((!viewer.status.contentId && !viewer.status.scheduleId) ||
          !viewerIsUpdated),

      yellow:
        viewerIsActive &&
        (viewer.status.displayStatus === "OFF" ||
          viewer.status.displayStatus === "NO_DATA"),

      green:
        viewerIsActive && viewer.status.contentId && viewer.status.scheduleId,
    };

    if (statusLogic.gray) {
      setStatusText(t("status.gray"));
      setColorState({
        left: colorStyling.traffic.gray,
        middle: colorStyling.traffic.gray,
        right: colorStyling.traffic.gray,
      });
      return;
    }

    if (statusLogic.red) {
      setStatusText(t("status.red"));
      setColorState({
        left: colorStyling.traffic.red,
        middle: colorStyling.traffic.gray,
        right: colorStyling.traffic.gray,
      });
      return;
    }

    if (statusLogic.yellow) {
      setStatusText(t("status.yellow"));
      setColorState({
        left: colorStyling.traffic.gray,
        middle: colorStyling.traffic.yellow,
        right: colorStyling.traffic.gray,
      });
      return;
    }

    if (statusLogic.green) {
      setStatusText(t("status.green"));
      setColorState({
        left: colorStyling.traffic.gray,
        middle: colorStyling.traffic.gray,
        right: colorStyling.traffic.green,
      });
      return;
    }
  };

  const circleItem = (color, key) => {
    return (
      <Box
        key={key}
        sx={{
          width: size || 30,
          height: size || 30,
          borderRadius: "50%",
          m: 0.5,
          backgroundColor: color,
        }}
      />
    );
  };

  return (
    <Box>
      <Tooltip
        title={
          <Typography
            sx={{
              color: colorStyling.white.full,
              fontSize: "0.9rem",
              my: 0.25,
            }}
          >
            {statusText}
          </Typography>
        }
        placement="top"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          {circleItem(colorState.left, viewer.name + "tl")}
          {circleItem(colorState.middle, viewer.name + "tm")}
          {circleItem(colorState.right, viewer.name + "tr")}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ViewerStatusIndicator;
