import dayjs from "dayjs";
import { logger } from "./logger";
import ScheduleCalendar from "../models/ScheduleCalendarModel";
import { getNextRunTimesUntilDate, getTimeFromCron } from "./cronHelper";
import L from "../locales/index";
import i18n from "../i18n";
import { colorStyling } from "./color";

let localized;
const calcDay = 24 * 60 * 60 * 1000,
  calcHour = 60 * 60 * 1000,
  calcMinute = 60 * 1000,
  calcSecond = 1000;

export const setPriorityName = (priority) => {
  localized = L[i18n.language]["schedule"];
  switch (priority) {
    case 0:
      return localized["priority"]["Highest"];

    case 1:
      return localized["priority"]["High"];

    case 2:
      return localized["priority"]["Normal"];

    case 3:
      return localized["priority"]["Low"];

    case 4:
      return localized["priority"]["Lowest"];

    default:
      return null;
  }
};

export const formatMs = (ms) => {
  return ms / 1000 + "second";
};

export const getScheduleQuery = (
  features = false,
  includeAnnouncement = false
) => {
  const scheduleType = [
    ...(!features || features["SCHEDULE.COMMAND"]
      ? ["COMMAND_SCHEDULE", "REPEATING_COMMAND_SCHEDULE"]
      : []),
    ...(!features || features["SCHEDULE.CONTENT"]
      ? ["CONTENT_SCHEDULE", "REPEATING_CONTENT_SCHEDULE"]
      : []),
    ...(!features || features["ADVERTISEMENT"] ? ["ADVERTISEMENT"] : []),
    ...(includeAnnouncement && (!features || features["ANNOUNCEMENT"])
      ? ["ANNOUNCEMENT"]
      : []),
  ];

  const scheduleTypeQuery = "&type=" + scheduleType.join("&type=");

  return scheduleTypeQuery;
};

export const getFrequency = (freq, cron) => {
  let newFreq = freq ?? null;
  const cronSplit = cron?.split(" ");
  const dailyCondition = ["*", "1-5", "0,6", "6,0"];

  if (cronSplit) {
    if (cronSplit[3] !== "*") newFreq = "MONTHLY";
    else if (dailyCondition.includes(cronSplit[4])) newFreq = "DAILY";
    else newFreq = "WEEKLY";
  }

  return newFreq;
};

export const calculateDuration = (value) => {
  const day = Math.floor(value / calcDay);
  const hour = Math.floor((value - day * calcDay) / calcHour);
  const minute = Math.floor(
    (value - day * calcDay - hour * calcHour) / calcMinute
  );
  const second = Math.floor(
    (value - day * calcDay - hour * calcHour - minute * calcMinute) / calcSecond
  );

  let stringDuration = "";
  if (day > 0) stringDuration += day + "d ";
  if (hour > 0) stringDuration += hour + "h ";
  if (minute > 0) stringDuration += minute + "m ";
  if (second > 0) stringDuration += second + "s";
  stringDuration = stringDuration.trim();

  return stringDuration;
};

export const generateDurationOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(i.toString().padStart(2, "0"));
  }
  return options;
};

export const durationValidation = (duration) => {
  if (
    duration.hours === "00" &&
    duration.minutes === "00" &&
    duration.seconds === "00"
  ) {
    return false;
  } else {
    return true;
  }
};

export const slotTimeValidation = (startTime, endTime) => {
  localized = L[i18n.language]["advertschedule"];
  if (startTime === endTime) {
    return localized["validation"]["timeCannotBeSame"];
  }

  return "";
};

/**
 *
 * @param {*} value HH:MM:SS duration
 * return miliseconds value
 */
export const convertHHMMSStoMs = (value) => {
  const valueSplit = value.split(":");
  let valueMs = 0;

  // calculate Hour
  const hour = valueSplit[0] * calcHour;
  const minute = valueSplit[1] * calcMinute;
  const second = valueSplit[2] * calcSecond;
  valueMs += hour + minute + second;
  logger.log(valueMs);

  return +valueMs;
};

/**
 *
 * @param {*} value {hours,minutes,seconds} duration
 * return miliseconds value
 */
export const convertDurationtoMs = (value) => {
  let valueMs = 0;

  // calculate Hour
  const hour = +value.hours * calcHour;
  const minute = +value.minutes * calcMinute;
  const second = +value.seconds * calcSecond;
  valueMs += hour + minute + second;
  logger.log(valueMs);

  return +valueMs;
};

/**
 *
 * @param {*} value miliseconds duration
 * return HH:MM:SS value
 */
export const convertMstoHHMMSS = (value) => {
  let stringHHMMSS = "";
  const hour = Math.floor(value / calcHour);
  const minute = Math.floor((value - hour * calcHour) / calcMinute);
  const second = Math.floor(
    (value - hour * calcHour - minute * calcMinute) / calcSecond
  );

  const checkLenght = (val) => {
    let newVal = val;
    if (val < 10) newVal = "0" + val;
    return newVal;
  };

  stringHHMMSS = `${checkLenght(hour)}:${checkLenght(minute)}:${checkLenght(
    second
  )}`;

  return stringHHMMSS;
};

/**
 *
 * @param {*} value miliseconds duration
 * return {hours,minutes,seconds} value
 */
export const convertMstoDuration = (value) => {
  const hour = Math.floor(value / calcHour);
  const minute = Math.floor((value - hour * calcHour) / calcMinute);
  const second = Math.floor(
    (value - hour * calcHour - minute * calcMinute) / calcSecond
  );

  const checkLenght = (val) => {
    let newVal = val;
    if (val < 10) newVal = "0" + val;
    return newVal;
  };

  const dura = {
    hours: checkLenght(hour),
    minutes: checkLenght(minute),
    seconds: checkLenght(second),
  };

  logger.log(dura);

  return dura;
};

export const compareMonthYear = (date1, date2) => {
  const date1Year = dayjs(date1).year();
  const date1Month = dayjs(date1).month();

  const date2Year = dayjs(date2).year();
  const date2Month = dayjs(date2).month();

  if (date1Year === date2Year && date1Month === date2Month) {
    return true;
  }
  return false;
};

export const getCalendarDateQuery = (date) => {
  const newDate = dayjs(date);
  const month = newDate.month() + 1;
  const year = newDate.year();

  const startDate = dayjs(`${year}-${month < 10 ? "0" + month : month}-01`)
    .subtract(7, "day")
    .format("YYYY-MM-DDT00:00:00");
  const endDate = dayjs(
    `${year}-${month + 1 < 10 ? "0" + (month + 1) : month + 1}-01`
  )
    .add(14, "day")
    .format("YYYY-MM-DDT23:59:59");

  return { startDate, endDate };
};

const groupConsecutiveDates = (dates, cron) => {
  let cronSplitMinute = cron.split(" ")[0];
  let result = [];
  let start = dates[0];
  let end = dates[0];

  for (let i = 1; i < dates.length; i++) {
    let currentDate = dates[i];
    let previousDate = dates[i - 1];

    // If current date is consecutive to the previous date
    if (currentDate.diff(previousDate, "day") === 1) {
      end = currentDate;
    } else {
      // Push an object with {start, end} or just {start} if it's a single date
      result.push({ start, end });
      start = currentDate;
      end = currentDate;
    }
  }

  // Push the final range or single date as an object
  result.push({
    start,
    end,
    ...(cronSplitMinute.includes("/") && {
      minuteInterval: cronSplitMinute.split("/")[1],
    }),
  });

  return result;
};

/**
 *
 * @param {*} startHour HH:mm
 * @param {*} duration ms
 */
const calculateEndHourDuration = (startHour, duration) => {
  const parseStart = startHour.split(":");
  const parseDuration = convertMstoHHMMSS(duration).split(":");

  let endHour = +parseStart[0] + +parseDuration[0];
  let endMinute = +parseStart[1] + +parseDuration[1];

  if (endMinute >= 60) {
    endMinute -= 60;
    endHour += 1;
  }
  if (endHour >= 24) endHour -= 24;

  if (endHour === +parseStart[0] && endMinute === +parseStart[1])
    endMinute += 1;

  if (endMinute < 10) endMinute = "0" + endMinute;
  if (endHour < 10) endHour = "0" + endHour;

  return endHour + ":" + endMinute;
};

// Start Date and End Date is calendar date
export const generateCalendarEvents = (scheduleData, startDate, endDate) => {
  let events = [];

  scheduleData.forEach((item) => {
    switch (item.type) {
      case "ADVERTISEMENT":
        let start = dayjs(item.startAt).format("YYYY-MM-DD");
        let end = item.endAt
          ? dayjs(item.endAt).add(1, "day").format("YYYY-MM-DD")
          : dayjs(endDate).format("YYYY-MM-DD");

        events.push(
          new ScheduleCalendar({
            ...item,
            start,
            end,
            startHour: getTimeFromCron(item.cron, item.cronTimeZone),
            endHour: getTimeFromCron(
              item.cron,
              item.cronTimeZone,
              item.duration
            ),
            slot: calculateDuration(+item.advertDuration),
            backgroundColor: colorStyling.green,
          })
        );
        break;

      default:
        // For schedule with repetition or frequency
        if (item.cron) {
          const dayjsStartAt = dayjs(item.startAt);

          // Check if date goes beyond whats shown on calendar
          const startDateCronCond =
            dayjs(startDate).diff(dayjsStartAt) > 0
              ? startDate
              : dayjsStartAt.format("YYYY-MM-DDT00:00:00");

          const endDateCronCond =
            dayjs(endDate).diff(dayjs(item.endAt)) > 0 ? item.endAt : endDate;

          const cronDates = getNextRunTimesUntilDate(
            item.cron,
            startDateCronCond,
            endDateCronCond
          );

          // Grouping consecutive dates to be within 1 event line
          // e.g weekly with mon,tue,wed,sat,sun
          // will have 1 event line for mon until wed (3 days 1 line)
          // and 1 event line for sat  until sun (2 days 1 line)
          const groupedDate = groupConsecutiveDates(cronDates, item.cron);

          groupedDate.forEach((cronDate) => {
            const startDate = cronDate.start;
            const startDateDayjs = dayjs(startDate);
            const startDateYYYYMMDD = startDateDayjs.format("YYYY-MM-DD");
            const startHour = startDateDayjs.format("HH:mm");

            const endDateYYYYMMDD = dayjs(cronDate.end).format("YYYY-MM-DD");
            const endDateCond =
              endDateYYYYMMDD === startDateYYYYMMDD
                ? endDateYYYYMMDD
                : dayjs(cronDate.end).add(1, "day").format("YYYY-MM-DD");

            events.push(
              new ScheduleCalendar({
                ...item,
                start: startDateYYYYMMDD,
                end: endDateCond,
                startHour,
                ...(item.duration && {
                  endHour: calculateEndHourDuration(startHour, item.duration),
                }),
                ...(cronDate.minuteInterval && {
                  minuteInterval: cronDate.minuteInterval,
                }),
              })
            );
          });
        } else {
          // Get calendar start date and end date
          // if schedule startAt within the calendar add Hour = Start Hour - 24:00
          // if not add Hour = 00:00 - 24:00
          //
          // if schedule endAt within calendar  add Hour = 00:00 - End Hour
          let djsStartAt = dayjs(item.startAt);
          let djsEndAt = dayjs(item.endAt);
          const djsStartDate = dayjs(startDate);
          const djsEndDate = dayjs(endDate);

          // Check if start of schedule within calendar
          if (
            djsStartAt.isAfter(djsStartDate) &&
            djsStartAt.isBefore(djsEndDate)
          ) {
            // Add event for the first day hour range
            events.push(
              new ScheduleCalendar({
                ...item,
                start: djsStartAt.format("YYYY-MM-DD"),
                end: djsStartAt.format("YYYY-MM-DD"),
                startHour: djsStartAt.format("HH:mm"),
                ...(item.type !== "COMMAND_SCHEDULE" && { endHour: "23:59" }),
              })
            );

            // Add 1 day for the next day range
            djsStartAt = djsStartAt.add(1, "day");
          }

          // Check if end of schedule within calendar and if end date exist
          if (
            item.endAt &&
            djsEndAt.isAfter(djsStartDate) &&
            djsEndAt.isBefore(djsEndDate)
          ) {
            // Add event for the last day hour range
            events.push(
              new ScheduleCalendar({
                ...item,
                start: djsEndAt.format("YYYY-MM-DD"),
                end: djsEndAt.format("YYYY-MM-DD"),
                startHour: "00:00",
                endHour: djsEndAt.format("HH:mm"),
              })
            );

            if (!djsEndAt.isSame(djsStartAt, "day")) {
              // Add event for the remainder date range until end schedule date
              events.push(
                new ScheduleCalendar({
                  ...item,
                  start: djsStartAt.format("YYYY-MM-DD"),
                  end: djsEndAt.format("YYYY-MM-DD"),
                  startHour: "00:00",
                  endHour: "23:59",
                })
              );
            }
          } else {
            // If no schedule end date or outside calendar range
            // Add event for the remainder date range until end calendar date

            if (item.type !== "COMMAND_SCHEDULE")
              events.push(
                new ScheduleCalendar({
                  ...item,
                  start: djsStartAt.format("YYYY-MM-DD"),
                  end: dayjs(endDate).format("YYYY-MM-DD"),
                  startHour: "00:00",
                  endHour: "23:59",
                })
              );
          }
        }
        break;
    }
  });

  return events;
};
