export default class Group {
  constructor(payload) {
    this.id = payload.id;
    this.name = payload.name;
    this.organizationId = payload.organizationId;
    this.screens = payload?.screens ?? [];
    this.schedules = payload?.schedules ?? [];
    this.screensCount = payload.screens
      ? payload.screens.length
      : Number.isInteger(payload.screensCount)
      ? payload.screensCount
      : null;
    this.schedulesCount = payload.schedules
      ? payload.schedules.length
      : Number.isInteger(payload.schedulesCount)
      ? payload.schedulesCount
      : null;
  }
}
