import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  LinearProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import PreviewIcon from "@mui/icons-material/Preview";
import { styled } from "@mui/material/styles";
import MediaPicker from "./MediaPicker";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../../customComponent";
import { uploadMedia, setErrorHandler } from "../../../store/actions/cmsAction";
import { inputOnlyNumbers } from "../../../helpers/inputProcessing";
import { getImageSource } from "../../../helpers/fileProcessing";
import { colorStyling } from "../../../helpers/color";
import { logger } from "../../../helpers/logger";
import { v4 as uuidv4 } from "uuid";
import parser from "html-react-parser";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const AdContentSetting = ({
  open,
  handleClose,
  addContent,
  container,
  editContent,
  saveEditChanges,
  assetCount,
}) => {
  const dispatch = useDispatch();
  const { layerMedias, uploadingMedia } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [adIndex, setAdIndex] = useState(null);
  const [adType, setAdType] = useState("VISTAR");
  const [adDuration, setAdDuration] = useState(10);
  const [adPlaceholderCheck, setAdPlaceholderCheck] = useState(true);
  const [adPlaceholderFile, setAdPlaceholderFile] = useState({});
  const [assetFit, setAssetFit] = useState("FIT");

  const [openMediaPicker, setOpenMediaPicker] = useState(false);
  const [errNoPlaceholder, setErrNoPlaceholder] = useState(false);
  const [errNotUploaded, setErrNotUploaded] = useState(false);

  const [adPlaceholder, setAdPlaceholder] = useState({});
  const [showThumbnailButton, setShowThumbnailButton] = useState(false);
  const [showThumbnailFull, setShowThumbnailFull] = useState(false);

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const handleOpenMenu = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  const handleOpenMediaPicker = () => {
    setOpenMediaPicker(true);
  };

  const handleCloseMediaPicker = () => {
    setOpenMediaPicker(false);
  };

  const handleAdPlaceholderCheck = (value) => {
    setAdPlaceholderCheck(value);
    setErrNoPlaceholder(false);
    setErrNotUploaded(false);
  };

  useEffect(() => {
    if (open) {
      setAdIndex(null);
      setAdType("VISTAR");
      setAdDuration(10);
      setAdPlaceholder({});
      setAdPlaceholderFile({});
      setAdPlaceholderCheck(true);
      setShowThumbnailButton(false);
      setShowThumbnailFull(false);
      setErrNoPlaceholder(false);
      setErrNotUploaded(false);

      if (editContent) {
        const index = layerMedias[container.id].findIndex(
          (media) => media.id === editContent.id
        );

        setAdIndex(index);
        setAdType(editContent.type.split("_")[0]);
        setAdDuration(editContent.interval);
        setAssetFit(editContent.assetFit);
        if (editContent.placeholder?.id) {
          setAdPlaceholder(editContent.placeholder);
          setAdPlaceholderFile(editContent.placeholder);
          setShowThumbnailButton(true);
        } else {
          setAdPlaceholderCheck(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCloseDialog = async () => {
    handleClose();
    setAdPlaceholderFile({});
  };

  const handleAssetFit = (e) => {
    const value = e.target.value;
    setAssetFit(value);
  };

  const handleAdPlaceholderFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setShowThumbnailButton(false);
    setErrNoPlaceholder(false);
    setErrNotUploaded(false);
    setAdPlaceholder({});
    setAdPlaceholderFile(file);
  };

  const setupAdPlaceholder = (data, order) => {
    if (order.length === 0) {
      dispatch(setErrorHandler({ selectedMedia: true }));
      return;
    }

    let placeholderFile;
    for (let i = 0; i < order.length; i++) {
      placeholderFile = data[order[i]];
      logger.log(`setupAdPlaceholder:`, placeholderFile);
      setAdPlaceholderFile(placeholderFile);
    }

    handleCloseMediaPicker();
    setShowThumbnailButton(true);
    setErrNoPlaceholder(false);
    setErrNotUploaded(false);
    setAdPlaceholder(placeholderFile);
  };

  const uploadNewMedia = async () => {
    const payload = [];
    logger.log(`UPLOADED:`, { adDuration, adPlaceholderFile });
    if (!adPlaceholderFile.name) {
      return;
    }

    await getImageSource({ input: adPlaceholderFile }).then(
      ({ type, url, dimension, videoURL, localURL }) => {
        const placeholder = {
          url,
          id: uuidv4(),
          file: adPlaceholderFile,
          fileSrc: url,
          videoSrc: videoURL,
          type: type,
          name: adPlaceholderFile.name,
          dimension: dimension,
          size: adPlaceholderFile.size,
        };

        setAdPlaceholder(placeholder);
        payload.push(placeholder);

        dispatch(
          uploadMedia(payload, (res) => {
            if (res.length > 0) {
              setAdPlaceholder(res[0]);
              setErrNoPlaceholder(false);
              setErrNotUploaded(false);
              setTimeout(() => {
                setShowThumbnailButton(true);
              }, 1234);
            }
          })
        );
      },
      ({ file, message }) => {
        // WIP
        logger.error(`ERROR:`, message);
      }
    );
  };

  const handleSubmitAdContent = () => {
    const shortUUID = uuidv4().split("-")[0];
    const addedContent = {
      id: shortUUID,
      interval: parseInt(adDuration),
      appType: "ads",
      type: `${adType}_ADVERTISEMENT`,
      name: t(`adContent.name${adType}`),
      assetFit: assetFit,
    };

    if (adPlaceholderCheck) {
      addedContent["placeholder"] = adPlaceholder.response ?? adPlaceholder;
      if (adPlaceholderFile.name && !adPlaceholder.id) {
        setErrNotUploaded(true);
      }
      if (!addedContent.placeholder?.name) {
        setErrNoPlaceholder(true);
        return;
      }
    }

    logger.log(`handleSubmitAdContent:`, addedContent);
    const updatedMedias = {};
    const updatedOrder = [];
    updatedMedias[addedContent.id] = addedContent;
    updatedOrder.push(addedContent.id);
    addContent(updatedMedias, updatedOrder);
  };

  const handleSaveEdit = () => {
    const edited = {
      id: editContent.id,
      interval: parseInt(adDuration),
      appType: "ads",
      type: `${adType}_ADVERTISEMENT`,
      name: t(`adContent.name${adType}`),
      assetFit: assetFit,
    };

    if (adPlaceholderCheck) {
      edited["placeholder"] = adPlaceholder.response ?? adPlaceholder;
      if (adPlaceholderFile.name && !adPlaceholder.id) {
        setErrNotUploaded(true);
      }
      if (!edited.placeholder?.name) {
        setErrNoPlaceholder(true);
        return;
      }
    } else {
      edited["placeholder"] = null;
    }

    logger.log(`handleSaveEdit:`, edited);
    saveEditChanges(adIndex, edited);
    handleClose();
  };

  const submitChanges = () => {
    if (!editContent) {
      handleSubmitAdContent();
    } else {
      handleSaveEdit();
    }
  };

  const parseDialogTitle = useMemo(() => {
    if (!editContent) {
      return t("adContent.addSettingTitle");
    } else {
      return t("adContent.editSettingTitle");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContent]);

  const parseContentSubmitText = useMemo(() => {
    if (!editContent) {
      return tCommon("submitBtn");
    } else {
      return tCommon("saveBtn");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContent]);

  const previewPlaceholder = useMemo(() => {
    if (!adPlaceholder?.name) {
      return null;
    }

    let placeholder = adPlaceholder;
    if (!placeholder.url) {
      placeholder = placeholder.response;
    }

    switch (placeholder.type) {
      case "VIDEO":
        return (
          <video
            is="x-muted"
            draggable={false}
            src={placeholder.url}
            alt={placeholder.name}
            controls={true}
            onClick={(e) => e.stopPropagation()}
            style={{
              minHeight: "100%",
              objectFit: "contain",
            }}
          />
        );

      case "image":
      default:
        return (
          <img
            draggable={false}
            src={placeholder.url}
            alt={t("contentImageAltText")}
            onClick={(e) => e.stopPropagation()}
            style={{
              objectFit: "contain",
            }}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adPlaceholder]);

  const enableDuration = useMemo(() => {
    if (assetCount > 1) {
      return true;
    } else if (assetCount === 1) {
      return !editContent ? true : false;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCount, editContent]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={() => handleCloseDialog()}
    >
      {/* TODO: Change to "Do you want to save your changes?" */}
      {/* <CloseConfirmation
        open={openCloseConfirmation}
        closeDialog={closeCloseConfirmation}
        handleConfirm={handleCloseConfirmation}
      /> */}

      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {parseDialogTitle}
        <IconButton onClick={() => handleCloseDialog()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minHeight: "33vh" }}>
        <MediaPicker
          open={openMediaPicker}
          handleClose={handleCloseMediaPicker}
          name={``}
          addContent={setupAdPlaceholder}
          container={container}
          onlyOne={true}
          noUpload={true}
        />

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ mt: 3, px: 3 }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ p: 4, width: "100%" }}
          >
            <Box
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              sx={{
                py: 2,
                px: 4,
                width: "100%",
                border: "1px solid black",
                borderRadius: "22px",
              }}
            >
              <Box
                width={"70%"}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <StyledBox>
                  <TypographyLarge sx={{ ml: 1 }}>
                    {t("setting.adTypeLabel")}
                  </TypographyLarge>
                  <RadioGroup
                    row
                    value={adType}
                    onChange={(e) => {
                      setAdType(e.target.value);
                    }}
                    sx={{ ml: 2 }}
                  >
                    <FormControlLabel
                      value={"VISTAR"}
                      control={<Radio size="small" />}
                      label={t("setting.adTypeVistar")}
                      slotProps={{ typography: { sx: { fontSize: "14px" } } }}
                    />
                    <FormControlLabel
                      value={"HIVESTACK"}
                      control={<Radio size="small" />}
                      label={t("setting.adTypeHivestack")}
                      slotProps={{ typography: { sx: { fontSize: "14px" } } }}
                    />
                  </RadioGroup>
                </StyledBox>

                <StyledBox>
                  <Box display={"flex"} gap={"3px"} alignItems={"center"}>
                    <TypographyLarge sx={{ ml: 1 }}>
                      {t("setting.durationAdLabel")}
                    </TypographyLarge>
                    <Tooltip
                      arrow
                      disableInteractive
                      placement="top"
                      title={
                        <TypographySmall
                          sx={{ color: "white", textAlign: "center" }}
                        >
                          {t("setting.durationAdTip")}
                        </TypographySmall>
                      }
                    >
                      <InfoOutlinedIcon fontSize="10px" />
                    </Tooltip>
                  </Box>

                  <Box
                    width={"50%"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        enableDuration ? (
                          ""
                        ) : (
                          <TypographySmall
                            sx={{ color: "white", textAlign: "center" }}
                          >
                            {t("setting.durationDisabledTip")}
                          </TypographySmall>
                        )
                      }
                    >
                      <TextField
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={adDuration}
                        disabled={!enableDuration}
                        onChange={(e) => setAdDuration(e.target.value)}
                        onKeyDown={(e) => inputOnlyNumbers(e)}
                        sx={{ pl: 2 }}
                      />
                    </Tooltip>
                    <TypographyNormal flex={"none"}>
                      {t("setting.durationHint")}
                    </TypographyNormal>
                  </Box>
                </StyledBox>

                <Box display={"flex"} alignItems={"center"}>
                  <Checkbox
                    value={adPlaceholderCheck}
                    checked={adPlaceholderCheck}
                    onClick={() =>
                      handleAdPlaceholderCheck(!adPlaceholderCheck)
                    }
                    sx={{ padding: "4px" }}
                  />
                  <InputLabel
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAdPlaceholderCheck(!adPlaceholderCheck)
                    }
                  >
                    <TypographyLarge>
                      {parser(t("setting.placeholderCheckbox"))}
                    </TypographyLarge>
                  </InputLabel>
                </Box>

                {adPlaceholderCheck && (
                  <Box display={"flex"} alignItems={"center"}>
                    <input
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      id="file-upload"
                      type="file"
                      onChange={handleAdPlaceholderFileChange}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Button
                        variant="contained"
                        component="span"
                        onClick={handleOpenMenu}
                        sx={{
                          mr: 1,
                          "&:hover": {
                            backgroundColor: "#0093ff",
                          },
                        }}
                      >
                        {tCommon("chooseBtn")}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorMenuEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        elevation={1}
                        anchorOrigin={{
                          horizontal: "left",
                          vertical: "bottom",
                        }}
                        transformOrigin={{
                          horizontal: "left",
                          vertical: "top",
                        }}
                        sx={{ marginTop: 1 }}
                      >
                        <label htmlFor="file-upload">
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                            }}
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TypographyNormal sx={{ ml: 1 }}>
                              {t("setting.placeholderUploadNew")}
                            </TypographyNormal>
                          </MenuItem>
                        </label>

                        <MenuItem
                          onClick={() => {
                            handleOpenMediaPicker();
                            handleCloseMenu();
                          }}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <TypographyNormal sx={{ ml: 1 }}>
                            {t("setting.placeholderExistingMedia")}
                          </TypographyNormal>
                        </MenuItem>
                      </Menu>
                    </Box>

                    <TextField
                      variant="outlined"
                      value={adPlaceholderFile.name ?? ""}
                      placeholder={t("setting.placeholderPlaceholder")}
                      disabled
                      sx={{
                        // for future reference
                        "& .MuiOutlinedInput-root": {},
                        "& .MuiInputBase-input.Mui-disabled": {
                          py: 0,
                          height: "40px",
                          WebkitTextFillColor: colorStyling.black,
                          backgroundColor: colorStyling.white.inactive,
                          border: errNoPlaceholder && "1px solid red",
                        },
                      }}
                    />

                    <Box
                      display={"flex"}
                      gap={2}
                      alignItems={"center"}
                      sx={{ width: "123px", ml: 2 }}
                    >
                      {!showThumbnailButton && (
                        <>
                          {!uploadingMedia[adPlaceholder.id] ? (
                            <Box>
                              {adPlaceholderFile?.name && (
                                <Button
                                  variant="contained"
                                  sx={{ ml: 2, bgcolor: "#1976d2" }}
                                  onClick={uploadNewMedia}
                                >
                                  {tCommon("uploadBtn")}
                                </Button>
                              )}
                            </Box>
                          ) : (
                            <Box
                              width={60}
                              minHeight={5}
                              sx={{
                                mt: "2px",
                                position: "relative",
                              }}
                            >
                              {uploadingMedia[adPlaceholder.id].status ===
                              "queued" ? (
                                <LinearProgress
                                  sx={{
                                    height: 5,
                                    borderRadius: "11px",
                                  }}
                                  variant="query"
                                />
                              ) : (
                                <LinearProgress
                                  sx={{
                                    height: 5,
                                    borderRadius: "11px",
                                  }}
                                  variant="determinate"
                                  value={
                                    uploadingMedia[adPlaceholder.id].progress
                                  }
                                />
                              )}

                              {uploadingMedia[adPlaceholder.id].status ===
                                "completed" && (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{
                                    p: "1px",
                                    top: "-16px",
                                    right: "-11px",
                                    position: "absolute",
                                    borderRadius: "999px",
                                    backgroundColor: "powderblue",
                                  }}
                                >
                                  <CheckIcon fontSize="small" />
                                </Box>
                              )}

                              {/* // TODO: handling error upon upload */}
                              {/* <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                p: "1px",
                                top: "-16px",
                                right: "-11px",
                                position: "absolute",
                                borderRadius: "999px",
                                backgroundColor: "orangered",
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </Box> */}
                            </Box>
                          )}
                        </>
                      )}

                      {showThumbnailButton && (
                        <Button
                          variant="outlined"
                          fullWidth
                          startIcon={<PreviewIcon />}
                          onClick={() => setShowThumbnailFull(true)}
                          sx={{
                            mr: 1,
                            color: "#1976d2",
                            borderColor: "#1976d2",
                            backgroundColor: "white",
                            "&:hover": {
                              backgroundColor: "#e3f2fd",
                            },
                          }}
                        >
                          {tCommon("previewBtn")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}

                {errNoPlaceholder && (
                  <TypographySmall
                    sx={{
                      ml: 1,
                      mt: "2px",
                      color: "red",
                      fontWeight: 600,
                    }}
                  >
                    {errNotUploaded
                      ? t("setting.errNoPlaceholderNotUploaded")
                      : t("setting.errNoPlaceholderNoFile")}
                  </TypographySmall>
                )}

                <StyledBox>
                  <Box display={"flex"} gap={"3px"} alignItems={"center"}>
                    <TypographyLarge sx={{ ml: 1 }}>
                      {t("setting.assetBehaviorLabel")}
                    </TypographyLarge>
                    <Tooltip
                      arrow
                      disableInteractive
                      placement="top"
                      title={
                        <TypographySmall
                          sx={{ color: "white", textAlign: "center" }}
                        >
                          {t("setting.assetBehaviorTip")}
                        </TypographySmall>
                      }
                    >
                      <InfoOutlinedIcon fontSize="10px" />
                    </Tooltip>
                  </Box>

                  <Box width={"70%"} display={"flex"} alignItems={"center"}>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      size="small"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      value={assetFit}
                      onChange={(e) => {
                        handleAssetFit(e);
                      }}
                      sx={{ pl: 2 }}
                    >
                      <option value={"FIT"} key={"fit"}>
                        {t("setting.assetBehaviorFit")}
                      </option>
                      <option value={"STRETCH"} key={"stretch"}>
                        {t("setting.assetBehaviorStretch")}
                      </option>
                    </TextField>
                  </Box>
                </StyledBox>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mx: 2, mb: 1 }}>
        <>
          <Button variant="outlined" onClick={() => handleCloseDialog()}>
            {tCommon("cancelBtn")}
          </Button>
          <Button variant="contained" onClick={() => submitChanges()}>
            {parseContentSubmitText}
          </Button>
        </>
      </DialogActions>

      <Dialog
        open={showThumbnailFull}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={true}
        onClose={() => setShowThumbnailFull(false)}
        PaperProps={{ sx: { backgroundColor: "black" } }}
      >
        {previewPlaceholder}
      </Dialog>
    </Dialog>
  );
};

export default AdContentSetting;
