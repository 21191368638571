import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  activateSchedule,
  deactivateSchedule,
} from "../../../store/actions/scheduleAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";
import { showSanitizedInput } from "../../../helpers/inputProcessing";

const ActivateSchedule = ({ schedule }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    e.preventDefault();
    dispatch(
      schedule.isActive
        ? deactivateSchedule(schedule.id)
        : activateSchedule(schedule.id)
    );
    setOpen(false);
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={schedule.isApproved ? "" : t("item.activateDisabledTip")}
        placement="top"
      >
        <Box>
          <Button
            variant="contained"
            startIcon={<UpdateIcon fontSize="small" />}
            sx={{ mr: 2, boxShadow: 3 }}
            disabled={schedule.isApproved ? false : true}
            {...(schedule.isApproved && { onClick: () => setOpen(true) })}
          >
            {schedule.isActive
              ? t("item.deactivateBtn")
              : t("item.activateBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {schedule.isActive
              ? parser(
                  parseVariables(t("item.deactivateConfirmationText"), {
                    name: showSanitizedInput(schedule.name),
                  })
                )
              : parser(
                  parseVariables(t("item.activateConfirmationText"), {
                    name: showSanitizedInput(schedule.name),
                  })
                )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {tCommon("continueBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivateSchedule;
