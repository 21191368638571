import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { TypographyNormal, TypographySmall } from "../../../customComponent";
import { inputOnlyNumbers } from "../../../../helpers/inputProcessing";

import DeleteIcon from "@mui/icons-material/Delete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SettingsIcon from "@mui/icons-material/Settings";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { colorStyling } from "../../../../helpers/color";

const ContentItem = ({
  content,
  index,
  removeContent,
  updateInterval,
  updateIsMuted,
  openAdSetting,
  openMediaSetting,
  assetCount,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: content.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { t } = useTranslation("cms");

  const [interval, setInterval] = useState("");
  const [muteVideo, setMuteVideo] = useState(false);

  useEffect(() => {
    setInterval(content.interval);
    setMuteVideo(content.isMuted ?? false);
  }, [content]);

  function handleIntervalChange(event) {
    let value = event?.target?.value;
    if (!value || value.length === 0 || parseInt(value) <= 0) {
      value = 1;
    }

    setInterval(value);
    updateInterval(index, value);
  }

  function handleMuteVideoChange(event) {
    let value = event.target.checked;
    setMuteVideo(value);
    updateIsMuted(index, value);
  }

  function handleOpenSetting() {
    switch (content.appType) {
      default:
        openMediaSetting(content);
        break;

      case "ads":
        openAdSetting(content);
        break;
    }
  }

  const contentName = useMemo(() => {
    switch (content.appType) {
      case "ads":
        return content.name;

      default:
        return content.name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <Box
      ref={setNodeRef}
      style={style}
      key={"content" + index}
      sx={{
        p: 1,
        mx: 1,
        mt: 1,
        width: "100%",
        borderRadius: "4px",
        ":hover": {
          backgroundColor: "rgba(28, 78, 216, 0.1)",
        },
      }}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box display={"flex"} alignItems={"center"} width={"70%"}>
        <Tooltip
          arrow={!isDragging}
          disableInteractive
          title={
            isDragging ? (
              ""
            ) : (
              <TypographySmall sx={{ color: "white", textAlign: "center" }}>
                {t("designer.contentOrderTip")}
              </TypographySmall>
            )
          }
        >
          <Box
            minWidth={"48px"}
            display={"flex"}
            alignItems={"center"}
            {...listeners}
            {...attributes}
            sx={{ cursor: "grab" }}
          >
            <Box display={"flex"} alignItems={"center"} sx={{ mx: "4px" }}>
              <UnfoldMoreIcon
                sx={{
                  ":hover": {
                    border: `2px solid ${colorStyling.primary}`,
                    borderRadius: "4px",
                  },
                }}
              />
            </Box>
            <Box sx={{ textAlign: "right" }}>{`${index + 1}.`}</Box>
          </Box>
        </Tooltip>

        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"inherit"}
          sx={{ ml: 1 }}
        >
          <Tooltip arrow disableInteractive title={contentName}>
            <Box sx={{ maxWidth: "230px" }}>
              <TypographyNormal
                sx={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {contentName}
              </TypographyNormal>
            </Box>
          </Tooltip>

          {content.type === "VIDEO" && (
            <Box display={"flex"} alignItems={"center"}>
              <TypographyNormal>
                {t("designer.contentVideoMuteLabel")}
              </TypographyNormal>
              <Checkbox
                checked={muteVideo === true}
                onChange={handleMuteVideoChange}
                sx={{
                  paddingY: 0,
                  paddingX: 1,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"end"} width={"30%"}>
        <Box display={"flex"} gap={1}>
          <Tooltip arrow disableInteractive title={t("designer.settingTip")}>
            <IconButton onClick={handleOpenSetting}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>

          {assetCount > 1 && (
            <Tooltip
              arrow
              disableInteractive
              placement="top"
              title={t("designer.intervalFieldTip")}
            >
              <TextField
                sx={{ width: "100px" }}
                size="small"
                placeholder="0"
                value={interval}
                onChange={(e) => handleIntervalChange(e)}
                onKeyDown={(e) => inputOnlyNumbers(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t("designer.contentIntervalSuffix")}
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Box sx={{ mx: "2px" }}>
          <Tooltip arrow disableInteractive title={t("designer.removeTip")}>
            <IconButton color="error" onClick={() => removeContent(content.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentItem;
