import React, { forwardRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Typography,
  Button,
  Snackbar,
  AlertTitle,
  Input,
  IconButton,
  InputAdornment,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { initiateAXIOS } from "../../config/axios";
import ForgotPassword from "./ForgotPassword";
import GlobalSuccessPrompt from "../global/GlobalSuccessPrompt";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import {
  Typography2XLarge,
  TypographyNormal,
} from "../../components/customComponent";
import { encrypt } from "../../helpers/crypto";
import {
  SET_AUTH_FEATURES,
  SET_AUTH_USER,
} from "../../store/actionTypes/userActionType";
import GlobalErrorPrompt from "../global/GlobalErrorPrompt";
import { colorStyling } from "../../helpers/color";
import Versioning from "../Versioning";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import { handleError } from "../../store/handleError";
import { parseVariables } from "../../helpers/localize";
import { useRemoteConfig } from "../hooks";
import parser from "html-react-parser";
import { persistor } from "../../store/store";
import { logger } from "../../helpers/logger";
import { coreFeatureFlag, getGlobalKey } from "../../helpers/featureFlag";

const RootBox = styled(Box)(() => ({
  minHeight: "100vh",
  width: "100vw",
  display: "flex",
}));

const LeftBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(11.25),
  paddingRight: theme.spacing(11.25),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minWidth: "540px",
  [theme.breakpoints.down("lg")]: {
    minWidth: "100%",
  },
}));

const RightBox = styled(Box)(({ theme }) => ({
  backgroundColor: colorStyling.primary,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const UsernameInput = styled(Input)(() => ({
  outline: "none",
  border: `1px solid ${grey[300]}`,
  backgroundColor: "white",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: "6px 6px 0px 0px",
  padding: "13px 9px 13px 9px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  fontSize: "1rem",
  lineHeight: "1.625rem",
  "&:focus": {
    border: `2px solid ${colorStyling.primary}`,
  },
  "&.Mui-focused": {
    border: `2px solid ${colorStyling.primary}`,
  },
}));

const PasswordInput = styled(Input)(() => ({
  outline: "none",
  border: `1px solid ${grey[300]}`,
  backgroundColor: "white",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: "0px 0px 6px 6px",
  padding: "13px 9px 13px 9px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  fontSize: "1rem",
  lineHeight: "1.625rem",
  "&:focus": {
    border: `2px solid ${colorStyling.primary}`,
  },
  "&.Mui-focused": {
    border: `2px solid ${colorStyling.primary}`,
  },
}));

const Image = styled("img")(({ theme }) => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
}));

const RightImageContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const RightImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  display: "block",
}));

const ForgotPasswordButton = styled("button")(({ theme }) => ({
  border: "none",
  backgroundColor: colorStyling.white.full,
  color: colorStyling.primary,
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.625rem",
  textAlign: "right",
  cursor: "pointer",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  ":hover": {
    textDecoration: "underline",
  },
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorPrompt = ({ isError, errorMessage, onClose }) => {
  const vertical = "bottom";
  const horizontal = "left";

  return (
    <Snackbar
      open={isError}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("user");

  const [userForm, setUserForm] = useState({
    name: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [isError, setIsError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const [errorPromptMessage, setErrorPromptMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const globalFF = useRemoteConfig(getGlobalKey(), JSON.parse, coreFeatureFlag);

  useEffect(() => {
    const sessionError = location?.state?.error;
    if (sessionError && sessionError.length > 0) {
      setShowSessionExpired(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
    setShowSessionExpired(false);
    setErrorMessage("");
  };

  const handleCloseErrorPrompt = () => {
    setIsError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = userForm.name;
    const password = userForm.password;

    let errorFlag = "";
    if (name.trim().length === 0) {
      setUserForm({ ...userForm, name: name.trim() });
      errorFlag += "n";
    }
    if (password.length === 0) {
      errorFlag += "p";
    }

    if (errorFlag.length > 0) {
      switch (errorFlag) {
        case "n":
          setErrorMessage(t("login.usernameEmpty"));
          break;
        case "p":
          setErrorMessage(t("login.passwordEmpty"));
          break;
        default:
        case "np":
          setErrorMessage(t("login.userpassEmpty"));
          break;
      }
      return;
    }

    const payload = {
      username: userForm.name,
      password: userForm.password,
    };

    await userLogin(payload);
  };

  const userLogin = async (payload) => {
    setLoading(true);
    persistor.persist();
    logger.log("Redux Persist restarted.");

    try {
      const { data } = await initiateAXIOS.post("/api/auth/login", payload);
      const userID = data?.user?.id;

      if (!userID) {
        const requestToken = data?.otpToken;
        const targetOtp = data?.otpTarget;

        sessionStorage.setItem("requestToken", requestToken || "TOTP");
        sessionStorage.setItem("emailUser", targetOtp);
        sessionStorage.setItem("userLogin", encrypt(payload));

        navigateOtp();
      } else {
        const access_token = data?.token;
        sessionStorage.setItem("access_token", access_token);

        if (data.isPasswordExpired) await navigateExpiredPassword();
        else navigateHome(data);
      }
    } catch (error) {
      const data = error?.response?.data;
      if (data?.statusCode === 400 && data?.message?.includes("OTP")) {
        sessionStorage.setItem("requestToken", "TOTP");
        sessionStorage.setItem("emailUser", "TOTP");
        sessionStorage.setItem("userLogin", encrypt(payload));
        navigateOtp();
      } else {
        const errorMsg = handleError(error, "auth", true);
        setIsError(true);
        setErrorPromptMessage(errorMsg);

        setTimeout(() => {
          setIsError(false);
        }, 5000);
        setUserForm({
          name: "",
          password: "",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const navigateExpiredPassword = async () => {
    dispatch({ type: SET_GLOBAL_ERROR, payload: true });
    dispatch({
      type: SET_GLOBAL_ERROR_MESSAGE,
      payload: t("login.passExpiredAlertMsg"),
    });
    handleTimeoutPrompt(dispatch, SET_GLOBAL_ERROR);
    navigate("/expired-password");
  };

  const navigateOtp = () => {
    navigate("/authorization/request-otp");
  };

  const navigateHome = (data) => {
    const user = { ...data.user, permission: data.user.userGroup.permission };
    const encryptedUser = encrypt(user);

    const userROLE = user.type;
    const encryptedRole = encrypt(userROLE);
    localStorage.setItem("permissionRole", encryptedRole);

    const userPermission = user.permission;
    const encryptedPermission = encrypt(userPermission);
    localStorage.setItem("permissionList", encryptedPermission);

    const orgDisabledFeatures = user.organization.featuresBlacklist ?? [];
    if (globalFF?.enabled) {
      const featureFlags = { ...globalFF };
      orgDisabledFeatures.forEach((feature) => {
        featureFlags[feature] = false;
      });
      dispatch({ type: SET_AUTH_FEATURES, payload: featureFlags });
    }

    dispatch({ type: SET_AUTH_USER, payload: encryptedUser });
    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("login.successAlertMsg"),
    });
    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);

    if (userROLE === "ADMIN" || userROLE === "USER") {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <Box component="main">
      <CssBaseline />

      <GlobalSuccessPrompt />
      <GlobalErrorPrompt />
      <ErrorPrompt
        isError={isError}
        errorMessage={errorPromptMessage}
        onClose={() => handleCloseErrorPrompt()}
      />

      <RootBox>
        <LeftBox
          component="form"
          onSubmit={handleSubmit}
          sx={{ backgroundColor: colorStyling.white.full }}
        >
          <Image src="./assets/itxcast-logo.png" alt="ItxCast logo" />
          <Typography
            component="h1"
            fontWeight="600"
            textAlign="center"
            sx={{
              fontSize: "2rem",
              lineHeight: "38px",
              mt: 2,
              mb: 0.5,
            }}
          >
            {parser(
              parseVariables(t("login.title"), { color: colorStyling.primary })
            )}
          </Typography>
          <Typography
            component="p"
            textAlign="center"
            sx={{
              fontSize: "1.125rem",
              lineHeight: "1.6875rem",
              fontWeight: 300,
              color: colorStyling.gray.darkblue,
              mb: 4,
            }}
          >
            {t("login.loginHint")}
          </Typography>
          {showSessionExpired && (
            <Alert sx={{ mb: 2 }} severity="error" variant="filled">
              <AlertTitle>{location?.state?.error}</AlertTitle>
            </Alert>
          )}
          <UsernameInput
            type="text"
            name="name"
            placeholder={t("login.usernameLabel")}
            onChange={handleChange}
            value={userForm.name}
            disabled={loading}
            disableUnderline={true}
          />
          <PasswordInput
            type={showPassword ? "text" : "password"}
            autoComplete="off"
            name="password"
            placeholder={t("login.passwordLabel")}
            onChange={handleChange}
            value={userForm.password}
            disabled={loading}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />

          {errorMessage.length > 0 && (
            <Box sx={{ mt: 1, display: "flex", justifyContent: "start" }}>
              <TypographyNormal sx={{ color: colorStyling.red }}>
                {errorMessage}
              </TypographyNormal>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ForgotPasswordButton
              type="button"
              onClick={() => setOpenForgotPassword(true)}
            >
              {t("login.forgotPassBtn")}
            </ForgotPasswordButton>

            <ForgotPassword
              open={openForgotPassword}
              onClose={() => setOpenForgotPassword(false)}
            />
          </Box>
          {loading ? (
            <Button fullWidth variant="contained" disabled>
              <CircularProgress color="inherit" size={20} thickness={2} />
            </Button>
          ) : (
            <Button type="submit" variant="contained" fullWidth>
              {t("login.confirmBtn")}
            </Button>
          )}

          <Versioning textColor={"black"} />
        </LeftBox>

        <RightBox>
          <RightImageContainer>
            <RightImage src="./assets/Illustration.png" alt="Itxcast screen" />
          </RightImageContainer>

          <Typography2XLarge
            sx={{
              color: "white",
              textAlign: "center",
              px: 14.125,
              letterSpacing: "0.01em",
            }}
          >
            {t("login.titleExplain")}
          </Typography2XLarge>
        </RightBox>
      </RootBox>
    </Box>
  );
};

export default Login;
