import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./LiveRecordingIcon.css"; // Import the CSS file for styling

const LiveRecordingIcon = ({ width, height, sx }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1000); // Toggle visibility every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <Box
      width={width}
      height={height}
      className={`live-recording-icon ${isVisible ? "fade-in" : "fade-out"}`}
      sx={sx}
    ></Box>
  );
};

export default LiveRecordingIcon;
