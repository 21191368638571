import DOMPurify from "dompurify";

export const inputOnlyNumbers = (event) => {
  if (
    (event.key >= '0' && event.key <= '9') ||  // numbers
    event.key === 'Backspace' ||  // backspace
    event.key === 'ArrowUp' ||  // up arrow
    event.key === 'ArrowDown' ||  // down arrow
    event.key === 'ArrowLeft' ||  // left arrow
    event.key === 'ArrowRight'  // right arrow
  ) {
    return; // do nothing, allow the key press
  }

  event.preventDefault(); // prevent default behavior
};

export const isHaveHTMLElement = (input) => {
  const htmlTagPattern = /<\/?[^>]+(>|$)/;
  return htmlTagPattern.test(input); // Returns true if HTML tags are found
};

export const sanitizeInput = (input) => {
  return DOMPurify.sanitize(input);
};

export const showSanitizedInput = (input) => {
  let sanitizedInput = input.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return sanitizedInput;
};
