import axios from "axios";

const baseURL = process.env.REACT_APP_ITXCAST_API_URL;
const disruptionURL = process.env.REACT_APP_TD_API_URL;

const instance = axios.create({ baseURL: baseURL });

export default instance;

export const initiateAXIOS = axios.create({
  baseURL: baseURL,
});

export const disruptionAXIOS = axios.create({
  baseURL: disruptionURL,
});

export const bplrtAXIOS = axios.create({
  baseURL: baseURL,
});

initiateAXIOS.interceptors.request.use((config) => {
  const requestEvent = new CustomEvent("apiRequest", {
    detail: {
      method: config.method,
      url: config.url,
    },
  });
  window.dispatchEvent(requestEvent);
  return config;
});
