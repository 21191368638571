import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import Role from "../helpers/roles";
import { decryptUser } from "../helpers/crypto";
import { externalRoutes } from "../externalFeatures/router";
import { authRoutes, normalRoutes, superRoutes } from "./routerConfig";
import { useRemoteConfig } from "../components/hooks";
import { AuthorizationLandingRoute } from "../auth/AuthorizationRoute";
import { isEmpty } from "lodash";
import * as Sentry from "@sentry/react";
import { coreFeatureFlag, getGlobalKey } from "../helpers/featureFlag";

Sentry.init({
  environment: process.env.REACT_APP_ENV ?? "dev",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RouterProvider = () => {
  const { loggedUser, features } = useSelector((state) => state.auth);
  const [userRole, setUserRole] = useState();
  const [orgFeaturesFlag, setOrgFeaturesFlag] = useState({});

  const globalFF = useRemoteConfig(getGlobalKey(), JSON.parse, coreFeatureFlag);

  useEffect(() => {
    if (Object.keys(loggedUser).length !== 0) {
      const decryptedLoggedUser = decryptUser(loggedUser);
      const orgDisabledFeatures =
        decryptedLoggedUser?.organization?.featuresBlacklist ?? [];
      setUserRole(decryptedLoggedUser.type);
      if (globalFF?.enabled && isEmpty(features)) {
        const featureFlags = { ...globalFF };
        orgDisabledFeatures.forEach((feature) => {
          featureFlags[feature] = false;
        });
        setOrgFeaturesFlag(featureFlags);
      }

      if (!isEmpty(features)) setOrgFeaturesFlag(features);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, features]);

  const allRoutes = [
    ...authRoutes,
    ...(userRole === Role.Eins
      ? superRoutes
      : [...normalRoutes, ...externalRoutes]),
  ];

  const landingRoute = (
    <Route path="/" key="landing" element={<AuthorizationLandingRoute />} />
  );

  return (
    <SentryRoutes>
      {landingRoute}

      {allRoutes
        .filter(
          (route) =>
            /**
             * Show when :
             * - orgFeatureFlag[route.flag] = true
             * - route doesnt have flag
             */
            orgFeaturesFlag[route.flag] || route.flag === undefined
        )
        .map((route, index) => {
          const { path, element, wrapper: Wrapper } = route;
          const routeElement = Wrapper ? <Wrapper>{element}</Wrapper> : element;

          return <Route key={index} path={path} element={routeElement} />;
        })}
    </SentryRoutes>
  );
};

export default RouterProvider;
