import React from "react";
import { MdOutlineScheduleSend } from "react-icons/md";

const CommandScheduleIcon = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#e3f7fb" />
        <foreignObject x="2" y="0" width="48" height="48">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <MdOutlineScheduleSend color="#1c54bf" size={25} />
          </div>
        </foreignObject>
      </svg>
    </>
  );
};

export default CommandScheduleIcon;
