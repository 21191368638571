import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  InputLabel,
  Button,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { editOrganization } from "../../store/actions/organizationAction";
import {
  isHaveHTMLElement,
  sanitizeInput,
} from "../../helpers/inputProcessing";
import { TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";

const EditOrganization = ({ open, onClose, organization }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("organization");
  const { t: tCommon } = useTranslation();

  const [organizationName, setOrganizationName] = useState(organization.name);
  const [nameValidation, setNameValidation] = useState("");

  const handleClickClose = () => {
    onClose(false);
    setNameValidation("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (organizationName.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      setOrganizationName("");
      return;
    }
    if (isHaveHTMLElement(organizationName)) {
      setNameValidation(tCommon("validation.noHTMLAllowed"));
      return;
    }

    const payload = {
      id: organization.id,
      payload: { name: organizationName.trim() },
    };

    payload.payload["name"] = sanitizeInput(payload.payload.name);
    dispatch(editOrganization(payload));
  };

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClickClose} open={open}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {t("editDialogTitle")}
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <InputLabel>{t("form.nameLabel")}</InputLabel>
          <TextField
            fullWidth
            margin="dense"
            value={organizationName}
            onChange={(e) => {
              setOrganizationName(e.target.value);
              setNameValidation("");
            }}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <TypographySmall sx={{ color: colorStyling.red }}>
            {nameValidation}
          </TypographySmall>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickClose} variant="outlined" fullWidth>
            {t("form.cancelBtn")}
          </Button>
          <Button type="submit" variant="contained" fullWidth>
            {t("form.saveBtn")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditOrganization;
