import { assetCMSAPILimit, instanceCMSAssetAPI } from "../../api";
import { initiateAXIOS } from "../../config/axios";
import {
  SET_CONTENT_ASSET,
  SET_SUCCESS_PROMPT_ASSET_PAGE,
  SET_SUCCESS_MESSAGE_ASSET_PAGE,
  SET_ERROR_MESSAGE_ASSET_PAGE,
  SET_ERROR_PROMPT_ASSET_PAGE,
  SET_LOADING_CONTENT_ASSET,
  SET_LOADING_UPDATE_ASSET,
  SET_ASSET_LIST_AFTER_REMOVE,
  SET_INFO_PROMPT_ASSET_PAGE,
  SET_INFO_MESSAGE_ASSET_PAGE,
} from "../actionTypes/assetActionType";
import { handleError } from "../handleError";
import { logger } from "../../helpers/logger";

import i18n from 'i18next';
import { parseVariables } from "../../helpers/localize";
const t = i18n.getFixedT(null, "assetmanager");

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_ASSET_PAGE, payload: false });
  }, 5000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: false });
  }, 5000);
};

const handleTimeoutInfoPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_INFO_PROMPT_ASSET_PAGE, payload: false });
  }, 5000);
};

export const cleanAssetData = (data) => {
  let cleanedData = data.filter(
    (item) =>
      item.assetName !== "Unknown" &&
      item.assetType !== "UNKNOWN" &&
      item.assetType !== "TEXT" &&
      item.assetId
  );

  cleanedData.forEach((item) => {
    if (item.assetCampaignName === null) item.assetCampaignName = "-";
  });

  return cleanedData;
};

export const getContentAssets = ({ payload, cache, append, filter }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    logger.log(`getContentAssets:`, { payload, cache, append, filter });
    if (filter || cache?.items?.length === 0) {
      dispatch({ type: SET_LOADING_CONTENT_ASSET, payload: true });
    }

    const { limit, offset, type, nameLike, campaignNameLike, sortBy, searchDate } = payload;
    let getURL = `${assetCMSAPILimit}`;
    if (limit >= 0) {
      getURL = `${instanceCMSAssetAPI}?limit=${limit || 10}`;
    }

    // parsing payload to query
    getURL += `&offset=${offset || 0}`;
    if (nameLike?.length > 0) {
      getURL += `&nameLike=${nameLike}`;
    }
    if (campaignNameLike?.length > 0) {
      getURL += `&campaignNameLike=${campaignNameLike}`;
    }
    if (type?.length > 0) {
      getURL += `&type=${type}`;
    } else {
      getURL += `&type=IMAGE&type=VIDEO`;
    }
    if (sortBy?.length > 0) {
      getURL += `&sortBy=${sortBy}`;
    } else {
      getURL += `&sortBy=idAsc`;
    }
    if (searchDate?.length > 0) { 
      const startAt = `${searchDate}T00:00:00Z`;
      const endAt = `${searchDate}T23:59:59Z`;
      getURL += `&createdAtGte=${startAt}`;
      getURL += `&createdAtLte=${endAt}`;
    }

    try {
      const { data } = await initiateAXIOS.get(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let fetchedData = [];
      const items = data.items;
      for (let i=0; i<items.length; i++) {
        fetchedData.push(items[i]);
      }

      let updated = cache?.items;
      if (append && cache?.items?.length > 0) {
        let appendedLength = cache.items.length + limit;
        if (appendedLength <= offset) {
          const paddingLength = offset - appendedLength + limit;
          const paddingArray = new Array(paddingLength).fill({}).map((_, index) => {
            return {
              id: index + 1,
              name: "..........",
              campaignName: ".....",
            };
          });
          updated = updated.concat(paddingArray);
        }
        
        updated = updated.concat(fetchedData);
        dispatch({ type: SET_CONTENT_ASSET, payload: {
          items: updated,
          totalItem: data.totalItem,
        }});
      } else {
        if (cache?.items?.length > 0 && !filter) {
          updated.splice(offset, limit, ...fetchedData);
          dispatch({ type: SET_CONTENT_ASSET, payload: {
            items: updated,
            totalItem: data.totalItem,
          }});
        } else {
          dispatch({ type: SET_CONTENT_ASSET, payload: {
            items: fetchedData,
            totalItem: data.totalItem,
          }});
        }
      }
    } catch (e) {
      logger.error(`Error-getContentAssets:`, e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ASSET_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_ASSET, payload: false });
      if (callback) callback();
    }
  };
};

export const updateAsset = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_UPDATE_ASSET, payload: true });
    const { id, name, campaignName } = payload;

    try {
      await initiateAXIOS.patch(`${instanceCMSAssetAPI}/${id}`,
        { name, campaignName },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_SUCCESS_PROMPT_ASSET_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ASSET_PAGE,
        payload: t("api.updateMsg"),
      });
      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ASSET_PAGE, payload: message });
      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_UPDATE_ASSET, payload: false });
      callback();
    }
  };
};

export const removeAsset = ({ id, name }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    let getURL = `${instanceCMSAssetAPI}/${id}`;

    try {
      await initiateAXIOS.delete(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_ASSET_LIST_AFTER_REMOVE, payload: { id } });
      dispatch({ type: SET_SUCCESS_PROMPT_ASSET_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ASSET_PAGE,
        payload: parseVariables(t("api.deleteMsg"), { name }),
      });
      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ASSET_PAGE, payload: message });
      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      callback();
    }
  };
};

export const removeAssetsInBatch = (payload, closeDialog, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    const { assets, batchIDs } = payload;
    dispatch({ type: SET_ASSET_LIST_AFTER_REMOVE, payload: {
      batchIDs,
      callback: closeDialog,
    }});

    dispatch({ type: SET_INFO_PROMPT_ASSET_PAGE, payload: true });
    dispatch({
      type: SET_INFO_MESSAGE_ASSET_PAGE,
      payload: parseVariables(t("api.batchDeleteMsg"), { count: batchIDs.length }),
    });

    let getURL;
    let errorFlag;
    for (let i=0; i<assets.length; i++) {
      const asset = assets[i];
      getURL = `${instanceCMSAssetAPI}/${asset.id}`;

      let error = null;
      try {
        await initiateAXIOS.delete(getURL, {
          headers: { authorization: `Bearer ${access_token}` },
        });

      } catch (e) {
        error = e;
        const message = handleError(e);

        dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE_ASSET_PAGE,
          payload: parseVariables(t("api.batchDeleteErrorMsg"), { name: asset.name }),
        });

        if (e.response?.status >= 500) {
          throw new Error(JSON.stringify(e.response?.data) || message);
        }
      } finally {
        if (error) {
          errorFlag = true;
        }
      }
    }

    if (errorFlag) {
      dispatch({ type: SET_LOADING_CONTENT_ASSET, payload: true });
    } else {
      dispatch({ type: SET_SUCCESS_PROMPT_ASSET_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ASSET_PAGE,
        payload: t("api.batchDeleteSuccessMsg"),
      });
      handleTimeoutSuccessPrompt(dispatch);
    }

    handleTimeoutInfoPrompt(dispatch);
    callback();
  };
};
