import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  DialogTitle,
  IconButton,
  Divider,
  TextField,
  Button,
  InputLabel,
  Checkbox,
  CircularProgress,
  Tooltip,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TypographySmall } from "../customComponent";
import { editUser } from "../../store/actions/userAction";
import { editPasswordUserSchema } from "../../schemas/editPasswordSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_LIMIT, initiateUserGroupAPI } from "../../api";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../store/actionTypes/userActionType";
import { initiateAXIOS } from "../../config/axios";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import parser from "html-react-parser";
import { sanitizeInput } from "../../helpers/inputProcessing";
import { colorStyling } from "../../helpers/color";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: theme.spacing(0),
  },
}));

const UserTextField = styled(OutlinedInput)({
  "& label.Mui-focused": {
    color: blue[900],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: blue[900],
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: blue[900],
    },
    "&:hover fieldset": {
      borderColor: blue[900],
    },
    "&.Mui-focused fieldset": {
      borderColor: blue[900],
    },
  },
});

const EditUser = ({ open, onClose, user }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [selectGroup, setSelectGroup] = useState(user.userGroupId);
  const [userGroups, setUserGroups] = useState([]);

  const [nameValidation, setNameValidation] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [form, setForm] = useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handleVisibilityNewPassword = () => {
    setForm({
      ...form,
      showNewPassword: !form.showNewPassword,
    });
  };

  const handleVisibilityConfirmPassword = () => {
    setForm({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(changePassword ? editPasswordUserSchema : null),
  });

  const handleClickClose = () => {
    onClose(false);
    setChangePassword(false);
    reset({
      "Admin Password": "",
      "New Password": "",
      "Confirm Password": "",
    });
    unregister(["Admin Password", "New Password", "Confirm Password"]);
    setName("");
    setEmail("");
    setSelectGroup("");
    setNameValidation("");

    dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: false });
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const submitEditForm = (data) => {
    if (name.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }

    let payload = {
      id: user.id,
      user: {},
    };

    if (name !== user.name) {
      payload["user"]["name"] = name.trim();
    }

    if (email !== user.email) {
      payload["user"]["email"] = email.trim();
    }

    if (changePassword) {
      payload["user"]["password"] = data["New Password"];
    } else unregister(["Admin Password", "New Password", "Confirm Password"]);

    if (selectGroup !== user.userGroupId)
      payload["user"]["userGroupId"] = +selectGroup;

    setLoading(true);
    payload["user"]["name"] = sanitizeInput(payload["user"]["name"]);
    dispatch(editUser(payload, handleFinishSubmit));
  };

  const stopPropagationForTab = (event) => {
    event.stopPropagation();
  };

  const getUserGroups = async (query) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}` + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setUserGroups(data.items);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (open) {
      setName(user.name);
      setEmail(user.email);
      setSelectGroup(user.userGroupId);
      getUserGroups(`&organizationId=${user.organizationId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        fullWidth
        onKeyDown={stopPropagationForTab}
        onClose={handleClickClose}
      >
        <Box component="form" onSubmit={handleSubmit(submitEditForm)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogTitle>{t("form.editDialogTitle")}</DialogTitle>
            <IconButton
              onClick={handleClickClose}
              sx={{ mr: ".75rem", color: grey[400] }}
            >
              <Close />
            </IconButton>
          </Box>

          <Divider />

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
            />

            <StyledBox sx={{ mt: 1 }}>
              <Tooltip
                title={
                  <TypographySmall sx={{ color: "white" }}>
                    {parser(t("common.userReqHints"))}
                  </TypographySmall>
                }
                placement="top-start"
              >
                <InputLabel>
                  {t("form.usernameLabel")}
                  <InfoOutlinedIcon fontSize="10" />
                </InputLabel>
              </Tooltip>
              <TextField
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameValidation("");
                }}
                autoFocus
                margin="dense"
                fullWidth
                required
                name="name"
                placeholder={t("form.usernamePlaceholder")}
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </StyledBox>

            <StyledBox>
              <InputLabel>{t("form.emailLabel")}</InputLabel>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                margin="dense"
                fullWidth
                required
                name="email"
                type="email"
                placeholder={t("form.emailPlaceholder")}
                disabled={loading}
              />
            </StyledBox>

            <StyledBox>
              <InputLabel>{t("form.userGroupLabel")}</InputLabel>
              <TextField
                select
                margin="dense"
                fullWidth
                required
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => setSelectGroup(e.target.value)}
                value={selectGroup}
                disabled={loading}
              >
                <option value="">{t("form.selectUserGroup")}</option>
                {userGroups?.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </TextField>
            </StyledBox>

            <StyledBox>
              <FormControlLabel
                control={<Checkbox size="small" />}
                value={changePassword}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setChangePassword(true);
                  } else {
                    setChangePassword(false);
                    unregister([
                      "Admin Password",
                      "New Password",
                      "Confirm Password",
                    ]);
                  }
                }}
                label={
                  <TypographySmall sx={{ fontWeight: 300 }}>
                    {t("form.changePasswordCBLabel")}
                  </TypographySmall>
                }
                disabled={loading}
              />
            </StyledBox>

            {changePassword ? (
              <Box>
                <StyledBox>
                  <Tooltip
                    title={
                      <TypographySmall sx={{ color: "white" }}>
                        {parser(t("common.passwordReqHints"))}
                      </TypographySmall>
                    }
                    placement="top-start"
                  >
                    <InputLabel>
                      {t("form.newPasswordLabel")}
                      <InfoOutlinedIcon fontSize="10" />
                    </InputLabel>
                  </Tooltip>

                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    sx={{ mt: 1 }}
                  >
                    <UserTextField
                      {...register("New Password")}
                      error={errors["New Password"] ? true : false}
                      margin="dense"
                      fullWidth
                      type={form.showNewPassword ? "text" : "password"}
                      required
                      placeholder={t("form.newPasswordPlaceholder")}
                      disabled={loading}
                      endAdornment={
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleVisibilityNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {form.showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors["New Password"] && (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors["New Password"]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>

                <StyledBox>
                  <InputLabel>{t("form.confirmPasswordLabel")}</InputLabel>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    sx={{ mt: 1 }}
                  >
                    <UserTextField
                      {...register("Confirm Password")}
                      error={errors["Confirm Password"] ? true : false}
                      margin="dense"
                      fullWidth
                      type={form.showConfirmPassword ? "text" : "password"}
                      required
                      placeholder={t("form.confirmPasswordPlaceholder")}
                      disabled={loading}
                      endAdornment={
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleVisibilityConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {form.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors["Confirm Password"] && (
                      <FormHelperText sx={{ color: "red" }}>
                        {t("form.confirmPasswordMustMatch")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
              </Box>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleClickClose}
              disabled={loading}
            >
              {tCommon("cancelBtn")}
            </Button>

            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("saveBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditUser;
