import { coreFeatureFlag } from "../../helpers/featureFlag";
import {
  SET_AUTH_FEATURES,
  SET_AUTH_USER,
} from "../actionTypes/userActionType";

const initialState = {
  loggedUser: {},
  otpToken: "",
  features: coreFeatureFlag,
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH_USER:
      return { ...state, loggedUser: payload };

    case SET_AUTH_FEATURES:
      return { ...state, features: payload };

    default:
      return state;
  }
};
