import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
  Backdrop,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoopIcon from "@mui/icons-material/Loop";
import UploadField from "./UploadField";
import MediaItem from "./MediaItem";
import CloseConfirmation from "./CloseConfirmation";
import { TypographyLarge, TypographySmall } from "../../customComponent";
import {
  getAllAssets,
  setErrorHandler,
} from "../../../store/actions/cmsAction";
import { SET_CONTENT_DISABLE_DIRECT } from "../../../store/actionTypes/cmsActionType";
import { useDebounce } from "../../hooks";
import { decryptUser } from "../../../helpers/crypto";
import { colorStyling } from "../../../helpers/color";
import { logger } from "../../../helpers/logger";
import { parseVariables } from "../../../helpers/localize";
import { v4 as uuidv4 } from "uuid";
import parser from "html-react-parser";
import GlobalErrorPrompt from "../../global/GlobalErrorPrompt";
import { showSanitizedInput } from "../../../helpers/inputProcessing";

const DATA_FETCH_LIMIT = 100;
const DATA_PER_PAGE_LIMIT = 10;
const assetPerLayerLimit = process.env.REACT_APP_ASSET_PER_LAYER_LIMIT || 50;

const MediaPicker = ({
  open,
  handleClose,
  name,
  addContent,
  container,
  contentState,
  onlyOne,
  noUpload,
}) => {
  const dispatch = useDispatch();
  const { media, mediaLength, mediaFilter, loadingMedia, errorHandler } =
    useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const permission = decryptedLoggedUser?.permission;
  const assetPermission = permission?.asset;

  const [contents] = contentState;
  const [forceLoading, setForceLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);

  const [dataFrom, setDataFrom] = useState(0);
  const [mediaOffset, setMediaOffset] = useState(0);
  const [searchBy, setSearchBy] = useState("name");
  const [searchInput, setSearchInput] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [selectedMedias, setSelectedMedias] = useState({});
  const [onPage, setOnPage] = useState("");
  const [uploadedURLs, setUploadedURLs] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [filterFlag, setFilterFlag] = useState(false);

  const handleSelectMedia = (selected) => {
    const updated = onlyOne ? {} : { ...selectedMedias };
    const updatedOrder = onlyOne ? [] : [...selectedOrder];
    updated[selected.id] = selected;
    updatedOrder.push(selected.id);
    setSelectedMedias(updated);
    setSelectedOrder(updatedOrder);
  };

  const handleUnselectMedia = (selected) => {
    const updated = { ...selectedMedias };
    delete updated[selected.id];
    const updatedOrder = selectedOrder.filter((val) => val !== selected.id);
    setSelectedMedias(updated);
    setSelectedOrder(updatedOrder);
  };

  const handleCloseUploadUI = () => {
    setOpenUpload(false);
    setOnPage("");
  };

  const closeCloseConfirmation = () => {
    setOpenCloseConfirmation(false);
  };

  const addUploadedURLs = (urlArray, reset = false) => {
    if (reset) {
      setUploadedURLs([]);
      return;
    }

    const updated = [...uploadedURLs, ...urlArray];
    setUploadedURLs(updated);
  };

  useEffect(() => {
    if (open) {
      updateDataList();
      dispatch({ type: SET_CONTENT_DISABLE_DIRECT, payload: true });
    } else {
      setOpenUpload(false);
      dispatch({ type: SET_CONTENT_DISABLE_DIRECT, payload: false });
      setSelectedMedias({});
      setSelectedOrder([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (mediaFilter.searchBy) {
      setSearchBy(mediaFilter.searchBy);
    } else {
      setSearchBy("name");
    }

    if (mediaFilter.searchInput) {
      setSearchInput(mediaFilter.searchInput);
    } else {
      setSearchInput("");
    }

    if (mediaFilter.mediaType) {
      setMediaType(mediaFilter.mediaType);
    } else {
      setMediaType("");
    }

    setDataFrom(0);
    setFilterFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaFilter]);

  const handleCloseDialog = async () => {
    if (onPage === "") {
      handleClose();
      setTimeout(() => handleCloseUploadUI(), 1111);
    } else {
      setOpenCloseConfirmation(true);
    }
  };

  const fetchAllAssets = (payload, filterUsed) => {
    let { limit, offset, type, byLike, input, append } = payload;
    if (limit === undefined) limit = DATA_FETCH_LIMIT;
    if (offset === undefined) offset = mediaOffset;
    if (type === undefined) type = mediaType;
    if (byLike === undefined) byLike = searchBy;
    if (input === undefined) input = searchInput;

    const getPayload = {
      limit,
      offset,
      type,
      [`${byLike}Like`]: input,
    };

    dispatch(
      getAllAssets(
        { payload: getPayload, cache: media, append, filter: filterUsed },
        () => {
          setLoadingSearch(false);
          setForceLoading(false);
        }
      )
    );
  };

  const updateDataList = (from) => {
    let nextSlice = from;
    if (!nextSlice) {
      nextSlice = dataFrom;
      if (filterFlag) {
        nextSlice = 0;
        setFilterFlag(false);
      }
    }

    let appendData = false;
    if (media.length > 0 && nextSlice >= media.length) {
      appendData = true;
    }

    const nextOffset =
      Math.floor(nextSlice / DATA_FETCH_LIMIT) * DATA_FETCH_LIMIT;
    setMediaOffset(nextOffset);
    fetchAllAssets({ offset: nextOffset, append: appendData });
  };

  const navigateBack = () => {
    const nextSlice = dataFrom - DATA_PER_PAGE_LIMIT;
    setDataFrom(nextSlice);
  };

  const navigateNext = () => {
    const nextSlice = dataFrom + DATA_PER_PAGE_LIMIT;
    setDataFrom(nextSlice);

    const checkLoadMore = nextSlice + DATA_PER_PAGE_LIMIT;
    if (
      media.length <= checkLoadMore &&
      checkLoadMore % DATA_FETCH_LIMIT === 0
    ) {
      updateDataList(checkLoadMore);
    }
  };

  const debouncedSearch = useDebounce(searchInput, 500);
  useEffect(
    () => {
      setDataFrom(0);
      setMediaOffset(0);
      if (open) {
        setLoadingSearch(true);
        fetchAllAssets({ offset: 0, input: debouncedSearch }, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch] // Only call effect if debounced interval changes
  );

  const handleSearchInputChange = (event) => {
    const val = event.target.value;
    setSearchInput(val);
  };

  const handleSearchByChange = (event) => {
    const val = event.target.value;
    setSearchBy(val);

    if (searchInput?.length === 0) {
      return;
    }

    setDataFrom(0);
    setMediaOffset(0);
    setForceLoading(true);
    fetchAllAssets({ offset: 0, byLike: val }, true);
  };

  const handleMediaTypeChange = (event) => {
    const val = event.target.value;
    setMediaType(val);

    setDataFrom(0);
    setMediaOffset(0);
    setForceLoading(true);
    fetchAllAssets({ offset: 0, type: val }, true);
  };

  const handleCloseConfirmation = () => {
    uploadedURLs.forEach((url) => {
      URL.revokeObjectURL(url);
    });

    handleClose();
    setTimeout(() => handleCloseUploadUI(), 1111);
    setOpenCloseConfirmation(false);
    setUploadedURLs([]);
  };

  const mediaSliced = useMemo(() => {
    const lastIndex = dataFrom + DATA_PER_PAGE_LIMIT;
    logger.log(`[${dataFrom}, ${lastIndex}]`);
    return media.slice(dataFrom, lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, dataFrom]);

  const disableNextButton = useMemo(() => {
    const lastIndex = dataFrom + DATA_PER_PAGE_LIMIT;
    if (
      mediaSliced.length < DATA_PER_PAGE_LIMIT ||
      (mediaSliced.length === DATA_PER_PAGE_LIMIT && lastIndex === mediaLength)
    ) {
      return true;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFrom, mediaLength, mediaSliced]);

  const parseDialogTitle = useMemo(() => {
    if (!openUpload) {
      return t("media.pickerTitle");
    } else {
      switch (onPage) {
        default:
        case "":
          return t("media.uploadNewTitle");

        case "confirm":
          return t("media.uploadConfirmationTitle");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, openUpload, onPage]);

  const parseNoSearchResults = useMemo(() => {
    if (searchInput.length === 0) {
      return <strong>{t("media.noData")}</strong>;
    } else {
      return (
        <>
          {parser(
            parseVariables(t("media.noResultsMatching"), {
              searchInput: showSanitizedInput(searchInput),
            })
          )}
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSearch]);

  const parseNoMoreSearchResults = useMemo(() => {
    if (searchInput.length === 0) {
      return <strong>{parser(t("media.noMoreData"))}</strong>;
    } else {
      return (
        <>
          {parser(
            parseVariables(t("media.noMoreResultsMatching"), {
              searchInput: showSanitizedInput(searchInput),
            })
          )}
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSearch]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={() => {
        if (onPage === "") {
          handleCloseDialog();
          return;
        }
      }}
    >
      <GlobalErrorPrompt />

      <CloseConfirmation
        open={openCloseConfirmation}
        closeDialog={closeCloseConfirmation}
        handleConfirm={handleCloseConfirmation}
      />

      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {parseDialogTitle}
        <IconButton onClick={() => handleCloseDialog()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minHeight: `55vh` }}>
        <>
          {!openUpload ? (
            <>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ mt: 1, px: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <TextField
                      size="small"
                      label={t("media.searchByLabel")}
                      select
                      SelectProps={{ native: true }}
                      value={searchBy}
                      onChange={handleSearchByChange}
                      sx={{ width: "150px" }}
                    >
                      <option key="name" value="name">
                        {t("media.searchByName")}
                      </option>
                      <option key="campaignName" value="campaignName">
                        {t("media.searchByCampaignName")}
                      </option>
                    </TextField>
                  </Box>
                </Box>

                <Box sx={{ mx: 2, width: "100%" }}>
                  <TextField
                    onChange={handleSearchInputChange}
                    value={searchInput}
                    fullWidth
                    type="Search"
                    placeholder={t("media.searchPlaceholder")}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box>
                  <TextField
                    size="small"
                    select
                    SelectProps={{ native: true }}
                    value={mediaType}
                    onChange={handleMediaTypeChange}
                    sx={{ width: "150px" }}
                  >
                    <option key="default" value="" defaultValue>
                      {t("media.searchTypeDefault")}
                    </option>
                    <option key="IMAGE" value="IMAGE">
                      {t("media.searchTypeImages")}
                    </option>
                    <option key="VIDEO" value="VIDEO">
                      {t("media.searchTypeVideos")}
                    </option>
                  </TextField>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ mt: 1 }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    height: "320px",
                    minWidth: "fit-content",
                    padding: 0,
                  }}
                  disabled={dataFrom === 0 || forceLoading}
                  onClick={() => navigateBack()}
                >
                  <NavigateBeforeIcon
                    color="inherit"
                    sx={{ fontSize: "33px" }}
                  />
                </Button>

                {(loadingMedia && media.length === 0) ||
                forceLoading ||
                loadingSearch ? (
                  <Box
                    alignContent={"center"}
                    textAlign={"center"}
                    sx={{ py: 2 }}
                    height={"33vw"}
                  >
                    <LoopIcon
                      color="inherit"
                      sx={{
                        fontSize: "55px",
                        animation: "spin 2s linear infinite",
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(360deg)",
                          },
                          "100%": {
                            transform: "rotate(0deg)",
                          },
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <Box width={"100%"}>
                    {media.length > 0 ? (
                      <>
                        {mediaSliced.length === 0 ? (
                          <Box
                            alignContent={"center"}
                            textAlign={"center"}
                            sx={{ py: 2 }}
                            height={"33vw"}
                          >
                            <LoopIcon
                              color="inherit"
                              sx={{
                                fontSize: "55px",
                                animation: "spin 2s linear infinite",
                                "@keyframes spin": {
                                  "0%": {
                                    transform: "rotate(360deg)",
                                  },
                                  "100%": {
                                    transform: "rotate(0deg)",
                                  },
                                },
                              }}
                            />
                          </Box>
                        ) : (
                          <Grid
                            container
                            gap={"20px"}
                            columns={5}
                            paddingX={3}
                            sx={{ py: 2 }}
                            height={"33vw"}
                            justifyContent={
                              mediaSliced.length % 5 === 0
                                ? "space-between"
                                : "start"
                            }
                          >
                            {mediaSliced.map((m) => (
                              <MediaItem
                                key={uuidv4()}
                                media={m}
                                order={selectedOrder.indexOf(m.id)}
                                selectedMedia={selectedMedias[m.id]}
                                handleSelectMedia={handleSelectMedia}
                                handleUnselectMedia={handleUnselectMedia}
                                onlyOne={onlyOne}
                              />
                            ))}
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Box
                        height={"33vw"}
                        alignContent={"center"}
                        textAlign={"center"}
                      >
                        {mediaOffset === 0 ? (
                          <TypographyLarge>
                            {parseNoSearchResults}
                          </TypographyLarge>
                        ) : (
                          <TypographyLarge>
                            {parseNoMoreSearchResults}
                          </TypographyLarge>
                        )}

                        {mediaOffset === 0 && (
                          <TypographyLarge>
                            {t("media.searchResetHintPrefix")}
                            <Link
                              href="#"
                              onClick={() => {
                                setForceLoading(true);
                                setMediaType("");
                                setSearchInput("");
                                fetchAllAssets(
                                  {
                                    offset: 0,
                                    type: "",
                                    input: "",
                                  },
                                  true
                                );
                              }}
                            >
                              {t("media.searchResetClickHere")}
                            </Link>
                            {t("media.searchResetHintSuffix")}
                          </TypographyLarge>
                        )}
                      </Box>
                    )}
                  </Box>
                )}

                <Button
                  variant="outlined"
                  sx={{
                    height: "320px",
                    minWidth: "fit-content",
                    padding: 0,
                  }}
                  disabled={loadingSearch || forceLoading || disableNextButton}
                  onClick={() => navigateNext()}
                >
                  <NavigateNextIcon color="inherit" sx={{ fontSize: "33px" }} />
                </Button>
              </Box>
            </>
          ) : (
            <>
              <GlobalErrorPrompt />

              <UploadField
                setOnPage={setOnPage}
                closeMediaPicker={handleClose}
                closeUploadUI={handleCloseUploadUI}
                container={container}
                contentState={contentState}
                setLoadingFile={setLoadingFile}
                uploadedURLs={uploadedURLs}
                addUploadedURLs={addUploadedURLs}
              />
            </>
          )}
        </>
      </DialogContent>

      {assetPermission?.create && !openUpload && !noUpload && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"end"}
          sx={{ px: 3, mb: 2 }}
        >
          <Button
            sx={{
              borderRadius: "16px",
              boxShadow: "0px 13px 10px -10px rgba(0,0,0,0.4)",
            }}
            variant="contained"
            startIcon={<UploadIcon fontSize="small" />}
            onClick={() => setOpenUpload(true)}
          >
            {t("media.uploadNewBtn")}
          </Button>
        </Box>
      )}

      {selectedOrder?.length > 0 && !openUpload && (
        <TypographySmall
          sx={{
            mr: 3,
            color: "blue",
            fontWeight: 600,
            textAlign: "right",
          }}
        >
          {parseVariables(t("media.mediaCountHint"), {
            mediaCount: selectedOrder.length,
          })}
        </TypographySmall>
      )}

      {errorHandler.selectedMedia && (
        <TypographySmall
          sx={{
            mr: 3,
            mt: "2px",
            color: "red",
            fontWeight: 600,
            textAlign: "right",
          }}
        >
          {t("media.mediaNoneHint")}
        </TypographySmall>
      )}

      {errorHandler.limitReached && (
        <TypographySmall
          sx={{
            mr: 3,
            mt: "2px",
            color: "red",
            fontWeight: 600,
            textAlign: "right",
          }}
        >
          {parseVariables(t("error.addLimitPerLayerReached"), {
            maxCount: assetPerLayerLimit - contents.length,
            limit: assetPerLayerLimit,
            currentCount: contents.length,
          })}
        </TypographySmall>
      )}

      <DialogActions sx={{ mx: 2, mb: 1 }}>
        {onPage !== "" ? null : (
          <>
            <Button variant="outlined" onClick={() => handleCloseDialog()}>
              {tCommon("cancelBtn")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (
                  selectedOrder.length + contents.length >
                  assetPerLayerLimit
                ) {
                  dispatch(setErrorHandler({ limitReached: true }));
                  return;
                }
                addContent(selectedMedias, selectedOrder);
                setSelectedMedias({});
                setSelectedOrder([]);
              }}
            >
              {!noUpload ? t("media.addMediaBtn") : tCommon("submitBtn")}
            </Button>
          </>
        )}
      </DialogActions>

      <Backdrop
        sx={{
          color: colorStyling.white.inactive,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingFile}
        onClick={null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default MediaPicker;
