import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Skeleton, Tooltip } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../../customComponent";
import {
  DotIcon,
  GroupBigBlueIcon,
  ViewerGreyIcon,
  ScheduleOrangeIcon,
} from "../../../icons";
import { colorStyling } from "../../../helpers/color";
import { parseVariables } from "../../../helpers/localize";

const GroupInfo = ({ group, loading }) => {
  const { t } = useTranslation("group");
  const { t: tCommon } = useTranslation();

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <Box
      sx={{
        pl: 1,
        pr: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GroupBigBlueIcon />

        <Box sx={{ ml: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip
              title={group?.name?.length > 30 ? group?.name : ""}
              placement="top-start"
            >
              <TypographyXLarge sx={{ fontWeight: 700 }}>
                {group?.name?.length > 30
                  ? group?.name?.substring(0, 27).trim() + "..."
                  : group?.name}
              </TypographyXLarge>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TypographyNormal
          sx={{ fontWeight: 500, display: "flex", alignItems: "center", mr: 2 }}
        >
          <ViewerGreyIcon />
          {group.screensCount || group.screensCount === 0 ? (
            <>
              {parseVariables(t("item.numViewers"), {
                num: group.screensCount ?? 0,
              })}
            </>
          ) : (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "12px",
                lineHeight: "24px",
                width: "80px",
              }}
            />
          )}
        </TypographyNormal>

        <DotIcon />

        <TypographyNormal
          sx={{ fontWeight: 500, display: "flex", alignItems: "center", ml: 2 }}
        >
          <ScheduleOrangeIcon />
          {group.schedulesCount || group.schedulesCount === 0 ? (
            <Box component="span" sx={{ ml: 1 }}>
              {parseVariables(t("item.numSchedules"), {
                num: group.schedulesCount ?? 0,
              })}
            </Box>
          ) : (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "12px",
                lineHeight: "24px",
                width: "80px",
              }}
            />
          )}
        </TypographyNormal>
      </Box>
    </Box>
  );
};

export default GroupInfo;
