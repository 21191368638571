import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { editSchedule } from "../../../store/actions/scheduleAction";
import { initiateAXIOS } from "../../../config/axios";
import { contentCMSAPILimit } from "../../../api";
import {
  CustomTextField,
  TypographyLarge,
  TypographySmall,
} from "../../customComponent";
import { calculateCron, cronToFormData } from "../../../helpers/cronHelper";
import { dateFormatISO } from "../../../helpers/dateFormat";
import { purple } from "@mui/material/colors";
import { decryptUser } from "../../../helpers/crypto";
import dayjs from "dayjs";
import RecurrenceOption from "../form/RecurrenceOption";
import { range } from "lodash";
import {
  convertDurationtoMs,
  convertMstoDuration,
  durationValidation,
  generateDurationOptions,
} from "../../../helpers/scheduleHelper";
import { colorStyling } from "../../../helpers/color";
import { handleTimeoutPrompt } from "../../../store/handlePrompt";
import {
  SET_ERROR_MESSAGE_SCHEDULE_DIALOG,
  SET_ERROR_PROMPT_SCHEDULE_DIALOG,
} from "../../../store/actionTypes/scheduleActionType";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import { parseVariables } from "../../../helpers/localize";
import { sanitizeInput } from "../../../helpers/inputProcessing";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const EditSchedule = ({ schedule }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptScheduleDialog: isError,
    errorMessageScheduleDialog: errorMessage,
  } = useSelector((state) => state.schedule);

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const durationRef = useRef();

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const contentPermission = decryptedLoggedUser?.permission?.content;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState("");

  const [loadingContentList, setLoadingContentList] = useState(false);
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [selectFrequency, setSelectFrequency] = useState("");
  const [radioValue, setRadioValue] = useState(0);
  const [dayCheckbox, setDayCheckbox] = useState([]);
  const initMonthCheckbox = range("1", "13").toString().split(",");
  const [monthCheckbox, setMonthCheckbox] = useState(initMonthCheckbox);
  const [selectMonthlyDate, setSelectMonthlyDate] = useState({
    start: 1,
    end: 1,
  });

  const [duration, setDuration] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [selectPriority, setSelectPriority] = useState(2);

  const [checkboxEndDate, setCheckboxEndDate] = useState(false);

  const [useMinuteInterval, setUseMinuteInterval] = useState(false);
  const [intervalMinutes, setIntervalMinutes] = useState(5);
  const [intervalMinutesHelper, setIntervalMinutesHelper] = useState("");

  const handleInputName = (input) => {
    if (input.length > 40)
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else if (input.length < 6 && input.trim() !== "") {
      setName(input);
      setNameValidation(
        parseVariables(tCommon("validation.minLengthChars"), {
          num: 6,
        })
      );
    } else {
      setName(input);
      if (nameValidation) setNameValidation("");
    }
  };

  const handleMinuteIntervalToggle = (e) => {
    setUseMinuteInterval(e.target.checked);
    if (intervalMinutesHelper && !e.target.checked)
      setIntervalMinutesHelper("");
  };

  const handleMinutesChange = (e) => {
    const value = parseInt(e.target.value, 10);

    // Validation logic
    if (isNaN(value) || value < 1 || value > 59) {
      setIntervalMinutesHelper(t("validation.repeatIntervalHint"));
    } else {
      setIntervalMinutesHelper(""); // Clear error if valid
      setIntervalMinutes(value);
    }
  };

  const handleSetRecurrenceState = (type, value) => {
    switch (type) {
      case "radio":
        setRadioValue(value);
        break;

      case "checkDay":
        setDayCheckbox(value);
        break;

      case "checkMonth":
        setMonthCheckbox(value);
        break;

      case "selectMonthDate":
        setSelectMonthlyDate(value);
        break;

      default:
        break;
    }
  };

  const handleGetRecurrenceState = (type) => {
    switch (type) {
      case "radio":
        return radioValue;

      case "checkDay":
        return dayCheckbox;

      case "checkMonth":
        return monthCheckbox;

      case "selectMonthDate":
        return selectMonthlyDate;

      default:
        break;
    }
  };
  const handleResetForm = () => {
    setName("");
    setNameValidation("");
    setSelectCustomContent("");
    setStartDate("");
    setEndDate("");
    setSelectFrequency("");
    setRadioValue(0);
    setDayCheckbox([]);
    setMonthCheckbox(initMonthCheckbox);
    setSelectMonthlyDate({
      start: 1,
      end: 1,
    });
    setDuration({
      hours: "00",
      minutes: "00",
      seconds: "00",
    });
    setSelectPriority(2);
    setCheckboxEndDate(false);
    setUseMinuteInterval(false);
    setIntervalMinutes(5);
    setIntervalMinutesHelper("");
  };

  const handleClickClose = () => {
    handleResetForm();
    setOpen(false);
    dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: false });
  };

  /**
   * Handle opening error alert
   */
  const handleOpenErr = (message) => {
    dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_DIALOG, payload: message });
  };

  /**
   *  Handling start date value
   */
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    if (dayjs(e.target.value).isAfter(dayjs(endDate)) || endDate === "")
      setEndDate(dayjs(e.target.value).add(1, "d").format("YYYY-MM-DDTHH:mm"));
  };

  /**
   * Handling end date value
   * @param {*} e Input Event
   */
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  /**
   * Handling duration field change
   * @param {*} e Input Value
   */
  const handleChangeDuration = (e, part) => {
    let input = e.target.value;
    setDuration({ ...duration, [part]: input });
  };

  const handleCheckboxEndDate = (checked) => {
    if (checked) {
      setEndDate(dayjs(startDate).add(1, "d").format("YYYY-MM-DDTHH:mm"));
    } else {
      setEndDate("");
    }
    setCheckboxEndDate(checked);
  };

  const getScheduleType = (frequency, action) => {
    let scheduleType = "";
    if (frequency !== "ONCE") scheduleType += "REPEATING_";
    if (action === "PLAY_CONTENT") scheduleType += "CONTENT_";
    else scheduleType += "COMMAND_";
    scheduleType += "SCHEDULE";

    return scheduleType;
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }
    if (name.trim().length < 6) {
      setNameValidation(
        parseVariables(tCommon("validation.minLengthChars"), {
          num: 6,
        })
      );
      return;
    }
    if (name.trim().length > 40) {
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
      return;
    }

    let payload;
    payload = {
      id: schedule.id,
      data: {},
      schedule,
    };

    if (name.trim() !== schedule.name) {
      payload["data"]["name"] = sanitizeInput(name.trim());
    }

    if (
      schedule.command === "PLAY_CONTENT" &&
      schedule.contentId !== +selectCustomContent
    ) {
      payload["data"]["contentId"] = +selectCustomContent;
    }

    const sDate = new Date(startDate);

    const startDateISO = sDate.toISOString();
    if (startDateISO !== schedule.startAt) {
      payload["data"]["startAt"] = startDateISO;
    }

    if (!checkboxEndDate && schedule.endAt) {
      payload["data"]["endAt"] = null;
    } else if (endDate) {
      const eDate = new Date(endDate);
      const endDateISO = eDate.toISOString();
      if (checkboxEndDate && endDateISO !== schedule.endAt) {
        payload["data"]["endAt"] = endDateISO;
      }
    }

    const cronData = calculateCron(
      selectFrequency,
      sDate,
      radioValue,
      dayCheckbox,
      monthCheckbox,
      selectMonthlyDate,
      useMinuteInterval ? intervalMinutes : ""
    );

    const convertedDuration = convertDurationtoMs(duration);
    if (cronData !== schedule.cron) {
      payload["data"]["cron"] = cronData;
    }

    if (payload.data.cron === null) {
      payload["data"]["duration"] = null;
    } else if (
      schedule.command === "PLAY_CONTENT" &&
      convertedDuration !== schedule.duration
    ) {
      if (!durationValidation(duration)) {
        handleOpenErr(t("validation.durationEmpty"));
        handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
        durationRef.current.focus();
        return;
      }
      payload["data"]["duration"] = convertedDuration;
    }
    if (selectPriority !== schedule.priority.value) {
      payload["data"]["priority"] = selectPriority;
    }
    const oldCronData = schedule.cron
      ? cronToFormData(schedule.frequency, schedule.cron)
      : { frequency: "ONCE" };

    payload["data"]["_type"] = getScheduleType(
      oldCronData.frequency !== selectFrequency
        ? selectFrequency
        : oldCronData.frequency,
      schedule.command
    );
    setLoading(true);
    dispatch(editSchedule(payload, handleFinishSubmit));
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      setSelectCustomContent(schedule.contentId);

      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      handleOpenErr(t("validation.listContentsError"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
    } finally {
      setLoadingContentList(false);
    }
  };

  useEffect(() => {
    if (open) {
      setName(schedule.name);
      setStartDate(dateFormatISO(new Date(schedule.startAt)).substring(0, 16));
      setSelectPriority(schedule.priority.value);

      if (schedule.endAt) {
        setCheckboxEndDate(true);
        setEndDate(dateFormatISO(new Date(schedule.endAt)).substring(0, 16));
      }

      if (schedule.duration) {
        // to HH:MM:SS
        setDuration(convertMstoDuration(schedule.duration));
      }

      const cronData = schedule.cron
        ? cronToFormData(schedule.frequency, schedule.cron)
        : { frequency: "ONCE" };

      setSelectFrequency(cronData.frequency);

      switch (cronData.frequency) {
        case "DAILY":
          setRadioValue(cronData.radioValue);
          break;

        case "WEEKLY":
          setDayCheckbox(cronData.selectValue);
          break;

        case "MONTHLY":
          setRadioValue(cronData.radioValue);
          setMonthCheckbox(cronData.selectValue);
          setSelectMonthlyDate(cronData.dateValue);
          break;

        default:
          break;
      }

      if (cronData.minuteInterval) {
        setUseMinuteInterval(true);
        setIntervalMinutes(cronData.minuteInterval);
      }

      if (schedule.command === "PLAY_CONTENT" && contentPermission?.read) {
        getCMSContent();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Tooltip
        arrow
        disableInteractive
        title={
          schedule.isApproved ? (
            <TypographySmall sx={{ color: "white" }}>
              {t("item.editDisabledTip")}
            </TypographySmall>
          ) : (
            ""
          )
        }
        placement="top"
      >
        <Box>
          <Button
            variant="contained"
            startIcon={<EditIcon fontSize="small" />}
            sx={{ boxShadow: 3, ml: 2 }}
            disabled={schedule.isApproved ? true : false}
            {...(!schedule.isApproved && { onClick: () => setOpen(true) })}
          >
            {t("item.editBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose} fullWidth>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("form.editDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_SCHEDULE_DIALOG}
            />

            <Box sx={{ mb: 2, mt: 1 }}>
              <InputLabel>{t("form.actionLabel")}</InputLabel>
              <TypographyLarge
                sx={{
                  fontWeight: 600,
                  color: purple[600],
                  textTransform: "capitalize",
                }}
              >
                {schedule?.command?.replace(/_/g, " ")?.toLowerCase()}
              </TypographyLarge>
            </Box>

            <StyledBox>
              <InputLabel>{t("form.nameLabel")}</InputLabel>
              <TextField
                size="small"
                autoFocus
                required
                fullWidth
                margin="dense"
                name="name"
                value={name}
                placeholder={t("form.namePlaceholder")}
                onChange={(e) => handleInputName(e.target.value)}
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </StyledBox>

            {selectFrequency !== "ONCE" && (
              <StyledBox>
                <InputLabel>{t("form.frequencyLabel")}</InputLabel>
                <TextField
                  size="small"
                  margin="dense"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  required
                  fullWidth
                  value={selectFrequency}
                  onChange={(e) => setSelectFrequency(e.target.value)}
                  disabled={loading}
                >
                  <option value="DAILY">{t("form.frequencyDaily")}</option>
                  <option value="WEEKLY">{t("form.frequencyWeekly")}</option>
                  <option value="MONTHLY">{t("form.frequencyMonthly")}</option>
                </TextField>
                {selectFrequency !== "ONCE" &&
                  selectFrequency !== undefined &&
                  selectFrequency !== "" && (
                    <RecurrenceOption
                      frequency={selectFrequency}
                      setRecurrenceState={handleSetRecurrenceState}
                      getRecurrenceState={handleGetRecurrenceState}
                    />
                  )}
                {/* Additional Option for "Every n Minutes" */}
                <Box sx={{ mt: 1 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    value={useMinuteInterval}
                    checked={useMinuteInterval}
                    onChange={handleMinuteIntervalToggle}
                    label={
                      !useMinuteInterval
                        ? t("form.repeatIntervalCBLabel")
                        : t("form.repeatIntervalLabel")
                    }
                    disabled={loading}
                  />
                  {useMinuteInterval && (
                    <>
                      <TextField
                        type="number"
                        value={intervalMinutes}
                        min="1"
                        max="59"
                        onChange={handleMinutesChange}
                        size="small"
                        sx={{ width: 135 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("form.repeatIntervalSuffix")}
                            </InputAdornment>
                          ),
                        }}
                        disabled={loading}
                      />
                    </>
                  )}
                </Box>
                {intervalMinutesHelper && (
                  <TypographySmall sx={{ color: "red" }}>
                    {intervalMinutesHelper}
                  </TypographySmall>
                )}
              </StyledBox>
            )}

            <Box sx={{ display: "flex", alignItems: "end", mb: 1 }}>
              <StyledBox sx={{ width: "100%", mb: 0 }}>
                <InputLabel>{t("form.startDateLabel")}</InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  value={startDate}
                  onChange={handleStartDate}
                  required
                  margin="dense"
                  type="datetime-local"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: dayjs(
                        dayjs(startDate).isBefore(dayjs())
                          ? startDate
                          : new Date()
                      ).format("YYYY-MM-DDT00:00"),
                    },
                  }}
                  disabled={loading}
                />
              </StyledBox>

              {selectFrequency === "ONCE" &&
              schedule.command !== "PLAY_CONTENT" ? null : (
                <Box
                  sx={{
                    width: "100%",
                    ml: 2,
                  }}
                >
                  <Box sx={{ pb: checkboxEndDate ? 0 : 0.5 }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      value={checkboxEndDate}
                      checked={checkboxEndDate}
                      name={"showEndDate"}
                      onChange={(e) => {
                        handleCheckboxEndDate(e.target.checked);
                      }}
                      label={t("form.endDateCBLabel")}
                      disabled={loading}
                    />
                  </Box>

                  {checkboxEndDate && (
                    <StyledBox sx={{ width: "100%" }}>
                      <InputLabel>{t("form.endDateLabel")}</InputLabel>

                      <TextField
                        size="small"
                        fullWidth
                        value={endDate}
                        onChange={handleEndDate}
                        margin="dense"
                        type="datetime-local"
                        variant="outlined"
                        disabled={loading}
                        InputProps={{
                          inputProps: {
                            min: dayjs(
                              dayjs(startDate).isAfter(dayjs())
                                ? startDate
                                : new Date()
                            ).format("YYYY-MM-DDT00:00"),
                          },
                        }}
                      />
                    </StyledBox>
                  )}
                </Box>
              )}
            </Box>

            {contentPermission?.read && (
              <>
                {loadingContentList && customContentList.length === 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ width: "100%", height: 200 }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </>
            )}

            {(!loadingContentList || customContentList.length !== 0) &&
              schedule.command === "PLAY_CONTENT" && (
                <>
                  {contentPermission?.read && (
                    <Box>
                      <InputLabel>{t("form.contentLabel")}</InputLabel>
                      <CustomTextField
                        select
                        required
                        size="small"
                        SelectProps={{ native: true }}
                        fullWidth
                        margin="dense"
                        name="contentSelect"
                        value={selectCustomContent}
                        onChange={(e) => {
                          setSelectCustomContent(e.target.value);
                        }}
                        disabled={loading}
                      >
                        <option value="" disabled>
                          {t("form.contentSelect")}
                        </option>
                        {customContentList.map((content) => (
                          <option value={content.id} key={content.id}>
                            {content.name}
                          </option>
                        ))}
                      </CustomTextField>
                    </Box>
                  )}

                  <Box sx={{ display: "flex" }}>
                    {selectFrequency !== "ONCE" && (
                      <StyledBox sx={{ width: "100%" }}>
                        <InputLabel>{t("form.durationLabel")}</InputLabel>

                        <Box display="flex" alignItems="center">
                          <FormControl variant="outlined" margin="dense">
                            <InputLabel id="hours-label">
                              {t("form.durationHourLabel")}
                            </InputLabel>
                            <Select
                              inputRef={durationRef}
                              size="small"
                              labelId="hours-label"
                              sx={{ width: 80 }}
                              value={duration.hours}
                              onChange={(e) => handleChangeDuration(e, "hours")}
                              label={t("form.durationHourLabel")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 100,
                                    overflowY: "scroll",
                                  },
                                },
                              }}
                              disabled={loading}
                            >
                              {generateDurationOptions(0, 23).map((hour) => (
                                <MenuItem key={hour} value={hour}>
                                  {hour}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <Box mx={1}>:</Box>

                          <FormControl variant="outlined" margin="dense">
                            <InputLabel id="minutes-label">
                              {t("form.durationMinuteLabel")}
                            </InputLabel>
                            <Select
                              size="small"
                              labelId="minutes-label"
                              sx={{ width: 80 }}
                              value={duration.minutes}
                              onChange={(e) =>
                                handleChangeDuration(e, "minutes")
                              }
                              label={t("form.durationMinuteLabel")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 100,
                                    overflowY: "scroll",
                                  },
                                },
                              }}
                              disabled={loading}
                            >
                              {generateDurationOptions(0, 59).map((minute) => (
                                <MenuItem key={minute} value={minute}>
                                  {minute}
                                </MenuItem>
                              ))}
                            </Select>
                            <TypographySmall>{}</TypographySmall>
                          </FormControl>

                          <Box mx={1}>:</Box>

                          <FormControl variant="outlined" margin="dense">
                            <InputLabel id="seconds-label">
                              {t("form.durationSecondLabel")}
                            </InputLabel>
                            <Select
                              size="small"
                              labelId="seconds-label"
                              sx={{ width: 80 }}
                              value={duration.seconds}
                              onChange={(e) =>
                                handleChangeDuration(e, "seconds")
                              }
                              label={t("form.durationSecondLabel")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 100,
                                    overflowY: "scroll",
                                  },
                                },
                              }}
                              disabled={loading}
                            >
                              {generateDurationOptions(0, 59).map((second) => (
                                <MenuItem key={second} value={second}>
                                  {second}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <TypographySmall>
                          {durationValidation(duration)
                            ? ""
                            : t("validation.durationHint")}
                        </TypographySmall>
                      </StyledBox>
                    )}

                    <StyledBox
                      sx={{
                        width: "100%",
                        ml: selectFrequency !== "ONCE" ? 2 : 0,
                      }}
                    >
                      <InputLabel>{t("form.priorityLabel")}</InputLabel>
                      <CustomTextField
                        size="small"
                        select
                        required
                        SelectProps={{ native: true }}
                        fullWidth
                        margin="dense"
                        name="contentSelect"
                        value={selectPriority}
                        onChange={(e) => {
                          setSelectPriority(+e.target.value);
                        }}
                        disabled={loading}
                      >
                        <option value="0">{t("priority.Highest")}</option>
                        <option value="1">{t("priority.High")}</option>
                        <option value="2">{t("priority.Normal")}</option>
                        <option value="3">{t("priority.Low")}</option>
                        <option value="4">{t("priority.Lowest")}</option>
                      </CustomTextField>
                    </StyledBox>
                  </Box>
                </>
              )}
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              {tCommon("cancelBtn")}
            </Button>

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("saveBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditSchedule;
