import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";

import {
  SET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_ASSIGNED_GROUP,
  SET_ANNOUNCEMENT_ASSIGNED_VIEWER,
  SET_ANNOUNCEMENT_CONTENT,
  SET_ANNOUNCEMENT_DETAIL,
  SET_DELETE_ANNOUNCEMENT,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG,
  SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE,
  SET_LOADING_ANNOUNCEMENT,
  SET_NEW_ANNOUNCEMENT,
  SET_PREFETCH_ANNOUNCEMENT,
  SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE,
} from "../actionTypes/announcementActionType";
import {
  API_LIMIT,
  groupAPILimit,
  initiateAnnouncementAPI,
  initiateScheduleAPI,
  instanceCMSContentAPI,
  viewerAPILimit,
} from "../../api";
import Schedule from "../../models/ScheduleModel";
import { fetchScheduleById } from "./scheduleAction";
import { logger } from "../../helpers/logger";
import { handleTimeoutPrompt } from "../handlePrompt";
import Group from "../../models/GroupModel";
import Viewer from "../../models/ViewerModel";

import i18n from "i18next";
const t = i18n.getFixedT(null, "announcement");

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: false });
  }, 3000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: false });
  }, 5000);
};

const fetchContent = async (id, access_token, dispatch) => {
  for (let i = 0; i < id.length; i++) {
    if (!Number.isInteger(+id[i])) break;
    try {
      const { data } = await initiateAXIOS.get(instanceCMSContentAPI + id[i], {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({
        type: SET_ANNOUNCEMENT_CONTENT,
        payload: { id: id[i], content: data },
      });
    } catch (e) {
      logger.error(e);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data));
      }
    }
  }
};

const fetchScreenData = async (id, dispatch = null) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const screenData = await initiateAXIOS.get(
      viewerAPILimit + "&scheduleId=" + id,
      {
        headers: { authorization: `Bearer ${access_token}` },
      }
    );

    let screens = [];
    // Check if screen groups exist
    if (screenData.data.items) {
      const initScreens = screenData.data.items;
      initScreens.map((item) => screens.push(new Viewer(item)));
    }

    if (dispatch)
      dispatch({
        type: SET_ANNOUNCEMENT_ASSIGNED_VIEWER,
        payload: { id, screens },
      });
    else return screens;
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

const fetchScreenGroupData = async (id, dispatch = null) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const groupData = await initiateAXIOS.get(
      groupAPILimit + "&scheduleId=" + id,
      {
        headers: { authorization: `Bearer ${access_token}` },
      }
    );

    let groups = [];
    // Check if screen groups exist
    if (groupData.data.items) {
      const initGroups = groupData.data.items;
      initGroups.map((item) => groups.push(new Group(item)));
    }

    if (dispatch)
      dispatch({
        type: SET_ANNOUNCEMENT_ASSIGNED_GROUP,
        payload: { id, screenGroups: groups },
      });
    else return groups;
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

export const getAnnouncements = (query, isPrefetch, loading, fromHome) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + (query ?? "") + "&type=ANNOUNCEMENT",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let announcements = [];
      let contentId = [];
      data.items.forEach((ann) => {
        announcements.push(new Schedule(ann));
        contentId.push(ann.contentId);
      });

      dispatch({
        type: isPrefetch ? SET_PREFETCH_ANNOUNCEMENT : SET_ANNOUNCEMENT,
        payload: { items: announcements, totalItem: data.totalItem },
      });

      if (!fromHome) {
        const uniqueContentId = [...new Set(contentId)];
        fetchContent(uniqueContentId, access_token, dispatch);
        announcements.forEach((a) => {
          fetchScreenData(a.id, dispatch);
          fetchScreenGroupData(a.id, dispatch);
        });
      }
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const createAnnouncement = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.post(initiateScheduleAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      dispatch({
        type: SET_NEW_ANNOUNCEMENT,
        payload: new Schedule(await fetchScheduleById(data.id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.createMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG,
        payload: message,
      });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const filterAnnouncement = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateAnnouncementAPI + `&limit=${API_LIMIT}` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const announcements = [];
      await Promise.all(
        data.items.map(async (ann) => {
          const announcementData = await fetchScheduleById(
            ann.id,
            access_token
          );
          announcements.push(new Schedule(announcementData));
        })
      );
      dispatch({ type: SET_ANNOUNCEMENT, payload: announcements });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      dispatch(handleTimeoutErrorPrompt(dispatch));
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const getAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const editAnnouncement = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateScheduleAPI + payload.id,
        payload.data,
        { headers: { authorization: `Bearer ${access_token}` } }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule({ ...payload.announcement, ...data }),
      });

      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.updateMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG,
        payload: message,
      });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const activateAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/activate",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.activateMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const deactivateAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/deactivate",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.deactivateMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const approveAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/approve",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.approveMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const unapproveAnnouncement = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/unapprove",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.unapproveMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const assignAnnouncementToGroup = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.assignGroupMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const removeGroupFromAnnouncement = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.removeGroupMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const assignAnnouncementToViewer = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.assignViewerMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const removeViewerFromAnnouncement = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_ANNOUNCEMENT_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.removeViewerMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};

export const deleteAnnouncement = (id, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: true });

    try {
      await initiateAXIOS.delete(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_DELETE_ANNOUNCEMENT, payload: id });

      dispatch({ type: SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
        payload: t("api.deleteMsg"),
      });

      handleTimeoutSuccessPrompt(dispatch);
      callback();
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: false });
    }
  };
};
