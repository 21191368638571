import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssetsManagerItem from "./AssetsManagerItem";
import AssetsManagerBatchDelete from "./AssetsManagerBatchDelete";
import { TypographyNormal, TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import {
  getContentAssets,
  removeAssetsInBatch,
} from "../../../store/actions/assetAction";
import {
  SET_ASSET_FILTER,
  SET_ASSET_SORTING,
  SET_ASSET_ROWS_PER_PAGE,
  SET_ASSET_FIRST_LOAD,
  SET_ERROR_PROMPT_ASSET_PAGE,
} from "../../../store/actionTypes/assetActionType";
import { useDebounce } from "../../hooks";
import { logger } from "../../../helpers/logger";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DATA_FETCH_LIMIT = 100;

const headerFields = [
  {
    id: "name",
    align: "left",
    labelID: "Name",
  },
  {
    id: "campaignName",
    align: "center",
    labelID: "CampaignName",
    width: "240px",
  },
  {
    id: "createdAt",
    align: "center",
    labelID: "CreatedAt",
    width: "200px",
  },
];

const AssetsManagerList = ({ assetPermission }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("assetmanager");
  const { t: tCommon } = useTranslation();

  const {
    firstLoad,
    contentAssets,
    assetFilter,
    assetSorting,
    rowsPerPage,
    loadingContentAsset: loading,
  } = useSelector((state) => state.asset);

  const [currentPage, setCurrentPage] = useState(0);
  const [dataFrom, setDataFrom] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const [dataPayload, setDataPayload] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [appendLoading, setAppendLoading] = useState(false);

  const [selectedItems, setSelectedItems] = useState({});
  const [openBatchDelete, setOpenBatchDelete] = useState(false);
  const [loadingBatchDelete, setLoadingBatchDelete] = useState(false);
  const [checkboxAll, setCheckboxAll] = useState(false);

  const tableDiv = useRef(null);

  const handleChangeSortBy = (raw) => {
    let direction;
    let sortValue = raw;
    if (assetSorting.by !== raw) {
      if (assetSorting.by === "id" && raw === "createdAt") {
        direction = "desc";
        sortValue += "Desc";
      } else {
        direction = "asc";
        sortValue += "Asc";
      }
    } else {
      switch (assetSorting.direction) {
        default:
        case "asc":
          direction = "desc";
          sortValue += "Desc";
          break;

        case "desc":
          direction = "asc";
          sortValue += "Asc";
          break;
      }
    }

    const updatedSortBy = { by: raw, direction: direction };
    dispatch({ type: SET_ASSET_SORTING, payload: updatedSortBy });
    dispatch({
      type: SET_ASSET_FILTER,
      payload: { ...assetFilter, sortBy: sortValue },
    });
  };

  const fetchContents = (payload) => {
    const { offset, append, filterUsed } = payload;

    const getPayload = {
      payload: {
        ...assetFilter,
        limit: DATA_FETCH_LIMIT,
        offset: offset ?? dataOffset,
      },
      cache: contentAssets,
      append: append,
      filter: filterUsed,
    };

    setDataOffset(offset);
    setDataPayload(getPayload.payload);
    dispatch(
      getContentAssets(getPayload, () => {
        setAppendLoading(false);
      })
    );
    dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: false });
  };

  const updateDataList = (page, filterUsed) => {
    const nextSlice = page * rowsPerPage;
    setDataFrom(nextSlice);

    let appendData = false;
    if (
      contentAssets.items.length > 0 &&
      nextSlice >= contentAssets.items.length
    ) {
      appendData = true;
    }

    const nextOffset =
      Math.floor(nextSlice / DATA_FETCH_LIMIT) * DATA_FETCH_LIMIT;
    const payload = { offset: nextOffset, append: appendData, filterUsed };
    fetchContents(payload);
  };

  const debouncedUpdate = useDebounce(currentPage, 1111);
  useEffect(() => {
    if (updateFlag === true) {
      updateDataList(currentPage);
      setUpdateFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdate]);

  const updateDataSlice = ({ page, rows }) => {
    const nextPage = page >= 0 ? page : currentPage;
    const nextSlice = nextPage * (rows ?? rowsPerPage);
    if (nextSlice >= contentAssets.items.length) {
      setAppendLoading(true);
    }

    setDataFrom(nextSlice);
    setCurrentPage(nextPage);
    setUpdateFlag(true);

    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSelectItem = (item) => {
    const updated = { ...selectedItems };
    updated[item.id] = item;
    setSelectedItems(updated);
  };

  const handleUnselectItem = (selectedID) => {
    const updated = { ...selectedItems };
    delete updated[selectedID];
    setSelectedItems(updated);
    setCheckboxAll(false);
  };

  const clearSelection = () => {
    setSelectedItems({});
    setCheckboxAll(false);
  };

  const openBatchDeleteDialog = () => {
    setOpenBatchDelete(true);
  };

  const confirmBatchDelete = (callback) => {
    setLoadingBatchDelete(true);

    const assets = [];
    const batchIDs = [];
    Object.keys(selectedItems).forEach((id) => {
      assets.push(selectedItems[id]);
      batchIDs.push(selectedItems[id].id);
    });

    const payload = dataPayload;
    dispatch(
      removeAssetsInBatch(
        { assets, batchIDs },
        () => {
          setCheckboxAll(false);
          setOpenBatchDelete(false);
          setLoadingBatchDelete(false);
          setSelectedItems({});
          callback();
        },
        () => {
          dispatch(getContentAssets({ payload, cache: contentAssets }));
        }
      )
    );
  };

  const closeBatchDeleteDialog = () => {
    setOpenBatchDelete(false);
  };

  const handleChangePagination = (e, page) => {
    updateDataSlice({ page });
    setCheckboxAll(false);
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedRows = parseInt(event.target.value, 10);
    dispatch({
      type: SET_ASSET_ROWS_PER_PAGE,
      payload: updatedRows,
    });
    updateDataSlice({ page: 0, rows: updatedRows });
    setCheckboxAll(false);
  };

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  useEffect(() => {
    updateDataList(0);
    setSelectedItems({});
    return () => {
      dispatch({ type: SET_ASSET_FIRST_LOAD, payload: false });
      dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setCurrentPage(0);
      updateDataList(0, true);
    } else {
      dispatch({ type: SET_ASSET_FIRST_LOAD, payload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetFilter]);

  const dataSliced = useMemo(() => {
    const lastIndex = dataFrom + rowsPerPage;
    logger.log(`[${dataFrom}, ${lastIndex}]`);
    return contentAssets.items.slice(dataFrom, lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentAssets, dataFrom, rowsPerPage]);

  const selectedCount = useMemo(() => {
    return Object.keys(selectedItems).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleCheckboxAll = () => {
    const updatedCheckbox = !checkboxAll;
    const updated = { ...selectedItems };
    dataSliced.forEach((item) => {
      if (!item.canDelete) {
        return;
      }

      if (updatedCheckbox) {
        updated[item.id] = item;
      } else {
        delete updated[item.id];
      }
    });

    setCheckboxAll(updatedCheckbox);
    setSelectedItems(updated);
  };

  return (
    <>
      <AssetsManagerBatchDelete
        assets={selectedItems}
        open={openBatchDelete}
        closeDialog={closeBatchDeleteDialog}
        unselectItem={handleUnselectItem}
        handleConfirm={confirmBatchDelete}
        loading={loadingBatchDelete}
      />

      <Box sx={{ maxHeight: 567 }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {contentAssets.items.length === 0 ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{tCommon("noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <>
                <Box
                  height={40}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"end"}
                  sx={{ mb: 1 }}
                >
                  <TypographySmall sx={{ ml: 1 }}>
                    {t("table.sortingHint")}
                  </TypographySmall>

                  {selectedCount > 0 && (
                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{ mr: 1 }}
                      >
                        <TypographySmall sx={{ fontSize: "13px" }}>
                          {parser(
                            t("table.selectedItemsHint", {
                              count: selectedCount,
                            })
                          )}
                        </TypographySmall>
                        <Box
                          display={"flex"}
                          justifyContent={"end"}
                          sx={{ mr: "4px" }}
                        >
                          <Link
                            href="#"
                            underline="none"
                            component={"div"}
                            onClick={clearSelection}
                            sx={{
                              width: "fit-content",
                              cursor: "pointer",
                            }}
                          >
                            <TypographySmall
                              sx={{
                                color: "blue",
                                fontWeight: 500,
                                ":hover": {
                                  opacity: 0.8,
                                },
                              }}
                            >
                              {t("table.clearSelectionLabel")}
                            </TypographySmall>
                          </Link>
                        </Box>
                      </Box>
                      {/* TODO: If needed (Only applicable to Campaign Name) */}
                      {/* <Button
                        variant="contained"
                        color="success"
                        onClick={() => openBatchDeleteDialog()}
                        sx={{ mr: 1 }}
                      >
                        {t("table.batchEditBtn")}
                      </Button> */}
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => openBatchDeleteDialog()}
                        sx={{ mx: 1 }}
                      >
                        {t("batchDelete.btn")}
                      </Button>
                    </Box>
                  )}
                </Box>
                <TableContainer
                  ref={tableDiv}
                  component={Paper}
                  sx={{ overflowY: "auto", maxHeight: 525, boxShadow: 3 }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width={"100px"}>
                          {t("table.headerNo")}
                        </TableCell>
                        {headerFields.map((header) => (
                          <TableCell
                            align={header.align}
                            key={header.id}
                            width={header.width}
                          >
                            {assetSorting.by === header.id ? (
                              <Link
                                href="#"
                                underline="none"
                                component={"div"}
                                onClick={() => handleChangeSortBy(header.id)}
                                sx={{
                                  color: colorStyling.black,
                                  cursor: "pointer",
                                  ":hover": {
                                    opacity: 0.7,
                                  },
                                }}
                              >
                                <Box
                                  width={"auto"}
                                  display={"flex"}
                                  justifyContent={header.align}
                                >
                                  {t(`table.header${header.labelID}`)}*
                                  {assetSorting.direction === "asc" ? (
                                    <ArrowUpwardIcon />
                                  ) : (
                                    <ArrowDownwardIcon />
                                  )}
                                </Box>
                              </Link>
                            ) : (
                              <Link
                                href="#"
                                underline="none"
                                component={"div"}
                                onClick={() => handleChangeSortBy(header.id)}
                                sx={{
                                  color: colorStyling.black,
                                  cursor: "pointer",
                                  ":hover": {
                                    opacity: 0.7,
                                  },
                                }}
                              >
                                {t(`table.header${header.labelID}`)}*
                              </Link>
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="center" width={"117px"}>
                          {t("table.headerType")}
                        </TableCell>
                        <TableCell align="center" width={"150px"}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-around"}
                            alignItems={"center"}
                          >
                            <Checkbox
                              checked={checkboxAll}
                              size="small"
                              onClick={handleCheckboxAll}
                              sx={{ padding: "4px", mr: "4px" }}
                            />
                            <span style={{ marginRight: "4px" }}>
                              {t("table.headerAction")}
                            </span>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataSliced.length !== 0 ? (
                        dataSliced.map((data, index) => (
                          <AssetsManagerItem
                            pageData={{ page: currentPage, rowsPerPage }}
                            key={data.id + "-" + data.campaignName}
                            data={data}
                            index={index}
                            assetPermission={assetPermission}
                            currentPayload={dataPayload}
                            selectItem={handleSelectItem}
                            unselectItem={handleUnselectItem}
                            selectedFlag={selectedItems[data.id] ? true : false}
                          />
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Box
                              sx={{ height: "20vh" }}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="column"
                            >
                              <CircularProgress
                                size={20}
                                thickness={3}
                                sx={{ color: colorStyling.primary }}
                              />
                              <TypographyNormal
                                sx={{
                                  color: colorStyling.primary,
                                  mt: 2,
                                  fontWeight: 400,
                                }}
                              >
                                {tCommon("loadingDataList")}
                              </TypographyNormal>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
      </Box>

      {!loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={contentAssets.totalItem}
              page={currentPage}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
              disabled={appendLoading}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default AssetsManagerList;
