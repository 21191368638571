import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { remoteConfig } from "../firebase";
import { logger } from "../helpers/logger";
import {
  ensureInitialized,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { isEmpty } from "lodash";

export const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
};

export const UserUsePagination = (data, itemsPerPage) => {
  const { userPage } = useSelector((state) => state.user);

  const [currentPage, setCurrentPage] = useState(userPage);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (userPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useRemoteConfig = (key, specialOp, defaultValue) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        await ensureInitialized(remoteConfig);
        await fetchAndActivate(remoteConfig);
        const configValue = getValue(remoteConfig, key).asString();
        logger.log("fetchRemoteConfig:", { key });

        if (specialOp)
          setValue(
            isEmpty(configValue)
              ? defaultValue
              : specialOp(configValue)
          );
        else setValue(isEmpty(configValue) ? defaultValue : configValue);
      } catch (error) {
        logger.error("Error fetching remote config: ", error);
        setValue(defaultValue);
      }
    };

    fetchRemoteConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return value;
};
