import { initiateAXIOS } from "../../config/axios";
import Schedule from "../../models/ScheduleModel";
import { handleError } from "../handleError";
import {
  API_LIMIT,
  groupAPILimit,
  initiateScheduleAPI,
  instanceCMSContentAPI,
  viewerAPILimit,
} from "../../api";
import {
  SET_ADVERTISEMENT_SCHEDULE,
  SET_ADVERTISEMENT_SCHEDULE_CONTENT,
  SET_DELETE_SCHEDULE,
  SET_ERROR_MESSAGE_SCHEDULE_DIALOG,
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_DIALOG,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
  SET_LOADING_ADV_SCHEDULE,
  SET_LOADING_SCHEDULE,
  SET_NEW_ADV_SCHEDULE,
  SET_NEW_SCHEDULE,
  SET_PREFETCH_ADV_SCHEDULE,
  SET_PREFETCH_SCHEDULE,
  SET_SCHEDULE,
  SET_SCHEDULE_ASSIGNED_GROUP,
  SET_SCHEDULE_ASSIGNED_VIEWER,
  SET_SCHEDULE_DETAIL,
  SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
  SET_SUCCESS_PROMPT_SCHEDULE_PAGE,
} from "../actionTypes/scheduleActionType";
import Viewer from "../../models/ViewerModel";
import Group from "../../models/GroupModel";
import {
  SET_ERROR_MESSAGE_GROUP_PAGE,
  SET_ERROR_PROMPT_GROUP_PAGE,
  SET_SUCCESS_MESSAGE_GROUP_PAGE,
  SET_SUCCESS_PROMPT_GROUP_PAGE,
} from "../actionTypes/groupActionType";
import { getGroup } from "./groupAction";
import {
  SET_SUCCESS_MESSAGE_VIEWER_PAGE,
  SET_SUCCESS_PROMPT_VIEWER_PAGE,
} from "../actionTypes/viewerActionTypes";
import { getViewer } from "./viewerAction";
import { logger } from "../../helpers/logger";
import { handleTimeoutPrompt } from "../handlePrompt";

import i18n from "i18next";
const t = i18n.getFixedT(null, "schedule");

const scheduleType = [
  "COMMAND_SCHEDULE",
  "CONTENT_SCHEDULE",
  "REPEATING_COMMAND_SCHEDULE",
  "REPEATING_CONTENT_SCHEDULE",
];
export const scheduleTypeQuery = "&type=" + scheduleType.join("&type=");

const fetchContent = async (id, access_token, dispatch) => {
  for (let i = 0; i < id.length; i++) {
    if (!Number.isInteger(+id[i])) break;
    try {
      const { data } = await initiateAXIOS.get(instanceCMSContentAPI + id[i], {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({
        type: SET_ADVERTISEMENT_SCHEDULE_CONTENT,
        payload: { id: id[i], content: data },
      });
    } catch (e) {
      logger.error(e);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data));
      }
    }
  }
};

const fetchScreenData = async (id, dispatch = null) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const screenData = await initiateAXIOS.get(
      viewerAPILimit + "&scheduleId=" + id,
      {
        headers: { authorization: `Bearer ${access_token}` },
      }
    );

    let screens = [];
    // Check if screen groups exist
    if (screenData.data.items) {
      const initScreens = screenData.data.items;
      initScreens.map((item) => screens.push(new Viewer(item)));
    }

    if (dispatch)
      dispatch({
        type: SET_SCHEDULE_ASSIGNED_VIEWER,
        payload: { id, screens },
      });
    else return screens;
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

const fetchScreenGroupData = async (id, dispatch = null) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const groupData = await initiateAXIOS.get(
      groupAPILimit + "&scheduleId=" + id,
      {
        headers: { authorization: `Bearer ${access_token}` },
      }
    );

    let groups = [];
    // Check if screen groups exist
    if (groupData.data.items) {
      const initGroups = groupData.data.items;
      initGroups.map((item) => groups.push(new Group(item)));
    }

    if (dispatch)
      dispatch({
        type: SET_SCHEDULE_ASSIGNED_GROUP,
        payload: { id, screenGroups: groups },
      });
    else return groups;
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

export const fetchScheduleById = async (id, access_token) => {
  try {
    const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
      headers: { authorization: `Bearer ${access_token}` },
    });

    const screenData = await fetchScreenData(id);

    const screenGroupData = await fetchScreenGroupData(id);

    let screens = [];
    // Check if screens exist
    if (screenData) screenData.map((item) => screens.push(new Viewer(item)));

    let screenGroups = [];
    // Check if screen groups exist
    if (screenGroupData)
      screenGroupData.map((item) => screenGroups.push(new Group(item)));

    return { ...data, screens, screenGroups };
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

export const getSchedules = (query, isPrefetch, loading, fromHome) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI +
          (query ?? "") +
          (query.includes("type") ? "" : scheduleTypeQuery),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      let contentId = [];
      const dataSchedule = data.items;
      dataSchedule.forEach((item) => {
        schedules.push(new Schedule(item));
        contentId.push(item.contentId);
      });
      dispatch({
        type: isPrefetch ? SET_PREFETCH_SCHEDULE : SET_SCHEDULE,
        payload: { items: schedules, totalItem: data.totalItem },
      });

      if (!fromHome) {
        const uniqueContentId = [...new Set(contentId)];
        fetchContent(uniqueContentId, access_token, dispatch);
        schedules.forEach((s) => {
          fetchScreenData(s.id, dispatch);
          fetchScreenGroupData(s.id, dispatch);
        });
      }
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      if (loading) loading(false);
    }
  };
};

export const getAdvertisementSchedules = (
  limit,
  query = "",
  isPrefetch = false,
  callback = null
) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${limit}&type=ADVERTISEMENT${query}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      let contentId = [];
      const dataSchedule = data.items;
      dataSchedule.forEach((item) => {
        schedules.push(new Schedule(item));
        contentId.push(item.contentId);
      });
      logger.log(schedules);
      dispatch({
        type: isPrefetch
          ? SET_PREFETCH_ADV_SCHEDULE
          : SET_ADVERTISEMENT_SCHEDULE,
        payload: { items: schedules, totalItem: data.totalItem },
      });

      const uniqueContentId = [...new Set(contentId)];
      fetchContent(uniqueContentId, access_token, dispatch);
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      if (callback) callback();
      dispatch({ type: SET_LOADING_ADV_SCHEDULE, payload: false });
    }
  };
};

export const filterSchedule = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${API_LIMIT}&sortBy=isActiveDesc` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      const dataSchedule = data.items;
      dataSchedule.map((item) => schedules.push(new Schedule(item)));
      dispatch({ type: SET_SCHEDULE, payload: schedules });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const createSchedule = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.post(initiateScheduleAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const schedule = data;
      const OOP = new Schedule(schedule);
      dispatch({ type: SET_NEW_SCHEDULE, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.createMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
      callback("success");
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_DIALOG, payload: message });
      callback("error");

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const createAdvertisementSchedule = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.post(
        initiateScheduleAPI + "advertisement",
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const schedule = data;
      const OOP = new Schedule(schedule);
      dispatch({ type: SET_NEW_ADV_SCHEDULE, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.createMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
      callback("success");

      fetchContent([payload.contentId], access_token, dispatch);
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_DIALOG, payload: message });
      callback("error");

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const createInstantSchedule = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.post(initiateScheduleAPI + "instant", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      if (payload.screenGroups) {
        dispatch(getGroup(payload.screenGroups[0].id));
        dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: true });
        dispatch({
          type: SET_SUCCESS_MESSAGE_GROUP_PAGE,
          payload: t("api.createInstanceMsg"),
        });
        setTimeout(() => {
          dispatch({ type: SET_SUCCESS_PROMPT_GROUP_PAGE, payload: false });
        }, 3000);
      }

      if (payload.screens) {
        dispatch(getViewer(payload.screens[0].id));
        dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
        dispatch({
          type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
          payload: t("api.createInstanceMsg"),
        });
        handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
      }
    } catch (e) {
      const message = handleError(e);

      if (payload.screenGroups) {
        dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: true });
        dispatch({ type: SET_ERROR_MESSAGE_GROUP_PAGE, payload: message });
        setTimeout(() => {
          dispatch({ type: SET_ERROR_PROMPT_GROUP_PAGE, payload: false });
        }, 5000);
      }

      if (payload.screens) {
        dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
        dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });
        handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      }

      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
      if (callback) callback();
    }
  };
};

export const getSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const deleteSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      await initiateAXIOS.delete(initiateScheduleAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_DELETE_SCHEDULE, payload: id });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.deleteMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const assignScheduleToViewer = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        {
          add: assignData,
        },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.assignViewerMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const removeViewerFromSchedule = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.removeViewerMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const assignScheduleToGroup = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.assignGroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const removeGroupFromSchedule = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateScheduleAPI + id + "/screen-group",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(await fetchScheduleById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.removeGroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const editSchedule = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateScheduleAPI + payload.id,
        payload.data,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const schedule = new Schedule({ ...payload.schedule, ...data });
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: schedule,
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.updateMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_DIALOG, payload: message });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const approveSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/approve",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const scheduleData = await fetchScheduleById(id, access_token);
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(scheduleData),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.approveMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const unapproveSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/unapprove",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const scheduleData = await fetchScheduleById(id, access_token);
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(scheduleData),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.unapproveMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const activateSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/activate",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const scheduleData = await fetchScheduleById(id, access_token);
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(scheduleData),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.activateMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const deactivateSchedule = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_SCHEDULE, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/deactivate",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const scheduleData = await fetchScheduleById(id, access_token);
      dispatch({
        type: SET_SCHEDULE_DETAIL,
        payload: new Schedule(scheduleData),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.deactivateMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const resendSchedule = ({ id, loading }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: loading, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + id + "/resend",
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_SUCCESS_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
        payload: t("api.resendMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SCHEDULE_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: loading, payload: false });
    }
  };
};
