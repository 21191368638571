import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { TypographyNormal, TypographySmall } from "../../../customComponent";
import { SET_ADS_PLACEHOLDER_FLAG } from "../../../../store/actionTypes/cmsActionType";

const LayerAsset = ({ layerID, content, index, assetCount }) => {
  const dispatch = useDispatch();
  const { adsPlaceholderFlag } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const contentName = useMemo(() => {
    switch (content.type) {
      case "VISTAR_ADVERTISEMENT":
      case "HIVESTACK_ADVERTISEMENT":
        const adType = content.type.split("_")[0];
        return t(`adContent.name${adType}`);

      default:
        return content.name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const togglePlaceholderFlag = (index) => {
    const updated = [];
    adsPlaceholderFlag[layerID].forEach((flag, i) => {
      updated.push(i !== index ? flag : !flag);
    });
    dispatch({
      type: SET_ADS_PLACEHOLDER_FLAG,
      payload: {
        id: layerID,
        edit: updated,
      },
    });
  };

  return (
    <Box
      width={"468px"}
      key={"content" + index}
      sx={{
        mt: 1,
        pr: 1,
        borderRadius: "4px",
        ":hover": {
          backgroundColor: "rgba(28, 78, 216, 0.1)",
        },
      }}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box
        width={"333px"}
        display={"flex"}
        alignItems={"center"}
        height={content.type.includes("_ADVERTISEMENT") ? "55px" : "44px"}
      >
        <Box sx={{ width: "33px", textAlign: "right" }}>{`${index + 1}.`}</Box>
        <Box display={"flex"} flexDirection={"column"} sx={{ ml: 1 }}>
          <Tooltip arrow disableInteractive title={contentName}>
            <Box sx={{ maxWidth: "275px" }}>
              <TypographyNormal
                sx={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {contentName}
              </TypographyNormal>
            </Box>
          </Tooltip>

          {content.placeholder && (
            <Box display={"flex"} alignItems={"center"}>
              <TypographySmall>
                {t("detail.contentAdShowPlaceholder")}
              </TypographySmall>
              <Checkbox
                size="small"
                checked={adsPlaceholderFlag[layerID]?.[index]}
                onChange={() => togglePlaceholderFlag(index)}
                sx={{
                  paddingY: 0,
                  paddingX: 1,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box display={"flex"} alignItems={"center"}>
        {content.isMuted && (
          <Tooltip
            arrow
            disableInteractive
            title={t("detail.contentVideoMutedTip")}
          >
            <Box display={"flex"} alignItems={"center"} sx={{ mr: 1 }}>
              <VolumeOffIcon />
            </Box>
          </Tooltip>
        )}

        {assetCount > 1 && (
          <Tooltip
            arrow
            disableInteractive
            title={t("detail.contentDurationTip")}
          >
            <TextField
              size="small"
              placeholder="0"
              defaultValue={content.interval}
              sx={{ width: "100px" }}
              inputProps={{ style: { cursor: "default", fontSize: 15 } }}
              InputProps={{
                readOnly: true,
                sx: { cursor: "default" },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    disableTypography
                    sx={{ fontSize: 14 }}
                  >
                    {t("detail.contentDurationSuffix")}
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default LayerAsset;
