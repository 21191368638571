import React, { useEffect, useCallback, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import axios from "../config/axios";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../store/actionTypes/globalActionType";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { logger } from "../helpers/logger";

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const SessionTimeout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: tCommon } = useTranslation();

  const isAuthenticated = sessionStorage.getItem("access_token");
  const sessionInterval = +process.env.REACT_APP_SESSION_EXPIRY / (60 * 1000); // (minutes)

  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = useCallback(() => {
    // warning timer
    let warningInactive = (timeString) => {
      clearTimeout(startTimerInterval.current);

      warningInactiveInterval.current = setInterval(() => {
        const maxTime = sessionInterval; // maximum ideal time given before logout

        const diff = dayjs.duration(dayjs().diff(dayjs(timeString)));
        const minPast = diff.minutes();

        if (minPast >= maxTime) {
          clearInterval(warningInactiveInterval.current);
          sessionStorage.removeItem("lastTimeStamp");
          //  logout function here
          handleLogout();
        }
      }, 1000);
    };

    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInterval, navigate, dispatch, isAuthenticated]);

  const handleLogout = async () => {
    logger.log("!!!SESSION TIMEOUT!!!");
    navigate("/login", {
      state: {
        error: tCommon("session.expiredAlert"),
      },
    });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: tCommon("session.logoutHint"),
    });

    await axios({
      method: "POST",
      url: "/api/auth/logout",
      headers: { authorization: `Bearer ${isAuthenticated}` },
    })
      .then(() => {
        dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      })
      .catch((e) => {
        dispatch({ type: SET_GLOBAL_ERROR, payload: true });
        dispatch({
          type: SET_GLOBAL_ERROR_MESSAGE,
          payload: e?.response?.data?.message,
        });
      })
      .finally(() => {
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.removeItem("lastTimeStamp");
        dispatch({ type: "LOGOUT" });
        withReactContent(Swal).close();
      });
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    let timeStamp;
    const storedTimeStamp = dayjs(sessionStorage.getItem("lastTimeStamp"));

    if (isAuthenticated) {
      timeStamp = dayjs();
      if (
        storedTimeStamp &&
        timeStamp.diff(storedTimeStamp, "minute") > sessionInterval
      ) {
        handleLogout();
      }
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }

    timeChecker();

    //eslint-disable-next-line
  }, [isAuthenticated, timeChecker]);

  useEffect(() => {
    const events = [
      "click",
      "load",
      "scroll",
      "apiRequest",
      "visibilitychange",
    ];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, timeChecker]);

  return <Fragment />;
};

export default SessionTimeout;
