import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LaunchIcon from "@mui/icons-material/Launch";
import ContentDetails from "./detail/ContentDetails";
import ContentPreview from "./detail/ContentPreview";
import { TypographyNormal, TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { parseVariables } from "../../../helpers/localize";
import { ResolutionType } from "../../../helpers/resolution";
import { initiateAXIOS } from "../../../config/axios";
import { instanceCMSContentAPI } from "../../../api";
import parser from "html-react-parser";
import { updateDetailsArray } from "../../../store/actions/cmsAction";
import { logger } from "../../../helpers/logger";

const ContentTemplateBatchDelete = ({
  contents,
  contentOrder,
  open,
  closeDialog,
  unselectItem,
  handleConfirm,
  loading,
}) => {
  const dispatch = useDispatch();
  const { cmsDetails } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hoverFlag, setHoverFlag] = useState([]);
  const [removedIndex, setRemovedIndex] = useState(null);

  const [itemIndex, setItemIndex] = useState(0);
  const [itemFocus, setItemFocus] = useState({});

  const [loadingContent, setLoadingContent] = useState(false);
  const [content, setContent] = useState({});
  const [layers, setLayers] = useState([]);
  const [canvasRes, setCanvasRes] = useState({ width: 0, height: 0 });
  const [ratiodRes, setRatiodRes] = useState({ width: 0, height: 0 });
  const [openContentDetails, setOpenContentDetails] = useState(false);

  useEffect(() => {
    const items = [];
    const flags = [];
    contentOrder.forEach((id) => {
      items.push(contents[id]);
      flags.push(false);
    });

    if (removedIndex !== null) {
      let updatedIndex = itemIndex;
      if (removedIndex < itemIndex || updatedIndex >= items.length) {
        updatedIndex = updatedIndex - 1;
      }

      if (updatedIndex < 0) {
        closeDialog();
        return;
      }

      setItemIndex(updatedIndex);
      setItemFocus(items[updatedIndex]);
      getContent(items[updatedIndex].id);
      setRemovedIndex(null);
    }

    setHoverFlag(flags);
    setSelectedItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentOrder]);

  const handleChangeFocus = (index) => {
    setItemIndex(index);
    setItemFocus(selectedItems[index]);
    setLoadingContent(true);
    getContent(selectedItems[index].id);
  };

  const handleHovering = (index, value) => {
    const updated = [...hoverFlag];
    updated[index] = value;
    setHoverFlag(updated);
  };

  const handleCallback = (event) => {
    event.preventDefault();
    handleConfirm(() => {
      setConfirmCheckbox(false);
    });
  };

  const handleClose = (event) => {
    event.preventDefault();
    closeDialog();
    setConfirmCheckbox(false);
  };

  const handleRemoveItem = (event, id, index) => {
    event.stopPropagation();
    setRemovedIndex(index);
    unselectItem(id);
  };

  const setupCanvas = (data) => {
    let resLabel = `${data.width} x ${data.height}`;
    let assignedRes =
      ResolutionType["P"][resLabel] ?? ResolutionType["L"][resLabel];
    if (!assignedRes) {
      resLabel = "custom0";
      assignedRes = ResolutionType["P"][resLabel];
    }

    let potraitFlag = false;
    const updatedCanvasRes = { width: 0, height: 0 };
    if (data.width < data.height) {
      potraitFlag = true;
    }

    let ratiodWidth;
    let ratiodHeight;
    if (!assignedRes?.free) {
      updatedCanvasRes["width"] = potraitFlag ? 360 : 640;
      updatedCanvasRes["height"] = potraitFlag ? 640 : 360;
      ratiodWidth = data.width / updatedCanvasRes.width;
      ratiodHeight = data.height / updatedCanvasRes.height;
    } else {
      updatedCanvasRes["width"] = data.width;
      updatedCanvasRes["height"] = data.height;
      ratiodWidth = 1;
      ratiodHeight = 1;
    }

    setRatiodRes({ width: ratiodWidth, height: ratiodHeight });
    setCanvasRes(updatedCanvasRes);
  };

  const getContent = async (contentID) => {
    if (cmsDetails[contentID]) {
      const data = cmsDetails[contentID];
      setContent(data);
      setupCanvas(data);
      setLoadingContent(false);
      if (data.layers?.length > 0) {
        setLayers(data.layers);
        return;
      }
    }

    const access_token = sessionStorage.getItem("access_token");
    const getUrl = `${instanceCMSContentAPI}${contentID}`;
    try {
      const { data } = await initiateAXIOS.get(getUrl, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setContent(data);
      setupCanvas(data);
      setLayers(data.layers);
      dispatch(updateDetailsArray({ cache: cmsDetails, data }));
      setLoadingContent(false);
    } catch (error) {
      logger.error(`getContent-ERR:`, error);
    }
  };

  const handleOpenContentDetails = () => {
    setOpenContentDetails(true);
  };

  const handleCloseContentDetails = () => {
    setOpenContentDetails(false);
  };

  useEffect(() => {
    if (open && selectedItems?.length > 0) {
      setItemIndex(0);
      setItemFocus(selectedItems[0]);
      setHoverFlag(new Array(selectedItems.length).fill(false));
      setLoadingContent(true);
      getContent(selectedItems[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <ContentDetails
        open={openContentDetails}
        handleClose={handleCloseContentDetails}
        contentID={itemFocus?.id}
      />

      <Dialog maxWidth="lg" open={open}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("batchDelete.title")}
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" color="inherit" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ pb: 0, mb: 4 }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ px: 1, py: 2 }}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              sx={{ pr: 3 }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                width={"333px"}
                maxHeight={"400px"}
              >
                <TableContainer
                  component={Paper}
                  sx={{ overflowY: "auto", maxHeight: 500, boxShadow: 3 }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width={"117px"}>
                          {parser(
                            parseVariables(t("batchDelete.headerName"), {
                              count: selectedItems.length,
                            })
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {selectedItems.map((data, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            ...((index + 1) % 2 !== 0
                              ? {
                                  backgroundColor: colorStyling.white.hover,
                                }
                              : {
                                  backgroundColor: colorStyling.white.full,
                                }),
                          }}
                        >
                          <TableCell align="left">
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              onClick={() => handleChangeFocus(index)}
                              onMouseEnter={() => handleHovering(index, true)}
                              onMouseLeave={() => handleHovering(index, false)}
                              sx={{ cursor: "pointer" }}
                            >
                              <Box
                                width={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <Tooltip
                                  arrow
                                  placement="top"
                                  disableInteractive
                                  title={
                                    loading ? (
                                      ""
                                    ) : (
                                      <TypographySmall
                                        sx={{
                                          color: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        {t("batchDelete.removeTip")}
                                      </TypographySmall>
                                    )
                                  }
                                >
                                  <ClearIcon
                                    color={!loading ? "error" : "disabled"}
                                    fontSize="small"
                                    onClick={(e) =>
                                      !loading
                                        ? handleRemoveItem(e, data.id, index)
                                        : null
                                    }
                                    sx={{
                                      mr: 1,
                                      "&:hover": { opacity: 0.7 },
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  arrow
                                  placement="top"
                                  disableInteractive
                                  title={
                                    <TypographySmall
                                      sx={{
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {data.name}
                                    </TypographySmall>
                                  }
                                >
                                  <Box sx={{ maxWidth: "230px" }}>
                                    <TypographyNormal
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {data.name}
                                    </TypographyNormal>
                                  </Box>
                                </Tooltip>
                              </Box>
                              {(hoverFlag[index] || index === itemIndex) && (
                                <PlayArrowIcon
                                  fontSize="small"
                                  sx={{ color: index === itemIndex && "blue" }}
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  top: "55px",
                  width: "380px",
                  height: "auto",
                  position: "sticky",
                  alignSelf: "flex-start",
                }}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <InputLabel>{t("batchDelete.previewLabel")}</InputLabel>
                  <Tooltip
                    arrow
                    placement="top"
                    disableInteractive
                    title={
                      <TypographySmall
                        sx={{
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {t("batchDelete.seeDetailsTip")}
                      </TypographySmall>
                    }
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <Button
                        variant="contained"
                        color="info"
                        disabled={loading}
                        onClick={handleOpenContentDetails}
                        sx={{ height: "33px", minWidth: "50px" }}
                      >
                        <LaunchIcon fontSize="small" />
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>

                <ContentPreview
                  open={!openContentDetails}
                  layers={layers}
                  loading={loadingContent}
                  content={content}
                  canvasRes={canvasRes}
                  ratiodRes={ratiodRes}
                  refreshed={true}
                  disableControl={true}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <Box
          sx={{ width: "100%", height: 0 }}
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmCheckbox}
                onClick={(e) => {
                  setConfirmCheckbox(e.target.checked);
                }}
              />
            }
            label={
              <TypographySmall sx={{ color: "red" }}>
                {parser(t("batchDelete.confirmCheckbox"))}
              </TypographySmall>
            }
            disabled={loading}
            sx={{ m: 0, width: "410px", marginTop: "-22px" }}
          />
        </Box>

        <DialogActions>
          <Button
            startIcon={<ClearIcon />}
            fullWidth
            variant="outlined"
            disabled={loading}
            onClick={handleClose}
            sx={{ height: "38px" }}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            startIcon={!loading && <DoneIcon />}
            fullWidth
            variant="contained"
            color="error"
            disabled={!confirmCheckbox || loading}
            onClick={handleCallback}
            sx={{ height: "38px" }}
          >
            {!loading ? (
              t("batchDelete.confirmBtn")
            ) : (
              <CircularProgress size={"22px"} color="inherit" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContentTemplateBatchDelete;
