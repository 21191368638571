import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Popper,
  Link,
  Paper,
  CircularProgress,
  Divider,
} from "@mui/material";
import { TypographySmall } from "./customComponent";
import { logger } from "../helpers/logger";
import { initiateAXIOS } from "../config/axios";

const env = process.env.REACT_APP_ENV;
const token = process.env.REACT_APP_TOKEN_GITHUB_VERSION;

const Versioning = ({ textColor }) => {
  const { t: tCommon } = useTranslation();
  const dashboardVersion = require("../../package.json").version;

  const [dashboardLatest, setDashboardLatest] = useState("");
  const [backendVersion, setBackendVersion] = useState("");
  const [backendLatest, setBackendLatest] = useState("");

  const [anchorVersionPopper, setAnchorVersionPopper] = useState(null);
  const openVersionPopper = Boolean(anchorVersionPopper);
  const handleOpenVersionPopper = (event) => {
    if (openVersionPopper) {
      setAnchorVersionPopper(null);
      return;
    }
    setAnchorVersionPopper(event.currentTarget);
  };
  const handleCloseVersionPopper = (event) => {
    setAnchorVersionPopper(null);
  };

  const fetchBackendVersion = async () => {
    const getExisting = sessionStorage.getItem("backend_version");
    if (getExisting) {
      setBackendVersion(getExisting);
      return;
    }

    try {
      const { data } = await initiateAXIOS.get("/api/version");
      logger.log(`fetchBackendVersion:`, data);

      const version = data;
      setBackendVersion(version);
      sessionStorage.setItem("backend_version", version);
    } catch (error) {
      setBackendVersion("error");
    }
  };

  const fetchDashboardLatestVersion = async () => {
    const getExisting = sessionStorage.getItem("dashboard_latest");
    if (getExisting) {
      setDashboardLatest(getExisting);
      return;
    }

    try {
      const { data } = await initiateAXIOS.get(
        "https://api.github.com/repos/Einstech-Itxcast/itxcast2020-dashboard/releases/latest",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const version = data["name"] ? data["name"].substring(1) : "error";
      setDashboardLatest(version);
      sessionStorage.setItem("dashboard_latest", version);
    } catch (error) {
      setDashboardLatest("error");
    }
  };

  const fetchBackendLatestVersion = async () => {
    const getExisting = sessionStorage.getItem("backend_latest");
    if (getExisting) {
      setBackendLatest(getExisting);
      return;
    }

    try {
      const { data } = await initiateAXIOS.get(
        "https://api.github.com/repos/Einstech-Itxcast/itxcast2020-backend/releases/latest",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const version = data["name"] ? data["name"].substring(1) : "error";
      setBackendLatest(version);
      sessionStorage.setItem("backend_latest", version);
    } catch (error) {
      setBackendLatest("error");
    }
  };

  useEffect(() => {
    if (env !== "prod") {
      fetchDashboardLatestVersion();
      fetchBackendLatestVersion();
    }

    fetchBackendVersion();
    window.addEventListener("beforeunload", () => {
      sessionStorage.removeItem("dashboard_latest");
      sessionStorage.removeItem("backend_version");
      sessionStorage.removeItem("backend_latest");
    });
  }, []);

  return (
    <>
      <Box sx={{ position: "absolute", bottom: 0, left: 0, ml: 0.5 }}>
        <Link
          href="#"
          underline="none"
          component={"div"}
          onMouseEnter={handleOpenVersionPopper}
          onMouseLeave={handleCloseVersionPopper}
          sx={{
            ml: "4px",
            mb: "4px",
            cursor: "pointer",
            ":hover": {
              opacity: 0.8,
            },
          }}
        >
          <TypographySmall sx={{ color: textColor }}>
            {dashboardVersion}
          </TypographySmall>
        </Link>

        <Popper
          open={openVersionPopper}
          anchorEl={anchorVersionPopper}
          placement="bottom-start"
          style={{ zIndex: 1200 }}
        >
          <Paper sx={{ marginBottom: 1, marginLeft: 2 }}>
            <Box sx={{ py: 1, px: 2, width: "240px" }}>
              <Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <TypographySmall>
                    {tCommon("version.dashboardCurrentVersionLabel")}
                  </TypographySmall>
                  <TypographySmall>
                    <b>{dashboardVersion}</b>
                  </TypographySmall>
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <TypographySmall>
                    {tCommon("version.apiCurrentVersionLabel")}
                  </TypographySmall>
                  {backendVersion.length > 0 ? (
                    <TypographySmall>
                      <b>{backendVersion}</b>
                    </TypographySmall>
                  ) : (
                    <CircularProgress
                      size={11}
                      sx={{ ml: 1, color: "black" }}
                    />
                  )}
                </Box>
              </Box>

              <Divider sx={{ my: 1 }} />

              {/* TODO: Will be added after Amplify S3 implemented */}
              {env !== "prod" && (
                <Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <TypographySmall>
                      {tCommon("version.dashboardLatestVersionLabel")}
                    </TypographySmall>
                    {dashboardLatest.length > 0 ? (
                      <TypographySmall>
                        <b>{dashboardLatest}</b>
                      </TypographySmall>
                    ) : (
                      <CircularProgress
                        size={11}
                        sx={{ ml: 1, color: "black" }}
                      />
                    )}
                  </Box>

                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <TypographySmall>
                      {tCommon("version.apiLatestVersionLabel")}
                    </TypographySmall>
                    {backendLatest.length > 0 ? (
                      <TypographySmall>
                        <b>{backendLatest}</b>
                      </TypographySmall>
                    ) : (
                      <CircularProgress
                        size={11}
                        sx={{ ml: 1, color: "black" }}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </>
  );
};

export default Versioning;
