import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { Box, Button, Paper } from "@mui/material";
import { logger } from "../../../helpers/logger";
import { colorStyling } from "../../../helpers/color";
import {
  ArrowForwardIos as NextIcon,
  ArrowBackIos as PrevIcon,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  compareMonthYear,
  generateCalendarEvents,
  getCalendarDateQuery,
  getScheduleQuery,
} from "../../../helpers/scheduleHelper";
import CalendarEvent from "./CalendarEvent";
import "./ScheduleCalendar.css";
import { initiateAXIOS } from "../../../config/axios";
import { initiateScheduleAPI } from "../../../api";
import {
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
} from "../../../store/actionTypes/scheduleActionType";
import { handleError } from "../../../store/handleError";
import EventHover from "./EventHover";
import { handleTimeoutPrompt } from "../../../store/handlePrompt";
import ColorLegend from "./ColorLegend";

const ScheduleBaseCalendar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("schedule");

  const { features } = useSelector((state) => state.auth);

  const [calendarApi, setCalendarApi] = useState();
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);

  const calendarRef = useRef(null);

  const [tooltipEvent, setTooltipEvent] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (showTooltip) {
        setTooltipPosition({
          x: event.clientX,
          y: event.clientY,
        });
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [showTooltip]);

  useEffect(() => {
    setCalendarApi(calendarRef?.current?.getApi());
  }, [calendarRef]);

  useEffect(() => {
    getSchedules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const getSchedules = async () => {
    const access_token = sessionStorage.getItem("access_token");

    setLoading(true);
    try {
      const dateQuery = getCalendarDateQuery(date);
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI +
          `?limit=100&offset=0&isActive=true&startAtLte=${dateQuery.endDate}&endAtGte=${dateQuery.startDate}` +
          getScheduleQuery(features),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let eventsData = generateCalendarEvents(
        data.items,
        dateQuery.startDate,
        dateQuery.endDate
      );

      setEvent(eventsData);
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_SCHEDULE_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_SCHEDULE_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SCHEDULE_PAGE);
    } finally {
      setLoading(false);
    }
  };

  const goNextMonth = () => {
    calendarApi.next();
    setDate(dayjs(date).add(1, "month"));
  };

  const goPrevMonth = () => {
    calendarApi.prev();
    setDate(dayjs(date).subtract(1, "month"));
  };

  const handleEventClick = (clickInfo) => {
    logger.log(clickInfo.event);
    navigate("/schedule/detail/" + clickInfo.event.extendedProps.scheduleId);
  };

  const handleGoTo = (goDate) => {
    logger.log(goDate);
    calendarApi?.gotoDate(dayjs(goDate).format("YYYY-MM-DD"));
    setDate(goDate);
  };

  const handleMouseEnter = (info) => {
    const event = info.event;
    setTooltipEvent(event); // Customize the content here

    setTooltipPosition({
      x: info.jsEvent.clientX,
      y: info.jsEvent.clientY,
    });

    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  function renderEventContent(eventInfo) {
    return CalendarEvent(eventInfo, t);
  }

  return (
    <Box>
      <Paper
        component="section"
        elevation={2}
        sx={{ borderRadius: "6px", my: 2, p: 2 }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["month", "year"]}
                value={date}
                onChange={(newValue) => handleGoTo(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    readOnly: true,
                    sx: {
                      "& .MuiInputBase-input": {
                        fontWeight: "bold", // Change font weight
                        fontSize: "18px", // Change font size
                        pl: 3,
                        py: 1.5,
                        width: 200,
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box display={"flex"}>
            <Box>
              <Button
                sx={{ mr: 2.5 }}
                variant="contained"
                onClick={() => handleGoTo(dayjs())}
                disabled={compareMonthYear(dayjs(), date)}
              >
                {t("calendar.today")}
              </Button>
            </Box>
            <Box>
              <Button
                sx={{ ml: 0.5, height: 38 }}
                variant="contained"
                onClick={goPrevMonth}
              >
                <PrevIcon />
              </Button>
              <Button
                sx={{ ml: 0.5, height: 38 }}
                variant="contained"
                onClick={goNextMonth}
              >
                <NextIcon />
              </Button>
            </Box>
          </Box>
        </Box>

        {features["ADVERTISEMENT"] && <ColorLegend />}

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{ left: "", right: "" }}
          eventClick={handleEventClick}
          events={loading ? [] : event}
          eventColor={colorStyling.primary}
          eventContent={renderEventContent}
          eventMinHeight={20}
          eventOrder={(a, b) => {
            // Handle null/undefined startHour values by treating them as "lesser" values (i.e., placing them at the start)
            const startHourA = a.extendedProps.startHour || "";
            const startHourB = b.extendedProps.startHour || "";

            // Sort by startHour first (in HH:mm format)
            return startHourA.localeCompare(startHourB);
          }}
          eventOrderStrict={true}
          eventMouseEnter={handleMouseEnter}
          eventMouseLeave={handleMouseLeave}
        />

        {showTooltip && (
          <EventHover event={tooltipEvent} position={tooltipPosition} />
        )}
      </Paper>
    </Box>
  );
};

export default ScheduleBaseCalendar;
